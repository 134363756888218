
import { Component, ViewChild, AfterViewInit, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from 'src/app/data/user.service';
import { DLT, DltService } from 'src/app/services/dlt.service';
import { RequestState } from 'src/app/shared/models/enums';
import { VALIDATIONS, VALIDATIONS_MESSAGE } from 'src/app/shared/util/validations';
import { IFormState, Summary } from 'src/app/shared/models/interface';
import { formatDate } from '@angular/common';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DifferenceOfObjectList, DifferenceOfStringList, JoinObjectList, JoinStringList } from 'src/app/shared/util/helper';
import { InitiatorTabComponent } from 'src/app/components/initiator-tab/initiator-tab.component';
import { IjeService } from 'src/app/services/ije.service';
import { LINKS } from 'src/app/shared/constant/defaults';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionDialogComponent } from 'src/app/components/terms-and-condition-dialog/terms-and-condition-dialog.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-distributed-ledger-token',
  templateUrl: './distributed-ledger-token.component.html',
  styleUrls: ['./distributed-ledger-token.component.scss']
})

export class DistributedLedgerTokenComponent implements AfterViewInit, IFormState {

  VALIDATIONS_MESSAGE = VALIDATIONS_MESSAGE;
  NATIVE_TOKEN_MAXIMUM_TAB = 5;
  REGISTRATION_TERMS_AND_CONDITION = LINKS.REGISTRATION_TERMS_AND_CONDITION;

  @ViewChild('steps') private stepper!: MatStepper;
  @ViewChild('stepperContainer', { static: false }) private elSummary!: ElementRef;
  @ViewChild('initiator') private initiatorComp!: InitiatorTabComponent

  // https://stackoverflow.com/questions/42142053/candeactivate-confirm-message/42207299#42207299
  // https://stackoverflow.com/questions/4617583/customize-onbeforeunload-dialog-box
  @HostListener('window:beforeunload') beforeUnload(event: BeforeUnloadEvent): void {
    if (this.isTouched() && this.cookieService.get('access_token')) {
      event.preventDefault();
    }
  }

  underlyingAssetExternalIdentifierTypesOptions = [
    { value: 'CUSIP', text: 'CUSIP' },
    { value: 'ISIN', text: 'ISIN' },
    { value: 'QUIK', text: 'QUIK' },
    { value: 'RIC', text: 'RIC' },
    { value: 'SEDOL', text: 'SEDOL' }
  ]

  digitalTokenExternalIdentifiersTypesOptions = [
    { value: 'FIGI', text: 'FIGI' },
    { value: 'ITIN', text: 'ITIN' }
  ]

  // -----------------
  //    FORM GROUP    | - All formgroup are handle here to reuse all the components.
  // -----------------
  initiatorFormGroup = this.formBuilder.group({
    isCreator: [false],
    hasLei: [true],
    lei: [''],
    companyName: [''],
    businessIdentifierCode: [''],
    hasNativeToken: [false],
    hasAuxiliaryToken: [false],
    customerName: ['', [Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_WITH_SINGLE_SPECIAL_CHARACTER)]],
    financeContract: ['', [Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_WITH_SINGLE_SPECIAL_CHARACTER)]],
    emailAddress: ['', [Validators.required, Validators.pattern(VALIDATIONS.EMAIL)]],
    tellNo: ['', [Validators.required, Validators.pattern(VALIDATIONS.TELEPHONE_OR_VAT_NUMBER)]],
    invoiceAddress: ['', [Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_WITH_SINGLE_SPECIAL_CHARACTER)]],
    vatNumber: ['', [Validators.pattern(VALIDATIONS.TELEPHONE_OR_VAT_NUMBER)]]
  });

  normativeFormGroup = this.formBuilder.group({
    dlt: ['', Validators.required],
    isProvisional: [{ value: false, disabled: true }],
    gbHashAlgo: [''],
    gbHash: [''],
    gbUtcTimeStamp: [new Date()],
    activationDate: "2022-02-02T12:12:12"
  })

  informativeFormGroup = this.formBuilder.group({
    longName: ['', [Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_SPACE)]],
    hasOrigLongName: [false],
    originalLongName: ['', [Validators.pattern(VALIDATIONS.UTF_8_WITH_RESTRICT_SYMBOL)]],
    tokenRefUrl: ['', [Validators.required]], // , Validators.pattern(VALIDATIONS.URL)
    publicDistLedgerIndicator: ['', Validators.required],
  })

  nativeTokenFormGroup = this.formBuilder.group({
    nativeTokens: this.formBuilder.array([
    ])
  });

  auxiliaryFormGroup = this.formBuilder.group({
    auxiliaryMechanisms: this.formBuilder.array([
    ])
  });


  additionalInfo = '';
  loadingText = '';
  successTitle = '';



  //Variables
  requestState = RequestState.Creation;
  // Genesis Block Hash Algorithm
  gbhaOptions!: string[];

  // a flag for current step.
  selectedTabIndex = -1;



  // determine if was steps in Auxiliary
  wasStepInNativeToken = false;

  // determine if was steps in Auxiliary
  wasStepInAuxiliary = false;


  dltRecords: any = {};

  isShowGlossary = false;

  constructor(private router: Router, private formBuilder: FormBuilder,
    private dltService: DltService, private userService: UserService,
    private activatedRoute: ActivatedRoute, private ijeService: IjeService,
    private cdr: ChangeDetectorRef, private dialog: MatDialog, private cookieService: CookieService) {
    this.ijeService.getGenesisBlockHashAlgorithm().then(({ properties }: any) => {
      this.gbhaOptions = [...properties.Normative.properties.GenesisBlockHashAlgorithm.enum].sort((first, second) => first - second);
    });
    this.activatedRoute.params.subscribe(({ id }) => {
      if (!id) {
        this.addNativeTokenTab();
        this.addAuxiliaryTab()
      }
      else {
        this.requestState = RequestState.Processing;
        this.loadingText = '';
        this.dltService.getRequestDetail(id).subscribe(({ data }: any) => {
          this.requestState = RequestState.Editing;
          this.dltRecords = data;
          this.dltService.applicationId = id;
          this.selectedTabIndex = +this.activatedRoute.snapshot.queryParams['tab'];
          const hasAuxiliaryToken = !!+this.activatedRoute.snapshot.queryParams['hasAuxiliaryTokens'],
            hasNativeToken = !!+this.activatedRoute.snapshot.queryParams['hasNativeTokens'];
          this.wasStepInAuxiliary = hasAuxiliaryToken;
          this.wasStepInNativeToken = hasNativeToken;
          this.setFieldsValue(hasAuxiliaryToken, hasNativeToken);
          if (this.selectedTabIndex > -1) {
            setTimeout(() => {
              this.stepper.selectedIndex = this.selectedTabIndex;
            })
          }
        });
      }
    })
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    if (this.selectedTabIndex > -1) {
      this.stepper.selectedIndex = this.selectedTabIndex;
    }
  }

  get atInitiatorTab(): boolean {
    return this.selectedTabIndex == -1;
  }

  get previousText(): string {
    return this.atInitiatorTab ? 'Back to Token Selection' : 'Previous';
  }

  get continueText(): string {
    return this.stepper && this.stepper.selectedIndex === this.tabsCount ? `Submit` : `Continue`;
  }

  get currentTabIndex(): number {
    if (this.stepper) {
      // check if in 3rd tab
      if (this.stepper.selectedIndex == 2) {
        // check if has Native Tab is not add 1 to tab index.
        return this.stepper.selectedIndex + (this.initiatorData.hasNativeToken ? 0 : 1);
      } else {
        return this.stepper.selectedIndex;
      }
    }
    return -1;
  }

  get tabsCount(): number {
    const defaultCountTab = 2;
    return defaultCountTab + this.initiatorData.hasNativeToken + this.initiatorData.hasAuxiliaryToken;
  }

  get initiatorData(): any {
    return this.initiatorFormGroup.value;
  }

  get normativeData(): any {
    return { ...this.normativeFormGroup.value, ...{ isProvisional: this.normativeFormGroup.get('isProvisional')?.value } };
  }

  get informativeData(): any {
    return this.informativeFormGroup.value;
  }

  get billingInfo() {
    const { customerName, financeContract, emailAddress, tellNo, invoiceAddress, vatNumber }: any = this.initiatorFormGroup.value;
    return { customerName, financeContract, emailAddress, tellNo, invoiceAddress, vatNumber }
  }

  // TODO: Make this like `nativeTokens.value` or reduce mapping of data
  get nativeTokenData() {
    return this.nativeTokens.value.map(({ shortNames, hasOrigLangShortName, origlangShortNames, unitMultiplier, underlyingAssets, externalIdentifiers }: any) => {
      return {
        shortNames: shortNames.map((s: any) => s.shortName),
        ...(hasOrigLangShortName ? { originalShortNames: origlangShortNames.map((s: any) => s.origlangShortName) } : {}),
        attribute: { unitMultiplier },
        externalIdentifier: {
          underLyingAssets: underlyingAssets.map((s: any) => s),
          digitalTokens: externalIdentifiers.map((s: any) => s),
        }
      }
    });
  }

  get tokenShortNames(): string {
    return this.nativeTokenData.map((s: any) => s.shortNames).join(', ');
  }

  get auxiliaryData() {
    return this.auxiliaries.value;
  }

  get companyNameControl() {
    return this.initiatorFormGroup.get('companyName');
  }

  get identifierCodeControl() {
    return this.initiatorFormGroup.get('businessIdentifierCode');
  }

  get isDLTBlockchain(): boolean {
    return this.normativeData.dlt == 'Blockchain';
  }

  get summaryDetails(): Summary {

    const issuerItems = [{
      fields: [{ label: 'Are you the Creator/Initiator/Maintainer of the DLT?', value: this.initiatorData.isCreator ? `Yes` : `No` }]
    }];
    // initiator validation
    if (this.initiatorData.isCreator) {
      if (this.initiatorData.hasLei) {
        issuerItems.push({ fields: [{ label: 'Legal Entity Identifier (LEI)', value: this.initiatorData.lei, }] })
      } else {
        issuerItems.push(
          { fields: [{ label: 'Company', value: this.initiatorData.companyName, }] },
          { fields: [{ label: 'Business Identifier Code', value: this.initiatorData.businessIdentifierCode, }] }
        )
      }
    }

    const biilingItems = [
      { fields: [{ label: 'Customer Name', value: this.initiatorData.customerName }] },
      { fields: [{ label: 'Finance Contact', value: this.initiatorData.financeContract }] },
      { fields: [{ label: 'Email Address', value: this.initiatorData.emailAddress }] },
      { fields: [{ label: 'Tel No.', value: this.initiatorData.tellNo }] },
      { fields: [{ label: 'Invoice Address', value: this.initiatorData.invoiceAddress }] },
      { fields: [{ label: 'VAT Number', value: this.initiatorData.vatNumber }] },
    ]


    const normativeItems: any = []
    if (this.isDLTBlockchain) {
      normativeItems.push(
        { fields: [{ label: 'Genesis Block Hash Algorithm', value: this.normativeData.gbHashAlgo, }] },
        { fields: [{ label: 'Genesis Block Hash', value: this.normativeData.gbHash, }] },
        { fields: [{ label: 'Provisional', value: this.normativeData.isProvisional ? `Yes` : `No` }, { ...(this.normativeData.isProvisional ? { label: ``, value: `` } : {}) }] },
        { fields: [{ label: 'Genesis Block UTC Timestamp:', value: formatDate(this.normativeData.gbUtcTimeStamp, 'dd/MM/yyyy HH:mm:ss', 'en-US'), }] }
      )
    } else {
      normativeItems.push({
        fields: [{ label: 'Provisional', value: this.normativeData.isProvisional ? `Yes` : `No` },
        { ...(this.normativeData.isProvisional ? { label: ``, value: `` } : {}) }]
      })
    }

    const informativeItems = [
      { fields: [{ label: 'Long Name', value: this.informativeData.longName }, { ...(this.informativeData.hasOrigLongName ? { label: 'Original Language Long Name', value: this.informativeData.originalLongName } : {}) }] },
      { fields: [{ label: 'Token Reference URL', value: this.informativeData.tokenRefUrl, }] },
      { fields: [{ label: 'Public Distributed Ledger Indicator', value: this.informativeData.publicDistLedgerIndicator, }] }
    ];


    const summaryData: any = {
      title: this.informativeFormGroup.value.longName as string,
      subTitle: `Native Digital Token`,
      miniSubTitle: this.normativeFormGroup.value.dlt as string,
      hasEditButton: true,
      sections: [
        {
          title: `Issuer Details`,
          items: [...issuerItems]
        },
        {
          title: `Billing Informations`,
          items: [...biilingItems]
        },
        {
          title: `Normative Attributes`,
          items: [...normativeItems]
        },
        {
          title: `Informative Attributes`,
          items: [...informativeItems]
        }
      ]
    }

    // validation if has native token tab.
    if (this.initiatorData.hasNativeToken) {
      const nativeTokensItems: any = []
      this.nativeTokenData.forEach(({ attribute, shortNames, originalShortNames, externalIdentifier }: any) => {
        const { digitalTokens, underLyingAssets } = externalIdentifier,
          items = {
            title: `Native Tokens`,
            items: [
              { fields: [{ label: `Short Name(s)`, value: JoinStringList(shortNames) }, { ...(originalShortNames ? { label: `Original Language Short Name(s)`, value: JoinStringList(originalShortNames) } : {}) }] },
              { fields: [{ label: `Unit Multiplier`, value: attribute.unitMultiplier }] },
              { fields: [{ label: `Underlying Asset External Identifiers Type`, value: JoinObjectList(underLyingAssets, 'type') }, { label: `Underlying Asset External Identifiers Value`, value: JoinObjectList(underLyingAssets, 'value') }] },
              { fields: [{ label: `Digital Token External Identifiers Type`, value: JoinObjectList(digitalTokens, 'type') }, { label: `Digital Token External Identifiers Value`, value: JoinObjectList(digitalTokens, 'value') }] }
            ]
          };
        nativeTokensItems.push(items);
      });
      summaryData.sections.push({
        title: `Native Tokens`,
        hasMultiple: true,
        subSections: [...nativeTokensItems]
      })
    }


    // validation if has auxiliary tab.
    if (this.initiatorData.hasAuxiliaryToken) {
      const auxiliaryTokensItems: any = []
      this.auxiliaryData.forEach(({ auxiliaryMechanism, auxiliaryMechanismDesc, auxiliaryMechanismTechRef, auxiliaryMechanismVerificationDetail }: any) => {
        const items = {
          title: `Auxiliary Token`,
          items: [
            { fields: [{ label: `Auxiliary Mechanism`, value: auxiliaryMechanism }] },
            { fields: [{ label: `Auxiliary Mechanism Description`, value: auxiliaryMechanismDesc }] },
            { fields: [{ label: `Auxiliary Digital Token Technical Reference`, value: auxiliaryMechanismTechRef }] },
            { fields: [{ label: `Auxiliary Digital Token Verification Details`, value: auxiliaryMechanismVerificationDetail }] }
          ]
        }
        auxiliaryTokensItems.push(items);
      });
      summaryData.sections.push({
        title: `Auxiliary Tokens`,
        hasMultiple: true,
        subSections: [...auxiliaryTokensItems]
      })
    }

    return summaryData;
  }

  get changesMade(): any[] {
    const changes: any = [];
    if (!!Object.keys(this.dltRecords).length) {

      // Initiator Changes
      const { isCreator, lei, companyName, businessIdentifierCode, customerName,
        financeContract, emailAddress, tellNo, invoiceAddress, vatNumber }: any = this.initiatorData,
        { billingInfo } = this.dltRecords,
        initiatorChanges = [];
      if (isCreator != this.dltRecords.isCreator) {
        initiatorChanges.push({ label: 'Are you the Creator/Initiator/Maintainer of the DLT?', value: isCreator ? `Yes` : `No` });
      }
      if (lei != this.dltRecords.lei && isCreator) {
        if (lei) {
          // Previous null and now have lei.
          initiatorChanges.push({ label: 'Legal Entity Identifier (LEI)', value: lei });
        } else {
          initiatorChanges.push({ label: 'Company', value: companyName });
          initiatorChanges.push({ label: 'Business Identifier Code', value: businessIdentifierCode });
        }
      } else {
        // Change only the companyName || businessIdentifierCode
        if (companyName != this.dltRecords.companyName) {
          initiatorChanges.push({ label: 'Company', value: companyName });
        }
        if (businessIdentifierCode != this.dltRecords.businessIdentifierCode) {
          initiatorChanges.push({ label: 'Business Identifier Code', value: businessIdentifierCode });
        }
      }
      if (initiatorChanges.length) {
        changes.push({
          title: `Initiator Details`,
          fields: initiatorChanges
        })
      }

      //Billing Information
      const billingInfoChanges = [];
      if (customerName != billingInfo.customerName) {
        billingInfoChanges.push({ label: 'Customer Name', value: customerName });
      }
      if (financeContract != billingInfo.financeContract) {
        billingInfoChanges.push({ label: 'Finance Contact', value: financeContract });
      }
      if (emailAddress != billingInfo.emailAddress) {
        billingInfoChanges.push({ label: 'Email Address', value: emailAddress });
      }
      if (tellNo != billingInfo.tellNo) {
        billingInfoChanges.push({ label: 'Tel No.', value: tellNo });
      }
      if (invoiceAddress != billingInfo.invoiceAddress) {
        billingInfoChanges.push({ label: 'Invoice Address', value: invoiceAddress });
      }
      if (vatNumber != billingInfo.vatNumber) {
        billingInfoChanges.push({ label: 'VAT Number', value: vatNumber });
      }
      if (billingInfoChanges.length) {
        changes.push({
          title: `Billing Information`,
          fields: billingInfoChanges
        });
      }

      const normativeChanges = [],
        { dlt, isProvisional, gbHashAlgo, gbHash, gbUtcTimeStamp, activationDate } = this.normativeData,
        { normative } = this.dltRecords;

      if (dlt != normative.dlt) {
        normativeChanges.push({ label: 'Distributed Ledger Technology', value: dlt });
      }
      if (gbHashAlgo != normative.gbHashAlgo) {
        normativeChanges.push({ label: 'Genesis Block Hash Algorithm', value: gbHashAlgo });
      }
      if (gbHash != normative.gbHash) {
        normativeChanges.push({ label: 'Genesis Block Hash ', value: gbHash });
      }
      if (isProvisional != normative.isProvisional) {
        normativeChanges.push({ label: 'Provisional', value: isProvisional ? 'Yes' : 'No' });
      }
      if (new Date(gbUtcTimeStamp).getTime() != new Date(normative.gbUtcTimeStamp).getTime()) {
        normativeChanges.push({ label: 'Genesis Block UTC Timestamp', value: formatDate(gbUtcTimeStamp, 'dd/MM/yyyy HH:mm:ss', 'en-US') });
      }

      if (normativeChanges.length) {
        changes.push({
          title: 'Normative Attributes',
          fields: normativeChanges
        })
      }

      const informativeChanges = [],
        { longName, hasOrigLongName, originalLongName, tokenRefUrl, publicDistLedgerIndicator, } = this.informativeData,
        { informative } = this.dltRecords;
      if (longName != informative.longName) {
        informativeChanges.push({ label: 'Long Name', value: longName })
      }
      if (originalLongName != informative.originalLongName) {
        informativeChanges.push({ label: 'Original Language Long Name', value: originalLongName })
      }
      if (tokenRefUrl != informative.tokenRefUrl) {
        informativeChanges.push({ label: 'Token Reference Url', value: tokenRefUrl })
      }
      if (publicDistLedgerIndicator != informative.publicDistLedgerIndicator) {
        informativeChanges.push({ label: 'Public Distributed Ledger Indicator', value: publicDistLedgerIndicator })
      }
      if (informativeChanges.length) {
        changes.push({
          title: 'Informative Attributes',
          fields: informativeChanges
        })
      }

      const nativeTokenChanges: any[] = this.dltRecords.nativeTokens.reduce((tabchange: any[], nativeToken: any, index: number) => {
        const tokenNumber = index + 1;
        if (index < this.nativeTokenData.length) {
          const { attribute, shortNames, originalShortNames, externalIdentifier } = this.nativeTokenData[index],
            { unitMultiplier } = attribute,
            shortNamesDiff = DifferenceOfStringList(nativeToken.shortNames, shortNames),
            originalShortNamesDiff = DifferenceOfStringList(nativeToken.originalShortNames, originalShortNames || []),
            underLyingAssetsTypeDiff = DifferenceOfObjectList(nativeToken.externalIdentifier.underLyingAssets, externalIdentifier.underLyingAssets, 'type'),
            underLyingAssetsValueDiff = DifferenceOfObjectList(nativeToken.externalIdentifier.underLyingAssets, externalIdentifier.underLyingAssets, 'value'),
            digitalTokensTypeDiff = DifferenceOfObjectList(nativeToken.externalIdentifier.digitalTokens, externalIdentifier.digitalTokens, 'type'),
            digitalTokensValueDiff = DifferenceOfObjectList(nativeToken.externalIdentifier.digitalTokens, externalIdentifier.digitalTokens, 'value'),
            groups = [];

          if (!!shortNamesDiff) {
            groups.push({ label: `Short Name(s)`, value: shortNamesDiff });
          }
          if (!!originalShortNamesDiff) {
            groups.push({ label: `Original Short Name(s)`, value: originalShortNamesDiff });
          }
          if (unitMultiplier != nativeToken.attribute.unitMultiplier && (!!unitMultiplier || !!nativeToken.attribute.unitMultiplier)) {
            groups.push({ label: `Unit Multiplier`, value: unitMultiplier });
          }
          if (!!underLyingAssetsTypeDiff) {
            groups.push({ label: `Underlying Asset External Identifiers Type`, value: underLyingAssetsTypeDiff });
          }
          if (!!underLyingAssetsValueDiff) {
            groups.push({ label: `Underlying Asset External Identifiers Value`, value: underLyingAssetsValueDiff });
          }
          if (!!digitalTokensTypeDiff) {
            groups.push({ label: `Digital Token External Identifiers Type`, value: digitalTokensTypeDiff });
          }
          if (!!digitalTokensValueDiff) {
            groups.push({ label: `Digital Token External Identifiers Value`, value: digitalTokensValueDiff });
          }
          if (groups.length) tabchange.push({ groups, title: `Native Token ${tokenNumber}` })
        } else if (!!nativeToken.attribute.unitMultiplier) {
          const groups = [];
          groups.push({ label: `Short Name(s)`, value: '' });
          groups.push({ label: `Original Short Name(s)`, value: '' });
          groups.push({ label: `Unit Multiplier`, value: '' });
          groups.push({ label: `Underlying Asset External Identifiers Type`, value: '' });
          groups.push({ label: `Underlying Asset External Identifiers Value`, value: '' });
          groups.push({ label: `Digital Token External Identifiers Type`, value: '' });
          groups.push({ label: `Digital Token External Identifiers Value`, value: '' });
          tabchange.push({ groups, title: `Native Token ${tokenNumber}` })
        }

        return tabchange;
      }, []);

      if (nativeTokenChanges.length) changes.push({ title: 'Native Token Creation', hasMultipleGroup: true, fields: nativeTokenChanges });


      const auxiliaryChanges: any[] = []
      this.dltRecords.auxiliaryTokens.forEach((auxiliaryToken: any, index: number) => {
        const tokenNumber = index + 1;
        if (index < this.auxiliaryData.length) {
          const { auxiliaryMechanism, auxiliaryMechanismDesc, auxiliaryMechanismTechRef, auxiliaryMechanismVerificationDetail }: any = this.auxiliaryData[index],
            groups = [];

          if (auxiliaryToken.auxiliaryMechanism != auxiliaryMechanism) {
            groups.push({ label: `Auxiliary Mechanism`, value: auxiliaryMechanism })
          }
          if (auxiliaryToken.auxiliaryMechanismDesc != auxiliaryMechanismDesc) {
            groups.push({ label: `Auxiliary Mechanism Description`, value: auxiliaryMechanismDesc })
          }
          if (auxiliaryToken.auxiliaryMechanismTechRef != auxiliaryMechanismTechRef) {
            groups.push({ label: `Auxiliary Digital Token Technical Reference`, value: auxiliaryMechanismTechRef })
          }
          if (auxiliaryToken.auxiliaryMechanismVerificationDetail != auxiliaryMechanismVerificationDetail) {
            groups.push({ label: `Auxiliary Digital Token Verification Details`, value: auxiliaryMechanismVerificationDetail })
          }

          if (groups.length) auxiliaryChanges.push({ groups, title: `Auxiliary Token ${tokenNumber}` });

        } else if (!!auxiliaryToken.auxiliaryMechanism && !!auxiliaryToken.auxiliaryMechanismDesc && !!auxiliaryToken.auxiliaryMechanismTechRef && !!auxiliaryToken.auxiliaryMechanismVerificationDetail) {
          const groups = [
            { label: `Auxiliary Mechanism`, value: '' },
            { label: `Auxiliary Mechanism Description`, value: '' },
            { label: `Auxiliary Digital Token Technical Reference`, value: '' },
            { label: `Auxiliary Digital Token Verification Details`, value: '' }
          ]
          auxiliaryChanges.push({ groups, title: `Auxiliary Token ${tokenNumber}` });
        }

      });

      if (auxiliaryChanges.length) {
        changes.push({
          title: 'Auxiliary Token Creation',
          hasMultipleGroup: true,
          fields: auxiliaryChanges
        })
      }

    }


    return changes;
  }

  get creation() {
    return this.requestState === RequestState.Creation;
  }

  get editing() {
    return this.requestState === RequestState.Editing;
  }

  get processing() {
    return this.requestState === RequestState.Processing;
  }

  get successfull() {
    return this.requestState === RequestState.Successful;
  }

  get nativeTokens(): FormArray {
    return <FormArray>this.getNativeTokenControl('nativeTokens');
  }

  get auxiliaries(): FormArray {
    return <FormArray>this.getAuxiliaryControl('auxiliaryMechanisms');
  }

  getAuxiliaryControl(controlName: string) {
    return this.auxiliaryFormGroup.get(controlName);
  }

  get hasLongNameMaxLength(): boolean {
    const maxLength = 100; // flag for long name reach the character limit then adjust the layout
    return (this.informativeFormGroup.value.longName || '').length > maxLength;
  }

  get nativeTokenSelectedTab(): number {
    return (<FormArray>this.getNativeTokenControl('nativeTokens')).length - 1;
  }

  get auxiliarySelectedTab(): number {
    return (<FormArray>this.getAuxiliaryControl('auxiliaryMechanisms')).length - 1;
  }

  // previous,summary,continue button
  get hasGotoPrevious(): boolean {
    return this.hasGotoReview ? this.selectedTabIndex > -1 : !this.successfull
  }

  get hasGotoReview(): boolean {
    return !!Object.keys(this.dltRecords).length && this.continueText === 'Continue';
  }

  get applicationId(): string {
    return this.dltService.applicationId;
  }


  isTouched(): boolean {
    return (this.creation || this.editing) && (this.initiatorFormGroup.dirty || this.informativeFormGroup.dirty || this.normativeFormGroup.dirty
      || this.nativeTokenFormGroup.dirty || this.auxiliaryFormGroup.dirty);
  };

  // event for backward button
  onClickPrevious(): void {
    const selectedTabIndex = this.stepper ? this.stepper.selectedIndex : -1;
    this.isShowGlossary = false;
    switch (selectedTabIndex) {
      case 0:
        this.selectedTabIndex = -1;
        break;
      case -1:
        this.router.navigateByUrl('/')
        break
      default:
        this.stepper.previous();
    }
  }
  // event for forward button
  onClickContinue(isContinue = true): void {
    const selectedTabIndex = this.stepper ? this.stepper.selectedIndex : -1,
      setLayoutViews = () => {
        if (this.continueText === 'Submit') {
          this.elSummary.nativeElement.scrollIntoView();

          // setting native token tab as visited
          if (this.initiatorData.hasNativeToken) {
            this.wasStepInNativeToken = true;
          }
          // setting auxiliary tab as visited
          if (this.initiatorData.hasAuxiliaryToken) {
            this.wasStepInAuxiliary = true;
          }
        }
      };
    this.isShowGlossary = false;

    switch (selectedTabIndex) {
      case -1: // on Initiator
        this.initiatorFormGroup.markAllAsTouched();
        if (this.initiatorFormGroup.valid) {
          // going to normative attributes
          this.selectedTabIndex = 0;
          if (!isContinue) {
            setTimeout(() => {
              this.stepper.selectedIndex = this.tabsCount;
              setLayoutViews();
            }, 100)
          }
        }
        break;
      case 0: // on Normative
        this.normativeFormGroup.markAllAsTouched();
        if (this.normativeFormGroup.valid) {
          // going to Informative Tab
          (isContinue) ? this.stepper.next() : this.stepper.selectedIndex = this.tabsCount;
        }
        break;
      case 1: // on Informative
        this.informativeFormGroup.markAllAsTouched();
        if (this.informativeFormGroup.valid) {
          // going to Native | Auxiliary | Summary
          (isContinue) ? this.stepper.next() : this.stepper.selectedIndex = this.tabsCount;
        }
        break;
      case 2:
        if (this.initiatorData.hasNativeToken) {
          this.nativeTokenFormGroup.markAllAsTouched()
          // going to Auxiliary | Summary
          if (this.nativeTokenFormGroup.valid) {
            (isContinue) ? this.stepper.next() : this.stepper.selectedIndex = this.tabsCount;
          }
        }
        else if (this.initiatorData.hasAuxiliaryToken) {
          this.auxiliaryFormGroup.markAllAsTouched()
          // going to Summary
          if (this.auxiliaryFormGroup.valid) {
            (isContinue) ? this.stepper.next() : this.stepper.selectedIndex = this.tabsCount;
          }
        } else {
          this.showTermsAndCondition();
        }
        break;
      case 3:
        if (this.initiatorData.hasNativeToken && this.initiatorData.hasAuxiliaryToken) {
          this.auxiliaryFormGroup.markAllAsTouched()
          // going to Summary
          if (this.auxiliaryFormGroup.valid) {
            (isContinue) ? this.stepper.next() : this.stepper.selectedIndex = this.tabsCount;
          }
        }
        else {
          this.showTermsAndCondition();
        }
        break;
      default:
        this.showTermsAndCondition();
    }

    setLayoutViews();

  }

  onClickReview(): void {
    if (!this.stepper) {
      this.selectedTabIndex = 0;
    }
    setTimeout(() => {
      this.stepper.selectedIndex = this.tabsCount;
    }, 100)
  }

  // ----------------
  //     EVENTS      |
  // ----------------
  dltOnChange(): void {
    const gbha = this.normativeFormGroup.get('gbHashAlgo'),
      gbh = this.normativeFormGroup.get('gbHash'),
      gbUTCTime = this.normativeFormGroup.get('gbUtcTimeStamp');

    gbha?.reset();
    gbh?.reset();
    gbUTCTime?.reset();

    gbha?.clearValidators();
    gbh?.clearValidators();
    gbUTCTime?.clearValidators();

    if (this.isDLTBlockchain) {
      gbha?.setValidators([Validators.required]);
      gbh?.setValidators([Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_VALID_ASCII)]);
      gbUTCTime?.setValidators([Validators.required]);
    }
    gbha?.updateValueAndValidity();
    gbh?.updateValueAndValidity();
    gbUTCTime?.updateValueAndValidity();
  }

  // radio button LEI on change
  onChangeLEI() {
    const lei = this.initiatorFormGroup.get('lei'),
      companyName = this.initiatorFormGroup.get('companyName'),
      identifierCode = this.initiatorFormGroup.get('businessIdentifierCode');
    lei?.clearValidators();
    companyName?.clearValidators();
    identifierCode?.clearValidators();
    if (this.initiatorFormGroup.get('isCreator')?.value) {
      if (this.initiatorData.hasLei) {
        lei?.setValidators([Validators.required, Validators.pattern(/^(?:[a-zA-Z0-9\s]+)?$/)]);
      } else {
        companyName?.setValidators([Validators.required, Validators.pattern(VALIDATIONS.COMPANY_NAME)]);
        identifierCode?.setValidators([Validators.pattern(VALIDATIONS.UTF_8_WITH_RESTRICT_SYMBOL)]);
      }
    }
    lei?.updateValueAndValidity;
    companyName?.updateValueAndValidity();
    identifierCode?.updateValueAndValidity();
  }

  onTabChange(selectedIndex: number) {
    if (selectedIndex > -1) {
      this.stepper.selectedIndex = selectedIndex
    }
    this.selectedTabIndex = selectedIndex;
  }

  onChangeOrigLangShortName({ checked }: MatCheckboxChange, nativeTokenIndex: number) {
    checked ? this.addOrigLangShortName(nativeTokenIndex) : this.getOrigLangShortNames(nativeTokenIndex).clear();
  }

  onChangeExternalIdentifiers({ checked }: MatCheckboxChange, nativeTokenIndex: number) {
    if (checked) {
      this.addUnderlyingAsset(nativeTokenIndex);
      this.addExternalIdentifier(nativeTokenIndex);
    } else {
      this.getUnderlyingAssets(nativeTokenIndex).clear();
      this.getExternalIdentifiers(nativeTokenIndex).clear();
    }
  }

  onSelectExternalIdentifiers(nativeTokenIndex: number, controlIndex: number, isUnderyingAssets = false) {
    const formArrayControl = isUnderyingAssets ? this.getUnderlyingAssets(nativeTokenIndex) : this.getExternalIdentifiers(nativeTokenIndex),
      valueControl = formArrayControl.at(controlIndex).get('value');
    valueControl?.setValidators([Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC)]);
    valueControl?.updateValueAndValidity();
    valueControl?.enable()
  }


  // ------------------
  //    METHODS       |
  // -----------------

  submitTokenRequest() {
    if (this.requestState != RequestState.Processing) {
      this.requestState = RequestState.Processing;
      const requestData: DLT = {
        ...this.initiatorData,
        normative: { ...this.normativeData },
        informative: { ...this.informativeData },
        nativeTokens: [...this.nativeTokenData],
        auxiliaryTokens: [...this.auxiliaryData],
        metaData: {
          email: this.userService.email,
          timeStamp: (new Date()).toISOString()
        },
        additionalInfo: this.additionalInfo,
        billingInfo: { ...this.billingInfo }
      };
      if (!Object.keys(this.dltRecords).length) {
        this.loadingText = 'Submitting your request. Please wait…';
        this.dltService.submitToken(requestData).subscribe(({ key }: any) => {
          this.dltService.applicationId = key;
          this.successTitle = 'New token has been successfully submitted!';
          this.requestState = RequestState.Successful;
        }, error => {
          alert('Something went wrong in connections. \n Please try again.')
          this.requestState = RequestState.Error;
        });
      } else {
        this.loadingText = 'Updating your request. Please wait…';
        this.dltService.updateRequestDetail(requestData).subscribe((data) => {
          this.successTitle = 'Form successfully updated!';
          this.requestState = RequestState.Successful;
        });
      }
    }
  }

  addNativeTokenTab(): void {
    this.nativeTokens.push(this.createNativeTokenControlGroup())
  }

  createNativeTokenControlGroup() {
    return this.formBuilder.group({
      shortNames: this.formBuilder.array([this.createShortNameControlGroup()]),
      hasOrigLangShortName: [false],
      hasExternalIdentifier: [false],
      origlangShortNames: this.formBuilder.array([]),
      underlyingAssets: this.formBuilder.array([]),
      externalIdentifiers: this.formBuilder.array([]),
      unitMultiplier: ['', [Validators.pattern(VALIDATIONS.FLOATING_NUMBER)]]
    })
  }

  createShortNameControlGroup(shortName = '') {
    return this.formBuilder.group({
      shortName: [shortName, [Validators.pattern(VALIDATIONS.ALPHA_NUMERIC)]]
    })
  }

  createOrigLangShortNameControlGroup(origlangShortName = '') {
    return this.formBuilder.group({
      origlangShortName: [origlangShortName, [Validators.pattern(VALIDATIONS.UTF_8_WITH_RESTRICT_SYMBOL)]]
    })
  }

  createExternalIdentifierControlGroup(type = '', value = '') {
    console.log(VALIDATIONS.ALPHA_NUMERIC);
    return this.formBuilder.group({
      type: [type],
      value: [{ value, disabled: !value }, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC)]
    })
  }

  createAuxiliryControlGroup(auxiliaryMechanism = null, auxiliaryMechanismDesc = null, auxiliaryMechanismTechRef = null, auxiliaryMechanismVerificationDetail = null) {
    const validations = [Validators.pattern(VALIDATIONS.UTF_8_WITH_RESTRICT_SYMBOL)];
    return this.formBuilder.group({
      auxiliaryMechanism: [auxiliaryMechanism, validations],
      auxiliaryMechanismDesc: [auxiliaryMechanismDesc, validations],
      auxiliaryMechanismTechRef: [auxiliaryMechanismTechRef, validations],
      auxiliaryMechanismVerificationDetail: [auxiliaryMechanismVerificationDetail, validations]
    })
  }

  addShortName(nativeTokenIndex: number): void {
    this.getShortNames(nativeTokenIndex).push(this.createShortNameControlGroup());
  }

  addOrigLangShortName(nativeTokenIndex: number) {
    this.getOrigLangShortNames(nativeTokenIndex).push(this.createOrigLangShortNameControlGroup())
  }

  addUnderlyingAsset(nativeTokenIndex: number) {
    this.getUnderlyingAssets(nativeTokenIndex).push(this.createExternalIdentifierControlGroup())
  }

  addExternalIdentifier(nativeTokenIndex: number) {
    this.getExternalIdentifiers(nativeTokenIndex).push(this.createExternalIdentifierControlGroup());
  }

  deleteShortName(nativeTokenIndex: number, shortNameIndex: number): void {
    this.getShortNames(nativeTokenIndex).removeAt(shortNameIndex);
  }

  deleteOrigLangShortName(nativeTokenIndex: number, origlangShortNameIndex: number) {
    this.getOrigLangShortNames(nativeTokenIndex).removeAt(origlangShortNameIndex);
  }

  deleteUnderlyingAsset(nativeTokenIndex: number, underlyingAssetIndex: number) {
    this.getUnderlyingAssets(nativeTokenIndex).removeAt(underlyingAssetIndex);
    // for the first row delete icon would reset only the selection and value
    if (!this.getUnderlyingAssets(nativeTokenIndex).length) {
      this.addUnderlyingAsset(nativeTokenIndex);
    }
  }

  deleteExternalIdentifier(nativeTokenIndex: number, externalIdentifierIndex: number) {
    this.getExternalIdentifiers(nativeTokenIndex).removeAt(externalIdentifierIndex);
    // for the first row delete icon would reset only the selection and value
    if (!this.getExternalIdentifiers(nativeTokenIndex).length) {
      this.addExternalIdentifier(nativeTokenIndex);
    }

  }

  setFieldsValue(hasAuxiliaryToken: boolean, hasNativeToken: boolean) {
    const { isCreator, lei, companyName, businessIdentifierCode, billingInfo, normative, informative, auxiliaryTokens, nativeTokens, additionalInfo }: any = this.dltRecords,
      { customerName, financeContract, emailAddress, tellNo, invoiceAddress, vatNumber } = billingInfo,
      { dlt, gbHashAlgo, gbHash, gbUtcTimeStamp } = normative,
      { longName, originalLongName, tokenRefUrl, publicDistLedgerIndicator } = informative,
      hasLei = !!lei?.length,
      hasOrigLongName = !!originalLongName;

    this.initiatorFormGroup.patchValue(
      {
        lei, isCreator, hasLei,
        companyName, businessIdentifierCode,
        hasNativeToken, hasAuxiliaryToken,
        customerName, financeContract,
        emailAddress, tellNo, invoiceAddress, vatNumber
      })
    if (isCreator) this.initiatorComp.setFieldsValidation(false);

    this.normativeFormGroup.patchValue({ dlt, gbHashAlgo, gbHash, gbUtcTimeStamp: new Date(gbUtcTimeStamp) });

    this.informativeFormGroup.patchValue({ longName, hasOrigLongName, originalLongName, tokenRefUrl, publicDistLedgerIndicator, });

    if (hasNativeToken) {
      nativeTokens.forEach(({ shortNames, originalShortNames, attribute, externalIdentifier }: any) => {
        const { unitMultiplier } = attribute;
        if (!!unitMultiplier) { // just validate unit multiplier since it is required in Native Token Creation tab 
          const shortNamesControls = shortNames.reduce((controls: any[], shortName: string) => {
            if (!!shortName) controls.push(this.createShortNameControlGroup(shortName))
            return controls;
          }, []),
            originalShortNamesControls: any[] = originalShortNames.reduce((controls: any[], originalShortName: string) => {
              if (!!originalShortName) controls.push(this.createOrigLangShortNameControlGroup(originalShortName))
              return controls;
            }, []),
            underlyingAssetsControls = externalIdentifier.underLyingAssets.reduce((controls: any[], { type, value }: any) => {
              if (!!type && !!value) controls.push(this.createExternalIdentifierControlGroup(type, value));
              return controls;
            }, []),
            digitalTokensControls = externalIdentifier.digitalTokens.reduce((controls: any[], { type, value }: any) => {
              if (!!type && !!value) controls.push(this.createExternalIdentifierControlGroup(type, value));
              return controls;
            }, []),
            hasExternalIdentifier = !!underlyingAssetsControls.length || !!digitalTokensControls.length;

          this.nativeTokens.push(
            this.formBuilder.group({
              shortNames: this.formBuilder.array(shortNamesControls.length ? shortNamesControls : [this.createShortNameControlGroup()]),
              hasOrigLangShortName: [!!originalShortNamesControls.length],
              hasExternalIdentifier: [hasExternalIdentifier],
              origlangShortNames: this.formBuilder.array(originalShortNamesControls),
              underlyingAssets: this.formBuilder.array(hasExternalIdentifier ? underlyingAssetsControls.length ? underlyingAssetsControls : [this.createExternalIdentifierControlGroup()] : []),
              externalIdentifiers: this.formBuilder.array(hasExternalIdentifier ? digitalTokensControls.length ? digitalTokensControls : [this.createExternalIdentifierControlGroup()] : []),
              unitMultiplier: [unitMultiplier, [Validators.pattern(VALIDATIONS.FLOATING_NUMBER)]]
            })
          );
        }
      });
    } else {
      this.addNativeTokenTab();
    }

    if (hasAuxiliaryToken) {
      auxiliaryTokens.forEach(({ auxiliaryMechanism, auxiliaryMechanismDesc, auxiliaryMechanismTechRef, auxiliaryMechanismVerificationDetail }: any) => {
        // need to validate not all items has a value and API always give all 3 tabs values.
        if (!!auxiliaryMechanism && !!auxiliaryMechanismDesc && !!auxiliaryMechanismTechRef && !!auxiliaryMechanismVerificationDetail) {
          this.auxiliaries.push(
            this.createAuxiliryControlGroup(auxiliaryMechanism, auxiliaryMechanismDesc, auxiliaryMechanismTechRef, auxiliaryMechanismVerificationDetail)
          )
        }
      });
    }
    else {
      this.addAuxiliaryTab();
    }

    this.additionalInfo = additionalInfo;
  }

  // ------------------
  //      Controls     |
  // -----------------

  getNativeTokenControl(controlName: string) {
    return this.nativeTokenFormGroup.get(controlName);
  }

  getOrigLangShortNames(index: number): FormArray {
    return <FormArray>this.nativeTokens.at(index).get('origlangShortNames');
  }

  getShortNames(nativeTokenIndex: number): FormArray {
    return <FormArray>this.nativeTokens.at(nativeTokenIndex).get('shortNames');
  }

  getUnderlyingAssets(nativeTokenIndex: number): FormArray {
    return <FormArray>this.nativeTokens.at(nativeTokenIndex).get('underlyingAssets');
  }

  getExternalIdentifiers(nativeTokenIndex: number): FormArray {
    return <FormArray>this.nativeTokens.at(nativeTokenIndex).get('externalIdentifiers');
  }

  removeNativeTokenTab(event: Event, index: number): void {
    event.stopPropagation();
    this.nativeTokens.removeAt(index);
  }

  addAuxiliaryTab() {
    this.auxiliaries.push(this.createAuxiliryControlGroup())
  }

  removeAuxiliaryTab(event: Event, index: number): void {
    event.stopPropagation();
    this.auxiliaries.removeAt(index);
  }

  textCounter({ value }: any, limit = 255) {
    const counter = value ? value.length : 0;
    return `${counter}/${limit}`;
  }

  showTermsAndCondition() {
    this.dialog.open(TermsAndConditionDialogComponent, { autoFocus: false })
      .afterClosed().subscribe((accept: boolean) => {
        accept && this.submitTokenRequest();
      });
  }
}