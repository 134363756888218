import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _email = '';
  private _name = '';
  private _nickname = '';
  private _picture = '';

  private bsEmail = new BehaviorSubject<string>('');
  $email = this.bsEmail.asObservable();

  constructor() { }

  set email(email: string) {
    this._email = email;
    this.bsEmail.next(email);
  }
  get email(): string {
    return this._email;
  }


  set name(name: string) {
    this._name = name;
  }
  get name() {
    return this._name;
  }

  set nickname(nickname: string) {
    this._nickname = nickname;
  }
  get nickname() {
    return this._nickname;
  }

  set picture(url: string) {
    this._picture = url;
  }
  get picture() {
    return this._picture;
  }

}
