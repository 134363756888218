import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ApiDocumentationsComponent } from './api-documentations/api-documentations.component';
import { Auth0RegisterComponent } from './auth0-register/auth0-register.component';
import { CallbackComponent } from './callback/callback.component';
import { DistributedLedgerTokenComponent } from './pages/distributed-ledger-token/distributed-ledger-token.component';
import { MultipleDropdownFieldComponent } from './components/multiple-dropdown-field/multiple-dropdown-field.component';
import { MultipleInputFieldComponent } from './components/multiple-input-field/multiple-input-field.component';
import { RegistrationComponent } from './registration/registration.component'
import { RegisterTokenStepsComponent } from './components/register-token-steps/register-token-steps.component';

import { MatNativeDateModule, MAT_DATE_LOCALE, } from '@angular/material/core';
// TODO: Need to create separate module for Material UI
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper'
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';

import { NgxMatNativeDateModule, NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuxiliaryTokenComponent } from './pages/auxiliary-token/auxiliary-token.component';
import { InitiatorTabComponent } from './components/initiator-tab/initiator-tab.component';
import { PreviousNextComponent } from './components/previous-next/previous-next.component';
import { SuccessSubmissionComponent } from './components/success-submission/success-submission.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MyRequestsComponent } from './pages/my-requests/my-requests.component';
import { TokenRequestSummaryComponent } from './pages/token-request-summary/token-request-summary.component';
import { SummaryComponent } from './components/summary/summary.component';
import { CommentsComponent } from './components/comments/comments.component';
import { ConfirmationDiallogComponent } from './components/confirmation-diallog/confirmation-diallog.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { LinkToGlossaryComponent } from './components/link-to-glossary/link-to-glossary.component';
import { LinkToContactUsComponent } from './components/link-to-contact-us/link-to-contact-us.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { TokenInterceptor } from './token.interceptor';
import { RightSideMenusComponent } from './components/right-side-menus/right-side-menus.component';
import { TabGlossaryComponent } from './components/tab-glossary/tab-glossary.component';
import { TermsAndConditionDialogComponent } from './components/terms-and-condition-dialog/terms-and-condition-dialog.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RequestListComponent } from './components/request-list/request-list.component';


@NgModule({
  declarations: [
    AppComponent,
    RegistrationComponent,
    DistributedLedgerTokenComponent,
    RegisterTokenStepsComponent,
    Auth0RegisterComponent,
    CallbackComponent,
    ApiDocumentationsComponent,
    MultipleInputFieldComponent,
    MultipleDropdownFieldComponent,
    AuxiliaryTokenComponent,
    InitiatorTabComponent,
    PreviousNextComponent,
    SuccessSubmissionComponent,
    LoadingComponent,
    MyRequestsComponent,
    TokenRequestSummaryComponent,
    SummaryComponent,
    CommentsComponent,
    ConfirmationDiallogComponent,
    MessageDialogComponent,
    LinkToGlossaryComponent,
    LinkToContactUsComponent,
    ErrorDialogComponent,
    RightSideMenusComponent,
    TabGlossaryComponent,
    TermsAndConditionDialogComponent,
    DashboardComponent,
    RequestListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatToolbarModule,
    MatStepperModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    NgxMatTimepickerModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatCardModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatDialogModule,
    MatMenuModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
