import { Component, OnInit } from '@angular/core';
import * as auth0 from 'auth0-js';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './data/user.service';
import { environment } from 'src/environments/environment';
import packageJson from '../../package.json'
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'dti-tokens-form';
  profile: any;
  appVersion = packageJson.version;
  year = (new Date()).getFullYear();

  private auth0 = new auth0.WebAuth({
    clientID: environment.clientID,
    domain: environment.domain,
    redirectUri: window.location.origin,
    responseType: 'token id_token',
    scope: 'openid profile email'
  });

  constructor(public router: Router, private user: UserService, private activatedRoute: ActivatedRoute, private cookieService: CookieService) {
    console.log(environment.title);
  }

  get isOnSignUp(): boolean {
    return window.location.pathname === '/signup';
  }

  get isOnBlocked(): boolean {
    return window.location.pathname === '/blocked';
  }

  get pathName() {
    return window.location.pathname.replace('/', '').split('/')[0].replaceAll('-', ' ');
  }

  get applicationId() {
    const urlItems = window.location.pathname.replace('/', '').split('/');
    return urlItems.length > 1 ? ` - ${urlItems[1]}` : '';
  }

  get profileName(): string {
    return this.user.name;
  }

  get profileEmail(): string {
    return this.user.email;
  }

  get picture(): string {
    return this.user.picture
  }

  get logoLink(): string {
    return environment.baseUrl;
  }

  // TODO: Need to optimize regarding redirection when logging
  get hideWhenCallback(): boolean {
    return this.pathName.toLocaleLowerCase() !== 'callback'
  }

  ngOnInit() {
    this.getProfile();
  }

  login() {
    this.auth0.authorize({
      prompt: 'login'
    });
  }

  logout() {
    const expirationDate = new Date('Thu, 01 Jan 1970 00:00:00 UTC');
    this.cookieService.set('access_token', '', expirationDate, '/', environment.cookieDomain);
    this.cookieService.set('name', '', expirationDate, '/', environment.cookieDomain);
    this.cookieService.set('email', '', expirationDate, '/', environment.cookieDomain);
    this.profile = null;
    this.auth0.logout({
      returnTo: environment.baseUrl + '/sign-out',
      clientID: environment.clientID
    });
  }

  get isLoggedIn() {
    const accessToken = this.cookieService.get('access_token');
    return !!accessToken;
  }

  private getProfile() {
    const accessToken = this.cookieService.get('access_token') || '{}';
    this.user.email = this.cookieService.get('email');
    this.user.name = this.cookieService.get('name');
    if (!accessToken) {
      console.log('Access token not found');
    }
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.user.email = profile.email || '';
        this.user.name = profile.name || '';
        this.user.nickname = profile.nickname || '';
        this.user.picture = profile.picture || '';
        this.profile = profile;
      }
    });
  }
}
