<div class="container">
    <div class="row title-section">
        <div class="col">
            <label class="title">Comments ({{comments.length}})</label>
        </div>
    </div>
    <div class="comment-section">
        <div class="comment-row" *ngFor="let comment of comments">
            <div class="users-column">
                <i class="material-icons-round material-icons-font material-icons">
                    account_circle
                </i>
            </div>
            <div class=" flex-grow-3 comment-column">
                <p><span class="username" [innerHTML]="removeBreakTag(comment.author)"></span><span class="date-comment"> &#183; {{dateAgo(comment.created)}}</span></p>
                <p class="comment" [innerHTML]="comment.text"></p>
            </div>
        </div>
        <div class="comment-row pt-50">
            <div class=" users-column">
                <i class="material-icons-round material-icons-font material-icons">
                    account_circle
                </i>
            </div>
            <div class=" flex-grow-3 comment-column">
                <textarea matInput [(ngModel)]="textComment" class=" input-comment" placeholder="Add a comment" cdkTextareaAutosize cdkAutosizeMinRows="5" maxlength="750"></textarea>
                 <p class="input-comment-options">
                     <button mat-raised-button (click)="addComment()" disableRipple="true" color="accent" class="button">Add comment</button>
                     <mat-hint class="input-count">{{textComment.length}}/750</mat-hint>
                 </p>
            </div>
        </div>
    </div>
</div>