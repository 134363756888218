<div class="row container container-step submitted-container">
    <div class="col content flex-column">
        <i class="check-circle material-symbols-outlined material-icons-font material-icons">
            check_circle
        </i>
        <div class="row flex-column">
            <p class="col submit-message">{{title}}</p>
            <p class="col ticket-id">Application ID: {{applicationId}}</p>
        </div>
        <div class="row flex-column text-center token-details">
            <label class="col token-name flex-column"><b>{{tokenLongName}}</b><span> {{shortName}}</span></label>
            <label class="col token-type"><b>{{tokenType}}</b> | {{mechanism}}</label>
        </div>
        <a routerLink="/dti-allocation" mat-raised-button disableRipple="true" color="accent" class="submit-new-token-link">Submit a New Token</a>
    </div>
</div>