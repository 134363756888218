import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'dti-previous-next',
  templateUrl: './previous-next.component.html',
  styleUrls: ['./previous-next.component.scss']
})
export class PreviousNextComponent {

  @Input() previousText: string = 'Back to Token Selection'
  @Input() continueText: string = 'Continue'
  @Input() hasContinue: boolean = true;
  @Input() hasPrevious: boolean = true;
  @Input() hasReview: boolean = false;
  @Input() continueDisable: boolean = false;

  @Output() previousClick = new EventEmitter();
  @Output() continueClick = new EventEmitter();
  @Output() reviewClick = new EventEmitter();

  clickPrevious(event: Event): void {
    event.preventDefault();
    this.previousClick.emit();
  }

  clickContinue(event: Event): void {
    event.preventDefault();
    this.continueClick.emit();
  }

  clickReview(event: Event): void {
    event.preventDefault();
    this.reviewClick.emit();
  }
}

