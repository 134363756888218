<table mat-table [dataSource]="dataSource">
    <!-- Type -->
    <ng-container matColumnDef="issueType">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let element"> <span class="{{element.issueType}}">{{element.issueType}}</span></td>
    </ng-container>
    <!-- Reference -->
    <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef> Application ID </th>
        <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
    </ng-container>

    <!-- Summary -->
    <ng-container matColumnDef="summary">
        <th mat-header-cell *matHeaderCellDef>Summary</th>
        <td mat-cell *matCellDef="let element" class="summary-cell"> {{element.summary}} </td>
    </ng-container>

    <!-- Date Submitted -->
    <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Date Submitted</th>
        <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'MM/dd/yyyy' }} </td>
    </ng-container>

    <!-- Status -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element"> <label class="{{element.status.toLowerCase()}}"> {{element.status}}</label> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" routerLink="/token-request-summary/{{row.reference}}" [queryParams]="getQueryParams(row)"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            <div class="no-results">
                <div class="icon-container">
                    <img class="image-icon" src="../../assets/img/archive.svg" alt="">
                </div>
                <label>Nothing to see here yet.</label>
                <a href="" routerLink="/dti-allocation">Register your first token</a>
            </div>
        </td>
    </tr>
</table>