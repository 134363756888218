import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dti-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {

  message = ''
  icon = '';
  title = '';


  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    this.message = data.message;
    this.icon = data.icon;
  }

}
