import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-multiple-input-field',
  templateUrl: './multiple-input-field.component.html',
  styleUrls: ['./multiple-input-field.component.scss']
})
export class MultipleInputFieldComponent {

  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() maxFields: number = 5;
  @Input() values: any[] = [{ value: '' }];



  get isPlusVisible() {
    return this.values.length < this.maxFields;
  }
  get isDeleteVisible() {
    return this.values.length > 1;
  }

  addField() {
    this.values.push({ value: '' });
  }

  removeField(index: number) {
    this.values.splice(index, 1);
  }
}
