import { HttpClient, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyRequestsService {

  private baseURL = environment.apiBaseURL;
  private url = `${this.baseURL}/v1/api/request`

  constructor(private http: HttpClient) { }

  getRequests(email: string, startAt = 0, maxResult = -1) {
    const urlWithParams = `${this.url}?email=${email}&startAt=${startAt}&maxResults=${maxResult}`;
    return this.http.get<any>(urlWithParams).pipe(
      map(({ data }) => {
        return data.map((item: any) => {
          item.createdAt = new Date(item.createdAt)
          item.issueType = item.issueType.slice(-4, -1)
          return item;
        });
      })
    );
  }
}
