import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MyRequestsService } from 'src/app/services/my-requests.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/data/user.service';
import { RequestState } from 'src/app/shared/models/enums';


export interface Request {
  id: string,
  issueType: string,
  reference: string,
  summary: string,
  createdAt: string,
  status: string
}

@Component({
  selector: 'dti-my-requests',
  templateUrl: './my-requests.component.html',
  styleUrls: ['./my-requests.component.scss']
})
export class MyRequestsComponent {

  searchByKeyword = ''; dateSubmitted = '';
  status = [];
  requestType = '';
  requestState = RequestState.Successful;

  statusOptions = ["Open", "In Progress", "Pending user input", "Escalated", "Verified", "Registered", "Rejected", "Enquiry Closed", "Canceled", "Dispute Accepted"]
  requestTypeOptions = [
    { name: "Distributed Ledger Technology Token Request", value: "DLT" },
    { name: "Auxiliary Digital Token Request", value: "AUX" }]
  // { name: "Functionally Fungible List of Token Request", value: "FFG" } // Temporary Comment Out.
  dateSubmittedOptions = ['All', 'Today', 'Yesterday', 'Last 7 days', 'Last 15 days', 'Last 30 days', 'Last 2 Months', 'Last 3 Months'];

  dataRequests = new MatTableDataSource([]);
  displayedColumns = ["issueType", "reference", "summary", "createdAt", "status"]

  constructor(private myRequestService: MyRequestsService, private user: UserService) { }

  get disableFilter(): boolean {
    return !this.dateSubmitted.toString().length &&
      !this.status.length &&
      !this.requestType.length &&
      !this.searchByKeyword.length;
  }

  get isProcessing(): boolean {
    return this.requestState === RequestState.Processing;
  }

  ngOnInit() {
    this.dataRequests.filterPredicate = (columns: any, filterString) => {

      const { reference, summary, status, issueType, createdAt } = columns,
        { summaryValue, referenceValue, statusValue, typeValue, dateSubmittedValue } = JSON.parse(filterString),

        filterByKeyword = () => {
          return (reference.trim().toLowerCase().indexOf(referenceValue) > -1 || summary.trim().toLowerCase().indexOf(summaryValue) > -1);
        },

        filterByStatus = () => {
          if (statusValue.length) { // For non empty string
            return statusValue.indexOf(status.toLowerCase()) > -1;
          }
          return true;
        },

        filterByType = () => {
          return (issueType.trim().toLowerCase().indexOf(typeValue) > -1);
        },

        filterByDate = () => {
          let from = 0; // 0 = All
          const now = new Date().setHours(0, 0, 0, 0)
          switch (dateSubmittedValue) {
            case 'Today':
              from = now;
              break;
            case 'Yesterday':
              from = new Date(now).setDate(new Date().getDate() - 1);
              break;
            case 'Last 7 days':
              from = new Date(now).setDate(new Date().getDate() - 7);
              break;
            case 'Last 15 days':
              from = new Date(now).setDate(new Date().getDate() - 15);
              break;
            case 'Last 30 days':
              from = new Date(now).setDate(new Date().getDate() - 30);
              break;
            case 'Last 2 Months':
              from = new Date(now).setMonth(new Date().getMonth() - 2);
              break;
            case 'Last 3 Months':
              from = new Date(now).setMonth(new Date().getMonth() - 3);
              break;
          }
          if (from) { // Today upto previous 3 months
            const date = (new Date(createdAt)).getTime(),
              to = (new Date()).getTime();
            return from <= date && to >= date;
          }
          return true;
        }


      return filterByKeyword() && filterByDate() && filterByStatus() && filterByType();
    }

    this.user.$email.subscribe((email) => {
      if (email.length) {
        this.requestState = RequestState.Processing;
        this.myRequestService.getRequests(email).subscribe((data) => {
          this.dataRequests.data = data;
          this.requestState = RequestState.Successful;
        });
      }
    });
  }

  clearFilter() {
    this.dateSubmitted = '';
    this.status = [];
    this.requestType = '';
    this.searchByKeyword = ''
    this.applyFilter();
  }

  applyFilter() {
    this.dataRequests.filter = JSON.stringify({
      summaryValue: this.searchByKeyword.trim().toLowerCase(),
      referenceValue: this.searchByKeyword.trim().toLowerCase(),
      statusValue: this.status.join('||').toLowerCase(),
      dateSubmittedValue: this.dateSubmitted,
      typeValue: this.requestType.trim().toLowerCase()
    })


  }

  getQueryParams(query: any): object {
    const { issueType } = query;
    return { issueType }
  }
}
