import { Component, ViewChild, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatStepper } from '@angular/material/stepper';
import { DltService } from 'src/app/services/dlt.service';
import { VALIDATIONS, VALIDATIONS_MESSAGE } from 'src/app/shared/util/validations';

@Component({
  selector: 'dti-register-token-steps',
  templateUrl: './register-token-steps.component.html',
  styleUrls: ['./register-token-steps.component.scss'],
})

export class RegisterTokenStepsComponent implements OnInit {

  VALIDATIONS_MESSAGE = VALIDATIONS_MESSAGE;


  @ViewChild('stepper') private stepper!: MatStepper;
  @ViewChild('normativeForm') normativeForm!: NgForm;

  @Input() options: any = {}

  @Output() tabChange = new EventEmitter<number>();

  MAXIMUM_TAB = 5;

  isOnRequest = false;
  isTokenRequestSuccess = false;

  //------------------
  // Normative Data  |
  // ----------------
  provisional = false;
  dateToBeActivated = null;
  dateToBeActivatedMin = new Date();
  // Genesis Block Hash Algorithm
  gbha = null;
  gbhaOptions = [
    `BLAKE2b-256`,
    `BLAKE2b-256 (0x)`,
    `Double SHA-256`,
    `Keccak-256`,
    `SteemHash`,
    `SHA512/256 (Base32)`,
    `SHA-256 (Base32)`,
    `SHA-256 (Base58)`,
    `SHA-256`,
    `SHA-384`,
    `N/A`
  ];
  // Genisis block hash
  gbh = null;
  // Genisis Block UTC TimeStamp
  gbUTCTime = '';

  //------------------  
  // Informative Data  |
  // ------------------
  // Original Language Long Name
  hasOLLN = false;
  longName = 'LN-Test';
  origLangLongName = 'OLLN-Test';
  publicDistributorLedgerIndicator = null

  additionalInfo = null;
  // ---------------------
  //  Native Token Data  |
  // --------------------


  underlyingAssetExternalIdentifierTypesOptions = [
    { value: 'CUSIP', text: 'CUSIP' },
    { value: 'SEDOL', text: 'SEDOL' },
    { value: 'QUIK', text: 'QUIK' },
    { value: 'ISIN', text: 'ISIN' },
    { value: 'RIC', text: 'RIC' }
  ]

  digitalTokenExternalIdentifiersTypesOptions = [
    { value: 'ITIN', text: 'ITIN' },
    { value: 'FIGI', text: 'FIGI' }
  ]


  // -----------------------
  //  Auxiliary Token Data  |
  // -----------------------


  genesis = null;

  tokenForkSelectedTab = 0;

  // Token Forks Tab 
  tokenForks = ['Fork'];

  // -----------------------
  //  Auxiliary Token Data  |
  // -----------------------
  normativeFG = this.formBuilder.group({
    dlt: ['', Validators.required],
    isProvisional: [{ value: false, disabled: true }],
    gbha: [''],
    gbh: [''],
    gbUTCTime: [''],
    activationDate: "2022-02-02T12:12:12",
  });

  informativeFG = this.formBuilder.group({
    longName: ['', [Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_SPACE)]],
    origLangLongName: [''],
    tokenReferenceURL: ['', [Validators.required, Validators.pattern(VALIDATIONS.URL)]], // Create global variable to hold the pattern
    publicDistributorLedgerIndicator: ['', Validators.required], // Public Distributed Ledger Indicator
  })

  nativeTokenFG = this.formBuilder.group({
    nativeTokens: this.formBuilder.array([
      this.createNativeTokenControlGroup()
    ])
  });

  auxiliaryFG = this.formBuilder.group({
    auxiliaryMechanisms: this.formBuilder.array([
      this.createAuxiliryControlGroup()
    ])
  });

  constructor(private formBuilder: FormBuilder, private dltService: DltService) {
  }

  ngOnInit(): void {
  }


  get applicationId() {
    return this.dltService.applicationId;
  }

  get isDLTBlockchain(): boolean {
    return this.normativeFG.get('dlt')?.value == 'Blockchain';
  }

  get nativeTokens(): FormArray {
    return <FormArray>this.getNativeTokenControl('nativeTokens');
  }

  get auxiliaries(): FormArray {
    return <FormArray>this.getAuxiliaryControl('auxiliaryMechanisms');
  }

  get auxiliarySelectedTab(): number {
    return (<FormArray>this.getAuxiliaryControl('auxiliaryMechanisms')).length - 1;
  }

  get nativeTokenSelectedTab(): number {
    return (<FormArray>this.getNativeTokenControl('nativeTokens')).length - 1;
  }

  // Data
  get normativeData(): any {
    return {
      dlt: this.getNormativeControl('dlt').value,
      isProvisional: this.normativeFG.get('isProvisional')?.value,
      activationDate: this.dateToBeActivated,
      ...(this.isDLTBlockchain ? {
        gbHashAlgo: this.getNormativeControl('gbha').value,
        gbHash: this.getNormativeControl('gbh').value,
        gbUtcTimeStamp: this.getNormativeControl('gbUTCTime').value
      } : {}),
    }
  }

  get informativeData(): any {
    return {
      // hasOLLN: this.hasOLLN,
      longName: this.getInformativeControl('longName').value,
      tokenRefUrl: this.getInformativeControl('tokenReferenceURL')?.value,
      publicDistLedgerIndicator: this.getInformativeControl('publicDistributorLedgerIndicator')?.value,
      ...(this.hasOLLN ? { origLangLongName: this.getInformativeControl('origLangLongName')?.value } : {})
    }
  }

  get nativeTokensData() {
    return this.nativeTokens.value.map(({ shortNames, hasOrigLangShortName, origlangShortNames, unitMultiplier, underlyingAssets, externalIdentifiers }: any) => {
      return {
        shortNames: shortNames.map((s: any) => s.shortName),
        ...(hasOrigLangShortName ? { originalShortNames: origlangShortNames.map((s: any) => s.origlangShortName) } : {}),
        attribute: { unitMultiplier },
        externalIdentifier: {
          underLyingAssets: underlyingAssets.map((s: any) => s),
          digitalTokens: externalIdentifiers.map((s: any) => s),
        }
      }
    });
  }

  get auxiliaryData() {
    return this.auxiliaries.value;
  }

  get selectedTabIndex(): number {
    return this.stepper.selectedIndex;
  }

  // Controls
  getNormativeControl(controlName: string): FormControl {
    return this.normativeFG.get(controlName) as FormControl;
  }

  getInformativeControl(controlName: string): FormControl {
    return this.informativeFG.get(controlName) as FormControl;
  }

  getNativeTokenControl(controlName: string) {
    return this.nativeTokenFG.get(controlName);
  }

  getAuxiliaryControl(controlName: string) {
    return this.auxiliaryFG.get(controlName);
  }

  getShortNames(nativeTokenIndex: number): FormArray {
    return <FormArray>this.nativeTokens.at(nativeTokenIndex).get('shortNames');
  }

  getOrigLangShortNames(index: number): FormArray {
    return <FormArray>this.nativeTokens.at(index).get('origlangShortNames');
  }

  getUnderlyingAssets(nativeTokenIndex: number): FormArray {
    return <FormArray>this.nativeTokens.at(nativeTokenIndex).get('underlyingAssets');
  }

  getExternalIdentifiers(nativeTokenIndex: number): FormArray {
    return <FormArray>this.nativeTokens.at(nativeTokenIndex).get('externalIdentifiers');
  }

  // Control Creations
  createAuxiliryControlGroup() {
    const validations = [Validators.required, Validators.pattern(VALIDATIONS.UTF_8_WITH_RESTRICT_SYMBOL)];
    return this.formBuilder.group({
      auxiliaryMechanism: new FormControl('', validations),
      auxiliaryMechanismDesc: ['', validations],
      auxiliaryMechanismTechRef: ['', validations],
      auxiliaryMechanismVerificationDetail: ['', validations]
    })
  }

  createNativeTokenControlGroup() {
    return this.formBuilder.group({
      shortNames: this.formBuilder.array([
        this.createShortNameControlGroup()
      ]),
      hasOrigLangShortName: [false],
      hasExternalIdentifier: [false],
      origlangShortNames: this.formBuilder.array([]),
      underlyingAssets: this.formBuilder.array([]),
      externalIdentifiers: this.formBuilder.array([]),
      unitMultiplier: ['', [Validators.required, Validators.pattern(VALIDATIONS.FLOATING_NUMBER)]]
    })
  }

  createShortNameControlGroup() {
    return this.formBuilder.group({
      shortName: ['', [Validators.pattern(VALIDATIONS.ALPHA_NUMERIC)]]
    })
  }

  createOrigLangShortNameControlGroup() {
    return this.formBuilder.group({
      origlangShortName: ['', [Validators.pattern(VALIDATIONS.ASCII)]]
    })
  }

  createExternalIdentifierControlGroup() {
    return this.formBuilder.group({
      type: [''],
      value: ['']
    })
  }

  // Events
  dltOnChange(): void {
    const gbha = this.normativeFG.get('gbha'),
      gbh = this.normativeFG.get('gbh'),
      gbUTCTime = this.normativeFG.get('gbUTCTime');

    gbha?.clearValidators();
    gbh?.clearValidators();
    gbUTCTime?.clearValidators();

    if (this.isDLTBlockchain) {
      gbha?.setValidators([Validators.required]);
      gbh?.setValidators([Validators.required, Validators.pattern(/^(?:[a-zA-Z0-9\s]+)?$/)]);
      gbUTCTime?.setValidators([Validators.required]);
    }
    gbha?.updateValueAndValidity();
    gbh?.updateValueAndValidity();
    gbUTCTime?.updateValueAndValidity();
  }

  onChangeOrigLangShortName({ checked }: MatCheckboxChange, nativeTokenIndex: number) {
    checked ? this.addOrigLangShortName(nativeTokenIndex) : this.getOrigLangShortNames(nativeTokenIndex).clear();
  }

  onChangeExternalIdentifiers({ checked }: MatCheckboxChange, nativeTokenIndex: number) {
    if (checked) {
      this.addUnderlyingAsset(nativeTokenIndex);
      this.addExternalIdentifier(nativeTokenIndex);
    } else {
      this.getUnderlyingAssets(nativeTokenIndex).clear();
      this.getExternalIdentifiers(nativeTokenIndex).clear();
    }
  }

  onSelectExternalIdentifiers(nativeTokenIndex: number, controlIndex: number, isUnderyingAssets = false) {
    const formArrayControl = isUnderyingAssets ? this.getUnderlyingAssets(nativeTokenIndex) : this.getExternalIdentifiers(nativeTokenIndex),
      valueControl = formArrayControl.at(controlIndex).get('value');
    valueControl?.setValidators([Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_SPACE)]);
    valueControl?.updateValueAndValidity();
  }

  onStepperChange({ selectedIndex }: any) {
    this.tabChange.emit(selectedIndex);
  }

  joinList(shortNames: any[], prop: string): string {
    return shortNames.map(s => s[prop]).join(`, `);
  }

  addForkTab(): void {
    this.tokenForks.push('Fork');
    this.tokenForkSelectedTab = this.tokenForks.length;
  }

  removeTokenForksTab(event: Event, index: number): void {
    event.stopPropagation();
    this.tokenForks.splice(index, 1);
    // this.tokenForkSelectedTab = this.tokenForks.length;
  }

  addNativeTokenTab(): void {
    this.nativeTokens.push(this.createNativeTokenControlGroup())
  }

  removeNativeTokenTab(event: Event, index: number): void {
    event.stopPropagation();
    this.nativeTokens.removeAt(index);
  }

  addAuxiliaryTab() {
    this.auxiliaries.push(
      this.createAuxiliryControlGroup()
    )
  }

  removeAuxiliaryTab(event: Event, index: number): void {
    event.stopPropagation();
    this.auxiliaries.removeAt(index)
  }

  addShortName(nativeTokenIndex: number): void {
    this.getShortNames(nativeTokenIndex).push(this.createShortNameControlGroup());
  }

  deleteShortName(nativeTokenIndex: number, shortNameIndex: number): void {
    this.getShortNames(nativeTokenIndex).removeAt(shortNameIndex);
  }

  deleteOrigLangShortName(nativeTokenIndex: number, origlangShortNameIndex: number) {
    this.getOrigLangShortNames(nativeTokenIndex).removeAt(origlangShortNameIndex);
  }


  addOrigLangShortName(nativeTokenIndex: number) {
    this.getOrigLangShortNames(nativeTokenIndex).push(this.createOrigLangShortNameControlGroup())
  }

  addUnderlyingAsset(nativeTokenIndex: number) {
    this.getUnderlyingAssets(nativeTokenIndex).push(this.createExternalIdentifierControlGroup())
  }

  deleteUnderlyingAsset(nativeTokenIndex: number, underlyingAssetIndex: number) {
    this.getUnderlyingAssets(nativeTokenIndex).removeAt(underlyingAssetIndex);
    // for the first row delete icon would reset only the selection and value
    if (!this.getUnderlyingAssets(nativeTokenIndex).length) {
      this.addUnderlyingAsset(nativeTokenIndex);
    }
  }

  addExternalIdentifier(nativeTokenIndex: number) {
    this.getExternalIdentifiers(nativeTokenIndex).push(this.createExternalIdentifierControlGroup());
  }

  deleteExternalIdentifier(nativeTokenIndex: number, externalIdentifierIndex: number) {
    this.getExternalIdentifiers(nativeTokenIndex).removeAt(externalIdentifierIndex);
    // for the first row delete icon would reset only the selection and value
    if (!this.getExternalIdentifiers(nativeTokenIndex).length) {
      this.addExternalIdentifier(nativeTokenIndex);
    }

  }

  nextStep() {
    this.stepper.next();
  }

  goBack() {
    this.stepper.previous();
  }
}

