import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'dti-link-to-glossary',
  templateUrl: './link-to-glossary.component.html',
  styleUrls: ['./link-to-glossary.component.scss']
})
export class LinkToGlossaryComponent {
  @Output() onClick = new EventEmitter();
}
