<div class="div col">
    <div class="row" *ngFor="let entry of values; let index = index">
        <div class="col">
            <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                <mat-label class="field-label-size">{{label}}{{index + 1}}</mat-label>
                <input matInput [(ngModel)]="entry.value" class="field-size" placeholder="{{placeholder}}" value="">
            </mat-form-field>
        </div>
        <i *ngIf="isDeleteVisible" (click)="removeField(index)" class="material-symbols-outlined material-icons-font material-icons">
            delete
        </i>
    </div>
    <i *ngIf="isPlusVisible" (click)="addField()" class="material-symbols-outlined material-icons-font material-icons">
        add
    </i>
</div>