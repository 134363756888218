<div class="title-container">
    <label for="" class="title primary-color size-8">
        Auxiliary Digital Token Registration Request
    </label>
</div>
<div class="form-container">
    <dti-loading *ngIf="processing" [text]="loadingText"></dti-loading>
    <dti-initiator-tab *ngIf="atInitiatorTab; else stepper" formType="AUX" [controls]="initiatorFormGroup" #initiator></dti-initiator-tab>
    <ng-template #stepper>
        <div class="flex-column" #stepperContainer>
            <mat-horizontal-stepper color="accent" labelPosition="bottom" [linear]="creation" #steps>
                <mat-step [stepControl]="normativeFormGroup" [completed]="normativeFormGroup.valid" [editable]="creation || editing">
                    <ng-template matStepLabel>
                        <p>
                            Normative Attributes
                        </p>
                    </ng-template>
                    <ng-template matStepperIcon="edit">
                        <i class="material-symbols-outlined material-icons-font material-icons">
                            check
                        </i>
                    </ng-template>
                    <form [formGroup]="normativeFormGroup">
                        <div class="tab-container flex-column normative-tab">
                            <div class="row field-row">
                                <p class="container-title col">Please enter the following details for the Auxiliary Digital Token</p>
                            </div>
                            <div class="row field-row">
                                <div class="col">
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                        <mat-label class="field-label-size" color="primary">Distributed Ledger Technology</mat-label>
                                        <mat-select class="field-size" placeholder="Select" formControlName="auxiliaryDlt" color="primary"
                                            (selectionChange)="normativeFormGroup.get('auxiliaryMechanism')?.reset()">
                                            <mat-option [ngClass]="'field-size mat-select-option'" *ngFor="let auxiliary of auxiliaryDistributedLedgers"
                                                value="{{auxiliary.tokenIdentifier}}">{{joinDistributedLedger(auxiliary)}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="normativeFormGroup.get('auxiliaryDlt')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col text-center">
                                    <span class="provisional-label">Provisional</span>
                                    <mat-slide-toggle formControlName="isProvisional" disableRipple="true" class="provisional field-size"></mat-slide-toggle>
                                </div>
                            </div>
                            <hr class="divider">
                            <div class="row field-row">
                                <div class="col">
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                        <mat-label class="field-label-size" color="primary">Token Mechanism</mat-label>
                                        <mat-select class="field-size" placeholder="Select" formControlName="auxiliaryMechanism" color="primary">
                                            <mat-option [ngClass]="'field-size mat-select-option'" *ngFor="let auxiliary of auxiliaryDigitalMechanisms" [value]="auxiliary">{{auxiliary}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="normativeFormGroup.get('auxiliaryMechanism')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col"></div>
                            </div>
                            <div class="row field-row">
                                <div class="col">
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                        <mat-label class="field-label-size">Technical Reference</mat-label>
                                        <input matInput formControlName="auxiliaryMechanismTechRef" class="field-size" placeholder="Technical Reference" maxlength="200">
                                        <mat-error *ngIf="normativeFormGroup.get('auxiliaryMechanismTechRef')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                                        <mat-error
                                            *ngIf="!normativeFormGroup.get('auxiliaryMechanismTechRef')?.hasError('required') && normativeFormGroup.get('auxiliaryMechanismTechRef')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_VALID_SYMBOLS}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col"></div>
                            </div>
                        </div>
                    </form>
                </mat-step>

                <mat-step [stepControl]="informativeFormGroup" [completed]="informativeFormGroup.valid" [editable]="creation || editing">
                    <ng-template matStepLabel>
                        <p>
                            Informative Attributes
                        </p>
                    </ng-template>
                    <div class="tab-container flex-column informative-tab">
                        <div class="row">
                            <div class="col">
                                <mat-checkbox [(ngModel)]="hasOrgLongOrOrgShort" color="primary" class="cb-items">
                                    Does the token have a long or a short name in the original language that is different from the long short names already specified?
                                </mat-checkbox>
                            </div>
                        </div>
                        <form [formGroup]="informativeFormGroup">
                            <h4 class="section-title pt-1-50">Long Names</h4>
                            <hr class="divider">
                            <div class="row pt-50">
                                <div class="col long-name">
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                        <mat-label class="field-label-size">Long Name</mat-label>
                                        <input class="field-size" formControlName="longName" matInput placeholder="Long Name" maxlength="255">
                                        <mat-error *ngIf="informativeFormGroup.get('longName')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                                        <mat-error
                                            *ngIf="!informativeFormGroup.get('longName')?.hasError('required') && informativeFormGroup.get('longName')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_SPACE}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col original-long-name">
                                    <mat-form-field *ngIf="hasOrgLongOrOrgShort" color="primary" appearance="outline" floatLabel="auto">
                                        <mat-label class="field-label-size">Original Language Long Name</mat-label>
                                        <input class="field-size" formControlName="originalLongName" matInput placeholder="Original Language Long Name" maxlength="255">
                                        <mat-error *ngIf="informativeFormGroup.get('originalLongName')?.errors">{{VALIDATIONS_MESSAGE.UTF_8_WITH_RESTRICT_SYMBOL_MESSAGE}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <h4 class="section-title pt-1">Short Names</h4>
                            <hr class="divider">
                            <div class="row border p shortnames-container">
                                <div class="col p-0" formArrayName="shortNames">
                                    <div class="row" *ngFor="let shortName of controlShortNames.controls; let index = index">
                                        <div class="col field-col">
                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                                <mat-label class="field-label-size">Short Name</mat-label>
                                                <input matInput class="field-size" formControlName="{{index}}" placeholder="Short Name" maxlength="30">
                                                <mat-error>{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_SPACE}}</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <button class="delete-button" mat-icon-button color="accent" (click)="controlShortNames.removeAt(index)" *ngIf="controlShortNames.controls.length > 1">
                                                <mat-icon class="delete-icon material-symbols-outlined material-icons-font material-icons">delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <button mat-icon-button color="accent" (click)="addShortName()" *ngIf="controlShortNames.controls.length < 5">
                                        <mat-icon class="material-symbols-outlined material-icons-font material-icons">add</mat-icon>
                                    </button>
                                </div>
                                <div class="col p-0" formArrayName="originalShortNames">
                                    <div class="row" *ngFor="let origShortName of controlOriginalShortNames.controls let index = index">
                                        <div class="col field-col">
                                            <mat-form-field color="primary" *ngIf="hasOrgLongOrOrgShort" appearance="outline" floatLabel="auto">
                                                <mat-label class="field-label-size">Original Language Short Name</mat-label>
                                                <input matInput class="field-size" formControlName="{{index}}" placeholder="Short Name" maxlength="30">
                                                <mat-error>{{VALIDATIONS_MESSAGE.UTF_8_WITH_RESTRICT_SYMBOL_MESSAGE}}</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <button class="delete-button" mat-icon-button color="accent" (click)="controlOriginalShortNames.removeAt(index)"
                                                *ngIf="controlOriginalShortNames.controls.length > 1 && hasOrgLongOrOrgShort">
                                                <mat-icon class="delete-icon material-symbols-outlined material-icons-font material-icons">delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <button mat-icon-button color="accent" (click)="addOrigLangShortName()" *ngIf="controlOriginalShortNames.controls.length < 5 && hasOrgLongOrOrgShort">
                                        <mat-icon class="material-symbols-outlined material-icons-font material-icons">add</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <h4 class="section-title pt-1-50">Attributes</h4>
                            <hr class="divider">
                            <div class="row pt-50" formGroupName="attribute">
                                <div class="col">
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                        <mat-label class="field-label-size">Unit Multiplier</mat-label>
                                        <input matInput class="field-size" formControlName="unitMultiplier" placeholder="Unit Multiplier" maxlength="30">
                                        <mat-error *ngIf="informativeFormGroup.get('attribute')?.get('unitMultiplier')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                                        <mat-error
                                            *ngIf="!informativeFormGroup.get('attribute')?.get('unitMultiplier')?.hasError('required') && informativeFormGroup.get('attribute')?.get('unitMultiplier')?.errors">{{VALIDATIONS_MESSAGE.FLOATING_NUMBER}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <h4 class="section-title pt-1">External Identifiers</h4>
                            <hr class="divider">
                            <div class="row">
                                <div class="col">
                                    <mat-checkbox [checked]="hasExternalIdentifier" color="primary" class="cb-items" #hasExternal>
                                        Does the token have any identifiers, external to this standard, that may be used to identify either the digital token or the underlying asset that this digital
                                        token represents?
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="row pt-1" formGroupName="externalIdentifier" *ngIf="hasExternal.checked">
                                <div class="col border p underlying-assets" formArrayName="underLyingAssets">
                                    <div class="row" *ngFor="let underLyingAsset of controlUnderLyingAssets.controls; let index = index;" [formGroupName]="index">
                                        <!-- {{testValue(underLyingAsset)}} -->
                                        <div class="col">
                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                                <mat-label class="field-label-size">Underlying Asset External Identifiers Type</mat-label>
                                                <mat-select (selectionChange)="onSelectExternalIdentifiers(index)" class="field-size" formControlName="type" placeholder="Select">
                                                    <mat-option [ngClass]="'field-size mat-select-option'" *ngFor="let option of underlyingAssetExternalIdentifierTypesOptions"
                                                        [value]="option.value">{{option.text}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                                <mat-label class="field-label-size">Underlying Asset External Identifiers Value</mat-label>
                                                <input matInput class="field-size" formControlName="value" placeholder="Underlying Asset External Identifiers Value" maxlength="48" />
                                                <mat-error *ngIf="underLyingAsset.get('value')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                                                <mat-error
                                                    *ngIf="!underLyingAsset.get('value')?.hasError('required') && underLyingAsset.get('value')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_SPACE}}</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col text-center flex-grow-0">
                                            <button mat-icon-button color="accent" class="ext-delete-button" (click)="deleteExternalIdentifiers(index)">
                                                <mat-icon class="material-symbols-outlined material-icons-font material-icons">delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="controlUnderLyingAssets.controls.length < 5">
                                        <div class="col text-center">
                                            <button mat-icon-button color="accent" (click)="addUnderLyingAsset()">
                                                <mat-icon class="material-symbols-outlined material-icons-font material-icons">add</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-2" formGroupName="externalIdentifier" *ngIf="hasExternal.checked">
                                <div class="col border p underlying-assets" formArrayName="digitalTokens">
                                    <div class="row" *ngFor="let digitalToken of controlDigitalTokens.controls; let index = index;" [formGroupName]="index">
                                        <div class="col">
                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                                <mat-label class="field-label-size">Digital Token External Identifiers Type</mat-label>
                                                <mat-select (selectionChange)="onSelectExternalIdentifiers(index, false)" class="field-size" formControlName="type" placeholder="Select">
                                                    <mat-option [ngClass]="'field-size mat-select-option'" *ngFor="let option of digitalTokenExternalIdentifiersTypesOptions"
                                                        [value]="option.value">{{option.text}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                                <mat-label class="field-label-size">Digital Token External Identifiers Value</mat-label>
                                                <input matInput class="field-size" formControlName="value" placeholder="Underlying Asset External Identifiers Value" maxlength="48" />
                                                <mat-error *ngIf="digitalToken.get('value')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                                                <mat-error
                                                    *ngIf="!digitalToken.get('value')?.hasError('required') && digitalToken.get('value')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_SPACE}}</mat-error>

                                            </mat-form-field>
                                        </div>
                                        <div class="col text-center flex-grow-0">
                                            <button mat-icon-button color="accent" class="ext-delete-button" (click)="deleteExternalIdentifiers(index, false)">
                                                <mat-icon class="material-symbols-outlined material-icons-font material-icons">delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="controlDigitalTokens.controls.length < 5">
                                        <div class="col text-center">
                                            <button mat-icon-button color="accent" (click)="addDigitalToken()">
                                                <mat-icon class="material-symbols-outlined material-icons-font material-icons">add</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </mat-step>

                <mat-step [editable]="creation || editing">
                    <ng-template matStepLabel>
                        <p>
                            Review & Submit
                        </p>
                    </ng-template>
                    <div *ngIf="!successfull" class="review-container flex-column container">
                        <dti-summary (onEdit)="onTabChange($event)" [details]="summaryDetails" [changesMade]="changesMade"></dti-summary>

                        <div class="row section pt-2 bottom-section">
                            <div class="col">
                                <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="text-area">
                                    <mat-label class="field-label-size">Additional Information</mat-label>
                                    <textarea matInput [(ngModel)]="additionalInfo" class="field-size" placeholder="Additional Information" cdkTextareaAutosize cdkAutosizeMinRows="5"  maxlength="400"></textarea>
                                    <mat-hint align="end" class="text-counter">{{additionalInfo ? additionalInfo.length : 0}}/400</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <dti-success-submission *ngIf="successfull" [applicationId]="applicationId" [tokenShortName]="(informativeData).shortNames.join(', ')" [tokenLongName]="(informativeData).longName"
                        [mechanism]="auxiliaryDigitalMechanism" [title]="successTitle"></dti-success-submission>
                </mat-step>

            </mat-horizontal-stepper>
        </div>
    </ng-template>
    <dti-previous-next [hasContinue]="!successfull" [hasPrevious]="hasGotoPrevious" [hasReview]="hasGotoReview" [previousText]="previousText" [continueText]="continueText"
     (previousClick)="onClickPrevious()" (continueClick)="onClickContinue()" (reviewClick)="onClickContinue(false)"></dti-previous-next>
    <dti-tab-glossary [formType]="formType" [show]="showGlossary" [tabIndex]="currentTabIndex" (close)="showGlossary = false" />
</div>
<dti-right-side-menus [show]="continueText!='Submit' && !processing && !successfull && !showGlossary" (onClick)="showGlossary=true"></dti-right-side-menus>