import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuxiliaryService {

  private baseURL = environment.apiBaseURL;
  private url = `${this.baseURL}/v1/api/request/token`;
  private _jiraKey = ''

  constructor(private http: HttpClient) { }

  set applicationId(id: string) {
    this._jiraKey = id;
  }
  get applicationId(): string {
    return `${this._jiraKey}`;
  }

  submit(auxiliaryData: Auxiliary) {
    return this.http.post<Auxiliary>(this.url, auxiliaryData)
  }

  getRequestDetail(id: string) {
    return this.http.get<any>(`${this.url}/${id}`)
  }

  updateRequestDetail(auxiliaryData: Auxiliary) {
    return this.http.put<Auxiliary>(`${this.url}/${this.applicationId}`, auxiliaryData)
  }

}

export interface Auxiliary {
  isCreator: boolean,
  hasLei?: boolean,
  lei?: string,
  companyName?: string,
  businessIdentifierCode?: string,
  normative: Normative,
  informative: Informative,
  metaData: MetaData,
  additionalInfo: string
}

interface Normative {
  isProvisional: boolean,
  activationDate?: string
  auxiliaryDlt: string,
  auxiliaryMechanism: string,
  auxiliaryMechanismTechRef: string,
}

interface Informative {
  longName: string,
  originalLongName?: string,
  shortNames: string[],
  originalShortNames?: string[],
  attribute: {
    tokenRefUrl?: string,
    unitMultiplier: string,
    publicDistLedgerIndicator?: string,
  },
  externalIdentifier: {
    underLyingAssets?: [
      { type: string, value: string }
    ],
    digitalTokens?: [
      { type: string, value: string }
    ]
  }
}

interface MetaData {
  email: string,
  timeStamp: string
}
