<div class="header">
    <div class="header-content">
        <img width="25" height="25" class="icon" src="../../assets/img/help_icon.svg" alt="">
        <span class="title">Need help?</span>
    </div>
    <i class="close-icon material-icons-font material-icons" (click)="close.emit()"> close </i>
</div>
<div class="content">
    <div class="glossary" *ngFor="let glossary of getGlossaries(_tabIndex+1)">
        <p class="title">
            {{glossary.field}}
        </p>
        <p class="description">
            {{glossary.description}}
        </p>
    </div>
</div>
<a class="link" href="https://dtif.org/glossary-of-terms/" target="_blank">
    <span class="text">Learn more</span>
    <i class="forward-icon material-icons-font material-icons primary-color">arrow_forward</i>
</a>