<div class="review-container flex-column container">
    <div class="row header-title">
        <div class="col">
            <h4 class="long-name">{{details.title}}</h4><br>
        </div>
        <div class="col token-type-and-ledger">
            <label *ngIf="details?.subTitle?.length"><b>{{details.subTitle}}</b></label><label class=""> |
                {{details.miniSubTitle}}</label>
        </div>
    </div>
    <div class="row pt-2" *ngIf="changesMade.length">
        <div class="col changes-made-container">
            <div class="row">
                <div class="col">
                    <label class="title">Changes Made:</label>
                </div>
            </div>
            <div class="row pt-1" *ngFor="let change of changesMade">
                <div class="col" *ngIf="!change.hasMultipleGroup; else mutilpleGroup">
                    <span class="section-title">{{change.title}}</span>
                    <span *ngFor="let field of change.fields">{{field.label}} : <b>{{field.value}}</b></span>
                </div>
                <ng-template #mutilpleGroup>
                    <div class="col">
                        <span class="section-title">{{change.title}}</span>
                        <div class="row pt-50" *ngFor="let tab of change.fields">
                            <div class="col">
                                <span class="section-title">{{tab.title}}</span>
                                <span *ngFor="let field of tab.groups">{{field.label}} : <b>{{field.value}}</b></span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="row section pt-2" *ngFor="let section of details.sections; let index=index">
        <div class="col">
            <div class="row section-header">
                <div class="col">
                    <label class="section-title">{{section.title}}</label>
                </div>
                <div class="col text-right" *ngIf="details.hasEditButton">
                    <span class="button-edit" (click)="onEditSection(index)">Edit</span>
                </div>
            </div>
            <hr class="section-header-divider">
            <div *ngIf="!section.hasMultiple; else multiple">
                <p *ngFor="let item of section.items" class="row">
                    <span *ngFor="let field of item.fields" class="col {{field.cssClass ? field.cssClass :''}}"
                        [innerHTML]="mapField(field)"></span>
                </p>
            </div>
            <ng-template #multiple>
                <fieldset *ngFor="let subSection of section.subSections; let index = index">
                    <legend class="sub-section-title">{{subSection.title}} {{index+1}}</legend>
                    <p *ngFor="let item of subSection.items" class="row">
                        <span *ngFor="let field of item.fields" class="col {{field.cssClass ? field.cssClass :''}}"
                            [innerHTML]="mapField(field)"></span>
                    </p>
                </fieldset>
            </ng-template>
        </div>
    </div>
</div>