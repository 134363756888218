import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IDialogData {
  title?: string
  message?: string
  okButton?: string
}

@Component({
  selector: 'dti-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {

  title = '';
  message = '';
  okButton = '';
  mailText = '';

  constructor(@Inject(MAT_DIALOG_DATA) data: IDialogData) {
    this.title = data?.title || 'Error!';
    this.message = data?.message || 'Error encountered! Please contact ';
    this.okButton = data?.okButton || 'Ok';
    this.mailText = "mailto:support@dtif.org";
  }
}
