import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormType } from 'src/app/shared/models/enums';

@Component({
  selector: 'dti-tab-glossary',
  templateUrl: './tab-glossary.component.html',
  styleUrls: ['./tab-glossary.component.scss']
})
export class TabGlossaryComponent {

  _tabIndex = -1;

  @Input() formType = FormType.DLT;
  @Input() show = false;
  @Input() set tabIndex(index: number) {
    this._tabIndex = index;
  }

  @Output() close = new EventEmitter();

  @HostBinding('style.top') get top() { return this._tabIndex == -1 ? '0' : '155px'; }
  @HostBinding('class') get visible() { return this.show ? 'show' : 'hide'; }

  glossary: any = {
    [FormType.DLT]: [
      [
        {
          field: 'Are you the Creator/Initiator/Maintainer of the DLT?',
          description: 'Tick if you are (or act as a representative of the entity) who either create, initiated or maintain the ledger.'
        },
        {
          field: 'Do you have Legal Entity Identifier (LEI)?',
          description: 'Legal Entity Identifier Definition: A Legal Entity Identifier (LEI), is a code that is unique to a legal entity such as a Limited Company, Fund or trust or any organization. The LEI code consists of a combination of 20 letters and numbers.'
        },
        {
          field: 'Company Name',
          description: 'Name of the company as represented in alphanumeric basic Latin characters'
        },
        {
          field: 'Business Identifier Code',
          description: 'The BIC is an 8 character code, defined as ‘business party identifier’, consisting of the business party prefix (4 alphanumeric), the country code as defined in ISO 3166-1 (2 alphabetic), and the business party suffix (2 alphanumeric).'
        },
        {
          field: 'Does the DLT support the following?',
          description: `
          Native Token(s) – digital token(s) with a privileged position in DLT
          Auxiliary Token – Ability to create Auxiliary Digital Tokens – non-native digital token created as application on DLT`
        }
      ],

      [
        {
          field: 'Distributed Ledger Technology Type',
          description: 'For example, Bitcoin and Ethereum use blockchain-based distributed ledgers. IOTA utilizes a directed acyclic graph for its distributed ledger, which is not a blockchain, and thus would be considered distributed ledger technology: Other.'
        },
        {
          field: 'Genesis Block Hash Algorithm',
          description: `Algorithm used to produce the genesis block hash e.g. SHA-256`
        },
        {
          field: 'Genesis Block Hash',
          description: `Block hash of the first block (Block 0) on the chain`
        },
        {
          field: 'Genesis Block UTC Timestamp',
          description: `Date and time of the generation of the first block (Block 0)`
        }
      ],

      [
        {
          field: 'Long Name',
          description: 'String containing the full name of the digital token, as represented in alphanumeric basic Latin characters.'
        },
        {
          field: 'Original Language Long Name',
          description: 'Long name of the token in the original language if it is different from the long name already specified.'
        },
        {
          field: 'Implementation URL (link to GitHub)',
          description: 'Implementation URL (link to GitHub)'
        },
        {
          field: 'Public Distributed Ledger indicator (public/private)',
          description: 'Indicator whether the ledger is private or public.'
        }
      ],


      [
        {
          field: 'Short Name',
          description: 'One or more short names or ticker symbols used to represent this digital token.'
        },
        {
          field: 'Original Language Short Name',
          description: 'Short name of the token in the original language if it is different from the short name already specified.'
        },
        {
          field: 'Unit Multiplier',
          description: `Multiplier used to map from the unit of value stored on the distributed ledger to the
          unit of value associated with the digital token long name. For example unit
          multiplier for Bitcoin is 8 or 100000000.`
        },
        {
          field: 'External Identifiers',
          description: `Specify any identifiers, external to this standard, that may be used to identify either the digital token or the underlying asset that this digital token represents. Additional fields will appear to add either underlying asset or a digital token.
          Accepted values: CUSIP, SEDOL, QUIK, ISIN, RIC
          (Based on SecurityIDSource – Fix Tag 22)`
        },
      ],

      [
        {
          field: 'Auxiliary Mechanism Name (e.g., ERC-20)',
          description: 'Protocol used to create an auxiliary digital token. If relevant protocol is not listed, please contact secretariat@dtif.org'
        },
        {
          field: 'Auxiliary Mechanism Description',
          description: 'Description of the protocol used to create an auxiliary digital token (e.g., the ERC-20 (Ethereum Request for Comments 20), is a token standard that implements an API for tokens within Smart Contracts)'
        },
        {
          field: 'Auxiliary Digital Token Technical Reference Overview',
          description: 'Description of an element, such as a smart contract address, used to uniquely identify an auxiliary digital token’s origin on a distributed ledger technology platform.'
        },
        {
          field: 'Auxiliary Digital Token Verification Details',
          description: 'Explanation on how digital token can be viewed and verified on the DLT (e.g., a link to DLT explorer(s) if available)'
        },
      ]
    ],
    [FormType.AUX]: [
      [
        {
          field: 'Are you the Creator/Initiator/Maintainer of the Token?',
          description: 'Tick if you are (or act as a representative of the entity) who either create, initiated or maintain the ledger.'
        },
        {
          field: 'Do you have Legal Entity Identifier (LEI)?',
          description: 'Legal Entity Identifier Definition: A Legal Entity Identifier (LEI), is a code that is unique to a legal entity such as a Limited Company, Fund or trust or any organization. The LEI code consists of a combination of 20 letters and numbers.'
        },
        {
          field: 'Company Name',
          description: 'Name of the company as represented in alphanumeric basic Latin characters'
        },
        {
          field: 'Business Identifier Code',
          description: 'The BIC is an 8 character code, defined as ‘business party identifier’, consisting of the business party prefix (4 alphanumeric), the country code as defined in ISO 3166-1 (2 alphabetic), and the business party suffix (2 alphanumeric).'
        },
      ],

      [
        {
          field: 'Distributed Ledger Technology',
          description: 'The DTI of a distributed ledger on which the digital token is operating. If relevant distribution ledger is not listed, please contact support@DTIF.org.'
        },
        {
          field: 'Token Mechanism',
          description: 'Protocol used to create an auxiliary digital token. If relevant protocol is not listed, please contact support@DTIF.org.'
        },
        {
          field: 'Technical Reference',
          description: 'Element, such as a smart contract address, used to uniquely identify an auxiliary digital token’s origin on a distributed ledger technology platform'
        },
      ],

      [
        {
          field: 'Long Name',
          description: 'String containing the full name of the digital token, as represented in alphanumeric basic Latin characters.'
        },
        {
          field: 'Original Language Long Name',
          description: 'Long name of the token in the original language if it is different from the long name already specified.'
        },
        {
          field: 'Short Name',
          description: 'One or more short names or ticker symbols used to represent this digital token.'
        },
        {
          field: 'Original Language Short Name',
          description: 'Short name of the token in the original language if it is different from the short name already specified.'
        },
        {
          field: 'Unit Multiplier',
          description: 'Multiplier used to map from the unit of value stored on the distributed ledger to the unit of value associated with the digital token long name. For example unit multiplier for Bitcoin is 8 or 100000000.'
        },
        {
          field: 'External Identifiers',
          description: `
          Specify any identifiers, external to this standard, that may be used to identify either the digital token or the underlying asset that this digital token represents.
          Additional fields will appear to add either underlying asset or a digital token. 
          Accepted values: CUSIP, SEDOL, QUIK, ISIN, RIC(Based on SecurityIDSource – Fix Tag 22)
          `
        }
      ]
    ]
  }



  getGlossaries(tabIndex: number) {
    return this.glossary[this.formType][tabIndex];
  }



}
