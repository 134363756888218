import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/data/user.service';
import { AuxiliaryService } from 'src/app/services/auxiliary.service';
import { VALIDATIONS, VALIDATIONS_MESSAGE } from 'src/app/shared/util/validations';
import { FormType, RequestState } from 'src/app/shared/models/enums';
import { IFormState, Summary } from 'src/app/shared/models/interface';
import { DifferenceOfObjectList, DifferenceOfStringList, JoinObjectList } from 'src/app/shared/util/helper';
import { InitiatorTabComponent } from 'src/app/components/initiator-tab/initiator-tab.component';
import { IjeService } from 'src/app/services/ije.service';
import { LINKS } from 'src/app/shared/constant/defaults';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionDialogComponent } from 'src/app/components/terms-and-condition-dialog/terms-and-condition-dialog.component';
import { CookieService } from 'ngx-cookie-service';



@Component({
  selector: 'app-auxiliary-token',
  templateUrl: './auxiliary-token.component.html',
  styleUrls: ['./auxiliary-token.component.scss']
})

export class AuxiliaryTokenComponent implements AfterViewInit, IFormState {

  VALIDATIONS_MESSAGE = VALIDATIONS_MESSAGE;
  REGISTRATION_TERMS_AND_CONDITION = LINKS.REGISTRATION_TERMS_AND_CONDITION;

  @ViewChild('steps') private stepper!: MatStepper;
  @ViewChild('stepperContainer', { static: false }) private elSummary!: ElementRef;
  @ViewChild('initiator') private initiatorComp!: InitiatorTabComponent

  // https://stackoverflow.com/questions/42142053/candeactivate-confirm-message/42207299#42207299
  // https://stackoverflow.com/questions/4617583/customize-onbeforeunload-dialog-box
  @HostListener('window:beforeunload') beforeUnload(event: BeforeUnloadEvent): void {
    if (this.isTouched() && this.cookieService.get('access_token')) {
      event.preventDefault();
    }
  }

  requestState = RequestState.Creation;
  additionalInfo = ''
  auxiliaryDistributedLedgers: any;
  selectedTabIndex = -1;

  successTitle!: string;
  loadingText!: string;

  // Need To Globalize This Part cause it same with DLT
  underlyingAssetExternalIdentifierTypesOptions = [
    { value: 'CUSIP', text: 'CUSIP' },
    { value: 'ISIN', text: 'ISIN' },
    { value: 'QUIK', text: 'QUIK' },
    { value: 'RIC', text: 'RIC' },
    { value: 'SEDOL', text: 'SEDOL' }
  ]

  // Need To Globalize This Part cause it same with DLT
  digitalTokenExternalIdentifiersTypesOptions = [
    { value: 'FIGI', text: 'FIGI' },
    { value: 'ITIN', text: 'ITIN' }
  ]


  // Initiator Tab
  initiatorFormGroup = this.formBuilder.group({
    isCreator: [false],
    hasLei: [true],
    lei: [''],
    companyName: [''],
    businessIdentifierCode: [''],
    customerName: ['', [Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_WITH_SINGLE_SPECIAL_CHARACTER)]],
    financeContract: ['', [Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_WITH_SINGLE_SPECIAL_CHARACTER)]],
    emailAddress: ['', [Validators.required, Validators.pattern(VALIDATIONS.EMAIL)]],
    tellNo: ['', [Validators.required, Validators.pattern(VALIDATIONS.TELEPHONE_OR_VAT_NUMBER)]],
    invoiceAddress: ['', [Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_WITH_SINGLE_SPECIAL_CHARACTER)]],
    vatNumber: ['', [Validators.pattern(VALIDATIONS.TELEPHONE_OR_VAT_NUMBER)]]
  });

  // Normative Tab
  normativeFormGroup = this.formBuilder.group({
    isProvisional: [{ value: false, disabled: true }],
    activationDate: [new Date()],
    auxiliaryDlt: ['', Validators.required],
    auxiliaryMechanism: ['', Validators.required],
    auxiliaryMechanismTechRef: ['', [Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_VALID_SYMBOLS)]],
  })

  // Informative Tab 
  informativeFormGroup = this.formBuilder.group({
    longName: ['', [Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_SPACE)]],
    originalLongName: ['', [Validators.pattern(VALIDATIONS.UTF_8_WITH_RESTRICT_SYMBOL)]],
    shortNames: this.formBuilder.array([
    ]),
    originalShortNames: this.formBuilder.array([
      // this.formBuilder.control('', [Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_SPACE)]),
    ]),
    attribute: this.formBuilder.group({
      unitMultiplier: ['', [Validators.required, Validators.pattern(VALIDATIONS.FLOATING_NUMBER)]],
    }),
    externalIdentifier: this.formBuilder.group({
      underLyingAssets: this.formBuilder.array([
      ]),
      digitalTokens: this.formBuilder.array([
      ])
    })
  });


  // Conditional Controls
  hasOrgLongOrOrgShort = false;
  hasExternalIdentifier = false;

  // this will hold the value of token to be update.
  // need to hold the details(values) to be always available if needed. 
  auxiliaryTokenToUpdate: any = {};

  formType = FormType.AUX;
  showGlossary = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute,
    private auxiliaryService: AuxiliaryService, private userService: UserService, private ijeService: IjeService,
    private cdr: ChangeDetectorRef, private dialog: MatDialog, private cookieService: CookieService) {

    this.ijeService.getAuxiliaryLedgerMechanisms().then((data) => {
      this.auxiliaryDistributedLedgers = data;
    });

    this.activatedRoute.params.subscribe(({ id }) => {
      if (!id) {
        // new token submission
        this.addShortName();
        this.addOrigLangShortName();
        this.addUnderLyingAsset();
        this.addDigitalToken();
      }
      else {
        this.auxiliaryService.applicationId = id;
        this.requestState = RequestState.Processing;
        this.loadingText = '';
        this.auxiliaryService.getRequestDetail(id).subscribe(({ data }) => {
          this.requestState = RequestState.Editing;
          this.auxiliaryTokenToUpdate = data;
          this.hasOrgLongOrOrgShort = true;
          // this.externalIdentifierState = true;
          this.selectedTabIndex = +this.activatedRoute.snapshot.queryParams['tab'];
          this.setFieldsValue(data);
          if (this.selectedTabIndex > -1) {
            setTimeout(() => {
              this.stepper.selectedIndex = this.selectedTabIndex;
            });
          }
        });
        //TODO: this part will the request token details
      }
    })
  }

  ngAfterViewInit(): void {
    if (this.selectedTabIndex > -1) {
      this.stepper.selectedIndex = this.selectedTabIndex;
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  get atInitiatorTab() {
    return this.selectedTabIndex == -1;
  }

  get atStepperTab() {
    return this.selectedTabIndex == 0;
  }

  get previousText() {
    return this.selectedTabIndex == -1 ? `Back to Token Selection` : `Previous`;
  }

  get continueText() {
    return this.stepper && this.stepper.selectedIndex == 2 ? `Submit` : `Continue`;
  }

  get currentTabIndex(): number {
    return this.stepper ? this.stepper.selectedIndex : -1;
  }

  get hasGotoPrevious(): boolean {
    return this.hasGotoReview ? this.selectedTabIndex > -1 : !this.successfull
  }

  get hasGotoReview(): boolean {
    return !!Object.keys(this.auxiliaryTokenToUpdate).length && this.continueText === 'Continue';
  }

  get initiatorData() {
    return this.initiatorFormGroup.value;
  }

  get billingInfo() {
    const { customerName, financeContract, emailAddress, tellNo, invoiceAddress, vatNumber }: any = this.initiatorFormGroup.value;
    return { customerName, financeContract, emailAddress, tellNo, invoiceAddress, vatNumber }
  }

  get normativeData() {
    return { ...this.normativeFormGroup.value, ...{ isProvisional: this.normativeFormGroup.get('isProvisional')?.value } };
  }

  get informativeData(): any {
    return this.informativeFormGroup.value;
  }

  get auxiliaryDigitalMechanisms() {
    const { auxiliaryDlt } = this.normativeData;
    if (auxiliaryDlt) {
      return this.auxiliaryDistributedLedgers.find((s: any) => s.tokenIdentifier == auxiliaryDlt).mechanisms;
    }
    return [];
  }

  get auxiliaryDigitalMechanism(): string {
    const { auxiliaryDlt } = this.normativeData;
    if (auxiliaryDlt) {
      const { longName, shortName } = this.auxiliaryDistributedLedgers.find((s: any) => s.tokenIdentifier == auxiliaryDlt);
      return `${longName} (${shortName})`;
    }
    return '';
  }

  get selectedAuxiliary() {
    const { auxiliaryDlt } = this.normativeData;
    if (auxiliaryDlt) {
      const auxiliary = this.auxiliaryDistributedLedgers.find((s: any) => s.tokenIdentifier == auxiliaryDlt);
      return this.joinDistributedLedger(auxiliary);
    }
    return '';
  }

  get controlShortNames(): FormArray {
    return this.informativeFormGroup.get('shortNames') as FormArray;
  }

  get controlOriginalShortNames(): FormArray {
    return this.informativeFormGroup.get('originalShortNames') as FormArray;
  }

  get controlUnderLyingAssets(): FormArray {
    return this.informativeFormGroup.get('externalIdentifier')?.get('underLyingAssets') as FormArray;
  }

  get controlDigitalTokens(): FormArray {
    return this.informativeFormGroup.get('externalIdentifier')?.get('digitalTokens') as FormArray;
  }

  get summaryDetails(): Summary {

    const { isCreator, lei, hasLei, companyName, businessIdentifierCode } = this.initiatorData,
      issuerItems = [{
        fields: [{ label: 'Are you the Creator/Initiator/Maintainer of the DLT?', value: isCreator ? `Yes` : `No` }]
      }];
    // initiator validation
    if (isCreator) {
      if (hasLei) {
        issuerItems.push({ fields: [{ label: 'Legal Entity Identifier (LEI)', value: <string>lei, }] })
      } else {
        issuerItems.push(
          { fields: [{ label: 'Company', value: <string>companyName, }] },
          { fields: [{ label: 'Business Identifier Code', value: <string>businessIdentifierCode, }] }
        )
      }
    }

    const biilingItems = [
      { fields: [{ label: 'Customer Name', value: this.initiatorData.customerName }] },
      { fields: [{ label: 'Finance Contact', value: this.initiatorData.financeContract }] },
      { fields: [{ label: 'Email Address', value: this.initiatorData.emailAddress }] },
      { fields: [{ label: 'Tel No', value: this.initiatorData.tellNo }] },
      { fields: [{ label: 'Invoice Address', value: this.initiatorData.invoiceAddress }] },
      { fields: [{ label: 'VAT Number', value: this.initiatorData.vatNumber }] },
    ]

    const normativeItems: any = [
      { fields: [{ label: 'Auxiliary Digital Distributed Ledger', value: this.normativeData.auxiliaryDlt, }] },
      { fields: [{ label: 'Provisional', value: this.normativeData.isProvisional ? `Yes` : `No` }] },
      { fields: [{ label: 'Auxiliary Digital Token Mechanism', value: this.normativeData.auxiliaryMechanism, }] },
      { fields: [{ label: 'Auxiliary Digital Token Technical Reference', value: this.normativeData.auxiliaryMechanismTechRef }] }
    ]

    const informativeItems: any = [
      { fields: [{ label: 'Long Name', value: this.informativeData.longName, }, { label: 'Original Language Long Name', value: this.informativeData.originalLongName, }] },
      { fields: [{ label: 'Short Name(s)', value: this.informativeData.shortNames.filter(Boolean).join(', '), }, { label: 'Original Language Short Name(s)', value: this.informativeData.originalShortNames.filter(Boolean).join(', '), }] },
      { fields: [{ label: 'Unit Multiplier', value: this.informativeData.attribute.unitMultiplier }] },
      { fields: [{ label: 'Underlying Asset External Identifiers Type', value: JoinObjectList(this.informativeData.externalIdentifier?.underLyingAssets, 'type') }, { label: 'Underlying Asset External Identifiers Value', value: JoinObjectList(this.informativeData.externalIdentifier?.underLyingAssets, 'value') }] },
      { fields: [{ label: 'Digital Token External Identifiers Type', value: JoinObjectList(this.informativeData.externalIdentifier?.digitalTokens, 'type') }, { label: 'Digital Token External Identifiers Value', value: JoinObjectList(this.informativeData.externalIdentifier?.digitalTokens, 'value') }] },
    ]



    const summary: Summary = {
      title: this.informativeData.longName,
      subTitle: `Auxiliary Digital Token`,
      miniSubTitle: this.normativeData.auxiliaryDlt as string,
      hasEditButton: true,
      sections: [
        {
          title: `Issuer Details`,
          items: [...issuerItems]
        },
        {
          title: `Billing Information`,
          items: [...biilingItems]
        },
        {
          title: `Normative Attributes`,
          items: [...normativeItems]
        },
        {
          title: `Informative Attributes`,
          items: [...informativeItems]
        },
      ]
    };
    return summary;
  }

  get changesMade(): any[] {
    // {
    //   title: 'Normative Attributes',
    //   fields: [{ label: 'Date to be activated', value: '15/11/2024' }, { label: ' Genesis Block UTC Timestamp', value: '15/11/2024 18:15:05' }]
    // }
    const changes: any = [],
      initiatorChanges = [];
    if (!!Object.keys(this.auxiliaryTokenToUpdate).length) {
      const { isCreator, lei, companyName, businessIdentifierCode }: any = this.initiatorData
      if (isCreator != this.auxiliaryTokenToUpdate.isCreator) {
        initiatorChanges.push({ label: 'Are you the Creator/Initiator/Maintainer of the Token?', value: isCreator ? `Yes` : `No` });
      }
      if (lei != this.auxiliaryTokenToUpdate.lei && isCreator) {
        if (lei) {
          // Previous null and now have lei.
          initiatorChanges.push({ label: 'Legal Entity Identifier (LEI)', value: lei });
        } else {
          initiatorChanges.push({ label: 'Company', value: companyName });
          initiatorChanges.push({ label: 'Business Identifier Code', value: businessIdentifierCode });
        }
      } else {
        // Change only the companyName || businessIdentifierCode
        if (!!companyName && companyName != this.auxiliaryTokenToUpdate.companyName) {
          initiatorChanges.push({ label: 'Company', value: companyName });
        }
        if (!!businessIdentifierCode && businessIdentifierCode != this.auxiliaryTokenToUpdate.businessIdentifierCode) {
          initiatorChanges.push({ label: 'Business Identifier Code', value: businessIdentifierCode });
        }
      }

      if (initiatorChanges.length) {
        changes.push({
          title: `Initiator Details`,
          fields: initiatorChanges
        });
      }

      //Billing Information
      const billingInfoChanges = [],
        { billingInfo } = this.auxiliaryTokenToUpdate,
        { customerName, financeContract, emailAddress, tellNo, invoiceAddress, vatNumber } = this.initiatorData;

      if (customerName != billingInfo.customerName) {
        billingInfoChanges.push({ label: 'Customer Name', value: customerName });
      }
      if (financeContract != billingInfo.financeContract) {
        billingInfoChanges.push({ label: 'Finance Contact', value: financeContract });
      }
      if (emailAddress != billingInfo.emailAddress) {
        billingInfoChanges.push({ label: 'Email Address', value: emailAddress });
      }
      if (tellNo != billingInfo.tellNo) {
        billingInfoChanges.push({ label: 'Tel No.', value: tellNo });
      }
      if (invoiceAddress != billingInfo.invoiceAddress) {
        billingInfoChanges.push({ label: 'Invoice Address', value: invoiceAddress });
      }
      if (vatNumber != billingInfo.vatNumber) {
        billingInfoChanges.push({ label: 'VAT Number', value: vatNumber });
      }
      if (billingInfoChanges.length) {
        changes.push({
          title: `Billing Information`,
          fields: billingInfoChanges
        });
      }

      const { isProvisional, activationDate, auxiliaryDlt, auxiliaryMechanism, auxiliaryMechanismTechRef } = this.normativeData,
        normativeChanges = [];
      if (auxiliaryDlt != this.auxiliaryTokenToUpdate.normative.auxiliaryDlt) {
        normativeChanges.push({ label: 'Auxiliary Digital Distributed Ledger', value: auxiliaryDlt });
      }
      if (isProvisional != this.auxiliaryTokenToUpdate.normative.isProvisional) {
        normativeChanges.push({ label: 'Provisional', value: isProvisional ? `Yes` : `No` });
      }
      if (auxiliaryMechanism != this.auxiliaryTokenToUpdate.normative.auxiliaryMechanism) {
        normativeChanges.push({ label: 'Auxiliary Digital Token Mechanism', value: auxiliaryMechanism });
      }
      if (auxiliaryMechanismTechRef != this.auxiliaryTokenToUpdate.normative.auxiliaryMechanismTechRef) {
        normativeChanges.push({ label: 'Auxiliary Digital Token Technical Reference', value: auxiliaryMechanismTechRef });
      }

      if (normativeChanges.length) {
        changes.push({
          title: `Normative Attributes`,
          fields: normativeChanges
        });
      }

      const { longName, originalLongName, shortNames, originalShortNames, attribute, externalIdentifier } = this.informativeData,
        { informative } = this.auxiliaryTokenToUpdate,
        shortNamesDiff = DifferenceOfStringList(informative.shortNames, shortNames),
        originalShortNamesDiff = DifferenceOfStringList(informative.originalShortNames, originalShortNames),
        underLyingAssetsTypeDiff = DifferenceOfObjectList(informative.externalIdentifier.underLyingAssets, externalIdentifier.underLyingAssets, 'type'),
        underLyingAssetsValueDiff = DifferenceOfObjectList(informative.externalIdentifier.underLyingAssets, externalIdentifier.underLyingAssets, 'value'),
        digitalTokensTypeDiff = DifferenceOfObjectList(informative.externalIdentifier.digitalTokens, externalIdentifier.digitalTokens, 'type'),
        digitalTokensValueDiff = DifferenceOfObjectList(informative.externalIdentifier.digitalTokens, externalIdentifier.digitalTokens, 'value'),
        informativeChanges = [];

      if (longName != informative.longName) {
        informativeChanges.push({ label: 'Long Name', value: longName });
      }
      if (originalLongName != informative.originalLongName) {
        informativeChanges.push({ label: 'Original Language Long Name', value: originalLongName });
      }
      if (!!shortNamesDiff) {
        informativeChanges.push({ label: 'Short Name(s)', value: shortNamesDiff });
      }
      if (!!originalShortNamesDiff) {
        informativeChanges.push({ label: 'Original Language Short Name(s)', value: originalShortNamesDiff });
      }
      if (attribute.unitMultiplier != informative.attribute.unitMultiplier) {
        informativeChanges.push({ label: 'Unit Multiplier', value: attribute.unitMultiplier });
      }
      if (!!underLyingAssetsTypeDiff) {
        informativeChanges.push({ label: 'Underlying Asset External Identifiers Type', value: underLyingAssetsTypeDiff });
      }
      if (!!underLyingAssetsValueDiff) {
        informativeChanges.push({ label: 'Underlying Asset External Identifiers Value', value: underLyingAssetsValueDiff });
      }
      if (!!digitalTokensTypeDiff) {
        informativeChanges.push({ label: 'Digital Token External Identifiers Type', value: digitalTokensTypeDiff });
      }
      if (digitalTokensValueDiff) {
        informativeChanges.push({ label: 'Digital Token External Identifiers Value', value: digitalTokensValueDiff });
      }

      if (informativeChanges.length) {
        changes.push({
          title: `Informative Attributes`,
          fields: informativeChanges
        });
      }

    }

    return changes;
  }

  get creation(): boolean {
    return this.requestState === RequestState.Creation;
  }

  get editing(): boolean {
    return this.requestState === RequestState.Editing;
  }

  get processing() {
    return this.requestState === RequestState.Processing;
  }

  get successfull() {
    return this.requestState === RequestState.Successful;
  }


  get applicationId(): string {
    return this.auxiliaryService.applicationId;
  }

  isTouched(): boolean | Promise<boolean> {
    return (this.creation || this.editing) && (this.initiatorFormGroup.dirty || this.normativeFormGroup.dirty || this.informativeFormGroup.dirty);
  }


  joinDistributedLedger({ longName, shortName, tokenIdentifier }: any) {
    shortName = shortName ? `(${shortName})` : '';
    return `${longName} ${shortName} - ${tokenIdentifier}`
  }

  // Events

  addShortName(name = '') {
    this.controlShortNames.push(this.formBuilder.control(name, [Validators.pattern(VALIDATIONS.ALPHA_NUMERIC)]))
  }

  addOrigLangShortName(origName = '') {
    this.controlOriginalShortNames.push(this.formBuilder.control(origName, [Validators.pattern(VALIDATIONS.UTF_8_WITH_RESTRICT_SYMBOL)]))
  }

  addUnderLyingAsset(type = '', value = '') {
    this.controlUnderLyingAssets.push(
      this.formBuilder.group({
        type: [type],
        value: [{ value, disabled: !value }, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC)],
      }))
  }

  addDigitalToken(type = '', value = '') {
    this.controlDigitalTokens.push(
      this.formBuilder.group({
        type: [type],
        value: [{ value, disabled: !value }, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC)]
      }))
  }

  deleteExternalIdentifiers(index: number, isUnderyingAssets = true) {
    // if false will use the digital tokens
    const controlExternalIdentifier = isUnderyingAssets ? this.controlUnderLyingAssets : this.controlDigitalTokens;
    controlExternalIdentifier.removeAt(index);
    // will reset the control form array if it's only one
    if (!controlExternalIdentifier.length) {
      controlExternalIdentifier.push(
        this.formBuilder.group({
          type: [''], value: [{ value: '', disabled: true }, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC)]
        })
      )
    }
  }

  onSelectExternalIdentifiers(index: number, isUnderyingAssets = true) {
    const control = isUnderyingAssets ? this.controlUnderLyingAssets.at(index) : this.controlDigitalTokens.at(index),
      valueControl = control.get('value');
    valueControl?.setValidators([Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC)]);
    valueControl?.updateValueAndValidity();
    valueControl?.enable();
  }

  onClickPrevious() {
    const selectedTabIndex = this.stepper ? this.stepper.selectedIndex : -1;
    this.showGlossary = false;
    switch (selectedTabIndex) {
      case 0:
        this.selectedTabIndex = -1;
        break;
      case -1:
        this.router.navigateByUrl('/');
        break
      default:
        this.stepper.previous();
    }
  }

  onClickContinue(isContinue = true) {
    const selectedTabIndex = this.stepper ? this.stepper.selectedIndex : -1,
      setLayoutViews = () => {
        if (this.continueText === 'Submit') {
          this.elSummary.nativeElement.scrollIntoView();
        }
      };
    this.showGlossary = false;
    switch (selectedTabIndex) {
      case 0: // on Normative Tab
        this.normativeFormGroup.markAllAsTouched();
        if (this.normativeFormGroup.valid) {
          // going to Informative Tab
          (isContinue) ? this.stepper.next() : this.stepper.selectedIndex = 2;
        }
        break;
      case 1: // on Informative Tab
        this.informativeFormGroup.markAllAsTouched();
        if (this.informativeFormGroup.valid) {
          // going to review & submit Tab
          this.stepper.next();
        }
        break;
      case 2: // on Review & Submit Tab
        this.dialog.open(TermsAndConditionDialogComponent)
          .afterClosed().subscribe((accept: boolean) => {
            if (accept) {
              this.requestState = RequestState.Processing;
              const auxiliaryData = {
                ...this.initiatorData as any,
                normative: { ...this.normativeData },
                informative: { ...this.informativeData },
                metaData: {
                  email: this.userService.email,
                  timeStamp: (new Date()).toISOString()
                },
                additionalInfo: this.additionalInfo,
                billingInfo: {
                  ...this.billingInfo
                }
              };
              if (!Object.keys(this.auxiliaryTokenToUpdate).length) {
                this.loadingText = 'Submitting your request. Please wait...';
                this.auxiliaryService.submit(auxiliaryData).subscribe(({ key }: any) => {
                  this.auxiliaryService.applicationId = key;
                  this.successTitle = 'New token has been successfully submitted!';
                  this.requestState = RequestState.Successful;
                }, (err) => {
                  this.requestState = RequestState.Error;
                  console.log(err, auxiliaryData);
                });
              } else {
                this.loadingText = 'Updating your request. Please wait...';
                this.auxiliaryService.updateRequestDetail(auxiliaryData).subscribe((data) => {
                  this.successTitle = 'Form successfully updated!';
                  this.requestState = RequestState.Successful;
                }, (err) => {
                  this.requestState = RequestState.Error;
                  console.log(err, auxiliaryData);
                })
              }
            }
          });
        break;
      default: // on initiator tab
        this.initiatorFormGroup.markAllAsTouched();
        if (this.initiatorFormGroup.valid) {
          // going to review and submit
          this.selectedTabIndex = 0;
          if (!isContinue) {
            setTimeout(() => {
              this.stepper.selectedIndex = 2;
              setLayoutViews();
            }, 100)
          }
        }
    }
    setLayoutViews();
  }

  onTabChange(selectedIndex: number) {
    if (selectedIndex > -1) {
      this.stepper.selectedIndex = selectedIndex
    }
    this.selectedTabIndex = selectedIndex;
  }


  // Methods
  setFieldsValue(auxiliaryTokenToUpdate: any): void {
    // 
    const { isCreator, lei, companyName, businessIdentifierCode, billingInfo, normative, informative, additionalInfo } = auxiliaryTokenToUpdate,
      { customerName, financeContract, emailAddress, tellNo, invoiceAddress, vatNumber } = billingInfo,
      { isProvisional, activationDate, auxiliaryDlt, auxiliaryMechanism, auxiliaryMechanismTechRef } = normative,
      { longName, originalLongName, attribute, shortNames, originalShortNames, externalIdentifier } = informative,
      hasLei = !!lei?.length;

    this.initiatorFormGroup.patchValue({
      lei, isCreator, hasLei,
      companyName, businessIdentifierCode, customerName,
      financeContract, emailAddress, tellNo, invoiceAddress, vatNumber
    });

    if (isCreator) this.initiatorComp.setFieldsValidation(false);

    this.normativeFormGroup.patchValue({
      isProvisional,
      activationDate,
      auxiliaryDlt,
      auxiliaryMechanism,
      auxiliaryMechanismTechRef
    })

    this.informativeFormGroup.patchValue({
      longName,
      originalLongName,
      attribute
    });

    shortNames.forEach((name: string) => {
      if (name?.length) {
        this.addShortName(name);
      }
    });

    // Adding short name, cause there a chance submitted token has no short name.
    if (!this.controlShortNames.length) {
      this.addShortName();
    }

    originalShortNames.forEach((name: string) => {
      if (name?.length) {
        this.addOrigLangShortName(name);
      }
    });

    // Adding short name, cause there a chance submitted token has no short name.
    if (!this.controlOriginalShortNames.length) {
      this.addOrigLangShortName();
    }

    externalIdentifier.underLyingAssets.forEach(({ type, value }: any) => {
      if (type?.length && value?.length) {
        this.addUnderLyingAsset(type, value);
      }
    });

    externalIdentifier.digitalTokens.forEach(({ type, value }: any) => {
      if (type?.length && value?.length) {
        this.addDigitalToken(type, value);
      }
    });

    this.hasExternalIdentifier = !!this.controlUnderLyingAssets.length || !!this.controlDigitalTokens.length;

    if (!this.controlUnderLyingAssets.length) {
      this.addUnderLyingAsset();
    }
    if (!this.controlDigitalTokens.length) {
      this.addDigitalToken();
    }

    this.additionalInfo = additionalInfo;

  }
}
