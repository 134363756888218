import { Component, Input, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { Comment } from '../../../app/shared/models/interface';
import { UserService } from 'src/app/data/user.service';

@Component({
  selector: 'dti-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

  @Input() id: string = '';
  comments: Comment[] = [];
  textComment: string = '';
  constructor(private requestService: RequestService, private userService: UserService) {
  }



  ngOnInit() {
    this.getAllComments();
  }

  getAllComments() {
    this.textComment = '';
    this.requestService.getComments(this.id).subscribe(({ comments }) => {
      this.comments = comments;
    });
  }

  addComment() {
    if (this.textComment.length > 3) {
      this.requestService.addComment(this.id, {
        text: this.textComment,
        author: this.userService.email
      }).subscribe((e) => {
        this.getAllComments();
      });
    }
  }


  dateAgo(value: string) {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      let intervals: any = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
      let counter;
      for (let i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
    }
    return value;

  }


  // need to replace <br/> since JIRA Author returning innerHTML. value
  removeBreakTag(text: string) {
    return text.replace('<br/>', '');
  }

}

