<dti-loading *ngIf="isProcessing" [text]="loadingText"></dti-loading>
<div class="container">
    <div class="row pt-1-50">
        <div class="col">
            <h3 class="title">{{requestDetails.title}}</h3>
            <h4 class="back-link pointer" routerLink="/my-requests">&#60; Back to My Requests</h4>
        </div>
    </div>
    <div class="row">
        <div class="col flex-grow-3 summary">
            <dti-summary [details]="requestDetails" (onEdit)="onEdit($event)"></dti-summary>
            <dti-comments [id]="submittedTokenId"></dti-comments>
        </div>
        <div class="col flex-grow-1 action">
            <p class="details"><span class="label">Raised by :</span><span class="value static-text"><b>{{userNickName}}</b></span></p>
            <p class="details"><span class="label">Date created :</span><span class="value static-text"><b>{{dateCreated | date:'MM/d/yy'}}</b></span></p>
            <p class="details"><span class="label">Status :</span><span class="value status {{status.toLowerCase()}}"><b>{{status}}</b></span></p>

            <div class="row pt-2" *ngIf="canCancelRequest">
                <div class="col text-center">
                    <button mat-raised-button disableRipple="true" color="outline" class="button" (click)="openConfirmationToCancel()">Cancel Request</button>
                </div>
            </div>
        </div>
    </div>
</div>