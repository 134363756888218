import { Component, ViewChild, AfterContentInit } from  '@angular/core';
import { ElementRef } from '@angular/core';
import SwaggerUI from 'swagger-ui';
import apiDoc from '../../assets/swagger/openapi.json'

@Component({
  selector: 'app-api-documentations',
  templateUrl: './api-documentations.component.html',
  styleUrls: ['./api-documentations.component.scss']
})

export class ApiDocumentationsComponent implements AfterContentInit {
  @ViewChild('apidocumentation',{static: true}) custApiDocElement: ElementRef | undefined
  constructor() { }
  
  ngAfterContentInit(): void {
    const apiDocumentation = apiDoc;
    const ui = SwaggerUI({
      spec: apiDocumentation,
      domNode: this.custApiDocElement?.nativeElement,
    })
  }
}
