import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IjeService {

  private urlGenesisBlockHashAlgorithm = `${environment.ijeBaseUrl}/json/distributed_ledger_without_a_native_digital_token.blockchain.json`;
  private urlAuxiliaryLedgerMechanism = `${environment.ijeBaseUrl}/json/auxiliary_ledger_mechanism.json`;

  constructor(private http: HttpClient) { }

  // TODO: Will use the HTTP get of angular in the future
  // when access key was implemented in IJE
  getGenesisBlockHashAlgorithm() {
    // return this.http.get(this.urlGenesisBlockHashAlgorithm);
    return fetch(this.urlGenesisBlockHashAlgorithm).then((res) => res.json());
  }

  getAuxiliaryLedgerMechanisms() {
    return fetch(this.urlAuxiliaryLedgerMechanism).then((res) => res.json());
  }
  
}
