<div class="container">
    <div class="row">
        <div class="col col-title">
            <p class="title"><mat-icon>warning</mat-icon> <span class="text-title">{{title}}</span></p>
        </div>
        <div class="col text-right">
            <button mat-icon-button class="close-button" mat-dialog-close tabindex="-1">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content>
        <p class="content-text">{{message}}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button mat-dialog-close color="outline" tabindex="-1" class="actions">{{noButtonText}}</button>
        <button mat-raised-button color="accent" [mat-dialog-close]="true" tabindex="-1" class="actions">{{yesButtonText}}</button>
    </mat-dialog-actions>
</div>