import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IDialogData {
  title?: string
  message?: string
  yesButtonText?: string
  noButtonText?: string
}

@Component({
  selector: 'dti-confirmation-diallog',
  templateUrl: './confirmation-diallog.component.html',
  styleUrls: ['./confirmation-diallog.component.scss']
})

export class ConfirmationDiallogComponent {

  title = '';
  message = '';
  yesButtonText = '';
  noButtonText = '';

  constructor(@Inject(MAT_DIALOG_DATA) data: IDialogData) {
    this.title = data?.title || 'Cancel token request?';
    this.message = data?.message || 'Are you sure you want to close this request? ';
    this.yesButtonText = data?.yesButtonText || 'Yes, Cancel Request';
    this.noButtonText = data?.noButtonText || 'No, Go Back';
  }

}
