<div class="initiator-container">
    <div class="container-wrapper">
        <div class="section-title row">
            <div class="col">
                To start the submission process, please fill out the following.
            </div>
        </div>
        <form [formGroup]="formGroup">
            <div class="row pt-2">
                <div class="col flex-column">
                    <label class="question-label">Are you the Creator/Initiator/Maintainer of the {{tokenType}}?</label>
                    <!-- (change)="onChangeLEI()"  formControlName="isCreator" -->
                    <mat-radio-group class="rb-group" aria-label="select if creator" formControlName="isCreator" (change)="setFieldsValidation()">
                        <mat-radio-button class="rb-items" color="primary" [value]="true">Yes</mat-radio-button>
                        <mat-radio-button class="rb-items" color="primary" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row pt-1" *ngIf="isCreator">
                <div class="col">
                    <label for="" class="question-label">Do you have a Legal Entity Identifier (LEI)?</label>
                    <!-- [(ngModel)]="hasLEI" (change)="onChangeLEI()" -->
                    <mat-radio-group class="rb-group" aria-label="select if LEI" formControlName="hasLei" (change)="setFieldsValidation()">
                        <mat-radio-button class="rb-items" color="primary" [value]="true">Yes</mat-radio-button>
                        <mat-radio-button class="rb-items" color="primary" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row pt-1 flex-column" *ngIf="isCreator && hasLei">
                <div class="col">
                    <label for="" class="question-label">Please input your Legal Entity Identifier (LEI).</label>
                </div>
                <div class="col pt-1 row">
                    <div class="col flex-grow-3">
                        <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                            <mat-label class="field-label-size">Legal Entity Identifier (LEI)</mat-label>
                            <input matInput class="field-size" formControlName="lei" placeholder="Legal Entity Identifier (LEI)" maxlength="20" oninput="this.value = this.value.toUpperCase()">
                            <mat-error *ngIf="formGroup.get('lei')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                            <mat-error *ngIf="!formGroup.get('lei')?.hasError('required') && formGroup.get('lei')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_MAX20}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col"></div>
                </div>
            </div>
            <div class="row pt-1 flex-column" *ngIf="isCreator && !hasLei">
                <div class="col">
                    <label for="" class="question-label">Please input the following details.</label>
                </div>
                <div class="col pt-1 row">
                    <div class="col flex-grow-3">
                        <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="form-field-size">
                            <mat-label class="field-label-size">Company Name</mat-label>
                            <input matInput class="field-size" formControlName="companyName" placeholder="Company Name" maxlength="255">
                            <mat-error *ngIf="formGroup.get('companyName')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                            <mat-error *ngIf="!formGroup.get('companyName')?.hasError('required') && formGroup.get('companyName')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_VALID_SYMBOL_UTF_8_ERROR_MESSAGE}}</mat-error>
                        </mat-form-field>
                        <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="form-field-size">
                            <mat-label class="field-label-size">Business Identifier Code</mat-label>
                            <input matInput class="field-size" formControlName="businessIdentifierCode" placeholder="Business Identifier Code" maxlength="11"
                                oninput="this.value = this.value.toUpperCase()">
                            <mat-error>{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_MIN8_MAX11}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div [class.pt-1]="!isCreator" class="row flex-column billing-section">
                <div class="col">
                    <p class="question-label">Billing Informations</p>
                    <p class="warning-notes">Please note that effective <strong>8 July 2024</strong>, a fee of <strong>€180</strong> will be charged for each allocated DTI (<a href="https://dtif.org/fee-model/" target="_blank">Fee
                        Model</a>). Payment request will be sent to you once the request has been validated. The DTI will
                    be allocated within 24 hours after the payment is received. Full terms and conditions are available <a
                        href="https://dtif.org/wp-content/uploads/2024/07/DTI-Allocation-Request-TCs-20240704.pdf" target="_blank">here</a>.</p>
                </div>
                <div class="col pt-1">
                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="form-field-size">
                        <mat-label class="field-label-size">Customer Name</mat-label>
                        <input matInput class="field-size" formControlName="customerName" placeholder="Customer Name" maxlength="255">
                        <mat-error class="pb-50" *ngIf="formGroup.get('customerName')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                        <mat-error class="pb-50" *ngIf="formGroup.get('customerName')?.hasError('pattern')">{{VALIDATIONS_MESSAGE.LETTERS_AND_NUMBERS_ONLY('Customer Name')}}</mat-error>
                    </mat-form-field>
                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="form-field-size">
                        <mat-label class="field-label-size">Finance Contact</mat-label>
                        <input matInput class="field-size" formControlName="financeContract" placeholder="Finance Contact" maxlength="255">
                        <mat-error class="pb-50" *ngIf="formGroup.get('financeContract')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                        <mat-error class="pb-50" *ngIf="formGroup.get('financeContract')?.hasError('pattern')">{{VALIDATIONS_MESSAGE.LETTERS_AND_NUMBERS_ONLY('Finance Contact')}}</mat-error>
                    </mat-form-field>
                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="form-field-size">
                        <mat-label class="field-label-size">Email address</mat-label>
                        <input matInput type="email" class="field-size" formControlName="emailAddress" placeholder="Email address" maxlength="255">
                        <mat-error *ngIf="formGroup.get('emailAddress')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                        <mat-error *ngIf="formGroup.get('emailAddress')?.hasError('pattern')">{{VALIDATIONS_MESSAGE.EMAIL}}</mat-error>
                    </mat-form-field>
                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="form-field-size">
                        <mat-label class="field-label-size">Tel No.</mat-label>
                        <input matInput class="field-size" formControlName="tellNo" placeholder="Tel No." maxlength="255">
                        <mat-error *ngIf="formGroup.get('tellNo')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                        <mat-error *ngIf="formGroup.get('tellNo')?.hasError('pattern')">{{VALIDATIONS_MESSAGE.NUMBERS_ONLY('Tel. No.')}}</mat-error>
                    </mat-form-field>
                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="form-field-size">
                        <mat-label class="field-label-size">Invoice Address</mat-label>
                        <input matInput class="field-size" formControlName="invoiceAddress" placeholder="Invoice Address" maxlength="255">
                        <mat-error class="pb-50" *ngIf="formGroup.get('invoiceAddress')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                        <mat-error class="pb-50" *ngIf="formGroup.get('invoiceAddress')?.hasError('pattern')">{{VALIDATIONS_MESSAGE.LETTERS_AND_NUMBERS_ONLY('Invoice Address')}}</mat-error>
                    </mat-form-field>
                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="form-field-size">
                        <mat-label class="field-label-size">VAT Number</mat-label>
                        <input matInput class="field-size" formControlName="vatNumber" placeholder="VAT Number (optional)" maxlength="255">
                        <mat-error *ngIf="formGroup.get('vatNumber')?.hasError('pattern')">{{VALIDATIONS_MESSAGE.NUMBERS_ONLY('VAT No.')}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="isDLT" class="row pt-50 flex-column">
                <div class="col">
                    <label for="" class="question-label">
                        Does the DLT support the following?
                    </label>
                    <!--will add FFG on FormGroup in DLT -->
                    <mat-checkbox formControlName="hasNativeToken" color="primary" class="cb-items">
                        Native Token(s) – <span>digital token(s) with a privileged position in the DLT</span> 
                    </mat-checkbox>
                    <mat-checkbox formControlName="hasAuxiliaryToken" color="primary" class="cb-items">
                        Ability to create Auxiliary Digital Tokens – <span>non-native digital token created as an application on the DLT</span> 
                    </mat-checkbox>
                </div>
            </div>
        </form>
    </div>
</div>