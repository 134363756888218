<div class="notification" *ngIf="showNotif">
    <mat-icon class="material-icons-font material-icons" color="accent">warning</mat-icon>

    <p>Please note that effective <strong>8 July 2024</strong>, a fee of <strong>€180</strong> will be charged for each allocated DTI (<a href="https://dtif.org/fee-model/" target="_blank">Fee
            Model</a>). Payment request will be sent to you once the request has been validated. The DTI will
        be allocated within 24 hours after the payment is received. Full terms and conditions are available <a
            href="https://dtif.org/wp-content/uploads/2024/07/DTI-Allocation-Request-TCs-20240704.pdf" target="_blank">here</a>.</p>
    <button mat-icon-button [disableRipple]="true" (click)="showNotif = !showNotif">
        <mat-icon class="material-icons-font material-icons close-icon" color="accent">close</mat-icon>
    </button>
</div>
<div class="user section">
    <div class="profile">
        <img [src]="picture" alt="" class="border">
    </div>
    <div class="details">
        <p class="username">Hello,&nbsp;{{nickname}}. Welcome back!</p> 
        <p class="user-info">
            <img src="../../../assets/img/awesome-id-card.svg" alt=""><span>Name:&nbsp;<b> {{name}}</b></span>
            <img src="../../../assets/img/material-email.svg" alt=""> <span>Email:&nbsp;<b> {{email}}</b></span>
        </p>
        <!-- TODO: Will enable once edit profile page is available -->
        <!-- <button matTooltip="Comming Soon" mat-raised-button disableRipple="true" color="accent" disabled class="button">Edit Profile</button> -->
    </div>
</div>
<div class="recent-request section ">
    <h1>Recent Requests</h1>
    <div class="section-body">
        <div *ngIf="hasRequest" class="spinner-container"><mat-spinner></mat-spinner></div>
        <dti-request-list [dataSource]="dataSource"/>
        <button mat-raised-button *ngIf="hasRecentRequest" disableRipple="true" color="accent" routerLink="/my-requests">View All Request</button>
    </div>
</div>
<div class="request-token section ">
    <h1>DTI Allocation</h1>
    <div class="section-body">
        <div class="border">
            <img src="../../../assets/img/ledger.svg" alt="">
            <p>Distributed Ledger Technology Token Request</p>
            <button mat-raised-button disableRipple="true" color="accent" routerLink="/distributed-ledger-token">Request</button>
        </div>
        <div class="border">
            <img src="../../../assets/img/token-icon.svg" alt="">
            <p>Auxiliary Digital Token Request</p>
            <button mat-raised-button disableRipple="true" color="accent" routerLink="/auxiliary-token">Request</button>
        </div>
        <div class="border">
            <img src="../../../assets/img/ffg-icon.svg" alt="">
            <p>Functionally Fungible Group of Tokens Request</p>
            <button mat-raised-button disableRipple="true" disabled class="button" color="accent">Coming Soon</button>
        </div>
    </div>
</div>
<div class="enquiry section ">
    <h1>Amendment or Enquiry</h1>
    <div class="section-body">
        <img src="../../../assets/img/character-inquiry.svg" alt="">
        <p>Request an amendment or raise and enquiry for the existing record in the registry.</p>
        <a mat-raised-button disableRipple="true" href="mailto:support@dtif.org?subject=Enquiry or DTI Amendment request" target="_blank">
            <span>Contact Us</span>
        </a>
    </div>
</div>