import { Component } from '@angular/core';

@Component({
  selector: 'dti-link-to-contact-us',
  templateUrl: './link-to-contact-us.component.html',
  styleUrls: ['./link-to-contact-us.component.scss']
})
export class LinkToContactUsComponent {

}
