import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import * as auth0 from 'auth0-js';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { VALIDATIONS_MESSAGE, VALIDATIONS } from '../shared/util/validations';
import { environment } from 'src/environments/environment';
import { RequestState } from '../shared/models/enums';

@Component({
  selector: 'app-auth0-register',
  templateUrl: './auth0-register.component.html',
  styleUrls: ['./auth0-register.component.scss']
})
export class Auth0RegisterComponent {

  VALIDATIONS_MESSAGE = VALIDATIONS_MESSAGE;

  errorMessage = ''
  state = RequestState.Processing;
  showPassword = false;

  get onSuccess(): boolean {
    return this.state == RequestState.Successful;
  }
  get onError(): boolean {
    return this.state == RequestState.Error;
  }
  get onProcessing(): boolean {
    return this.state == RequestState.Processing;
  }

  get inputFirstName(): FormControl {
    return <FormControl>this.formGroup.get('firstName');
  }

  get firstNameHasErrorRequired(): boolean {
    return this.inputFirstName.hasError('required');
  }

  get inputLastName(): FormControl {
    return <FormControl>this.formGroup.get('lastName');
  }

  get lastNameHasErrorRequired(): boolean {
    return this.inputLastName.hasError('required');
  }

  get inputEmail(): FormControl {
    return <FormControl>this.formGroup.get('email');
  }


  get inputPassword(): FormControl {
    return <FormControl>this.formGroup.get('password');
  }

  get visibility(): string {
    return this.showPassword ? "visibility" : "visibility_off";
  }

  get passwordType(): string {
    return this.showPassword ? "text" : "password";
  }

  private auth0 = new auth0.WebAuth({
    clientID: environment.clientID,
    domain: environment.domain,
    redirectUri: window.location.origin,
    responseType: 'token id_token',
    scope: 'openid profile email'
  });

  formGroup = this.formBuilder.group({
    firstName: ['', [Validators.required, Validators.minLength(3), Validators.pattern(VALIDATIONS.LATIN_CHARACTERS_WHITESPACE)]],
    lastName: ['', [Validators.required, Validators.minLength(3), Validators.pattern(VALIDATIONS.LATIN_CHARACTERS_WHITESPACE)]],
    email: ['', [Validators.required, Validators.pattern(VALIDATIONS.EMAIL)]],
    password: ['', [Validators.required, Validators.pattern(VALIDATIONS.PASSWORD)]],
  });

  constructor(private authService: AuthService, private formBuilder: FormBuilder) { }

  register() {
    if (this.formGroup.valid) {
      // TODO: will adjust the backend parameters for FirstName and LastName
      const { firstName, lastName, email, password }: any = this.formGroup.value;
      this.authService.signup(email, password, firstName, lastName).then(result => {
        this.state = RequestState.Successful;
        this.errorMessage = 'Succefully registered!! Email was sent for verifications...'
        // To give time for user to read the message.
        setTimeout(() => {
          this.auth0.authorize({ prompt: 'login' });
        }, 8000);
        console.log(result);
      }).catch(({ description }) => {
        this.errorMessage = description;
        this.state = RequestState.Error;
      })
    }
  }
}
