import { Component, Input } from '@angular/core';

@Component({
  selector: 'dti-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  @Input() text: string = `Submitting your request. Please wait…`;

  constructor() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');
  }
  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('modal-open');
  }
}
