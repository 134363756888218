import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'dti-terms-and-condition-dialog',
  templateUrl: './terms-and-condition-dialog.component.html',
  styleUrls: ['./terms-and-condition-dialog.component.scss']
})
export class TermsAndConditionDialogComponent {

  isReadAll = false;

  onScroll({ target: elem }: any) {
    const topPadding = 10;
    // calculate if scroll bar is in the bottom.
    if ((elem.offsetHeight + elem.scrollTop) >= (elem.scrollHeight - topPadding)) {
      this.isReadAll = true;
    }
  }

}
