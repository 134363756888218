<div class="container flex-column">
    <mat-horizontal-stepper ngClass="mat-step-headers" color="accent" [linear]="true" labelPosition="bottom" (selectionChange)="onStepperChange($event)" #stepper>
        <!-- Normative -->
        <mat-step [stepControl]="normativeFG" [editable]="!isTokenRequestSuccess">
            <ng-template matStepLabel>
                <p>
                    Normative Attributes
                </p>
            </ng-template>
            <ng-template matStepperIcon="edit">
                <i class=" material-symbols-outlined material-icons-font material-icons">
                    check
                </i>
            </ng-template>
            <form [formGroup]="normativeFG">
                <div class="normative-container flex-column container container-step">
                    <div class="row">
                        <p class="container-title col">Please enter the following details</p>
                    </div>
                    <div class="row">
                        <div class="col">
                            <mat-form-field matTooltip="This is Destributed Ledger Technology" matTooltipPosition="after" color="primary" appearance="outline" floatLabel="auto">
                                <mat-label class="field-label-size" color="primary">Destributed Ledger Technology</mat-label>
                                <mat-select class="field-size" placeholder="Select" formControlName="dlt" color="primary" (selectionChange)="dltOnChange()">
                                    <mat-option [ngClass]="'field-size'" value="Other">Other</mat-option>
                                    <mat-option [ngClass]="'field-size'" value="Blockchain">Blockchain</mat-option>
                                </mat-select>
                                <mat-error *ngIf="getNormativeControl('dlt')?.hasError('required')">This field is required.</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col text-center">
                            <!-- [(ngModel)]="provisional" -->
                            <span class="provisional-label">Provisional</span><mat-slide-toggle formControlName="isProvisional" disableRipple="true" class="provisional field-size"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="row" *ngIf="provisional">
                        <div class="col">
                            <!-- <mat-slide-toggle checked="true">Provisional</mat-slide-toggle> -->
                            <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                <mat-label class="field-label-size">Date to be Activated</mat-label>
                                <!-- [(ngModel)]="dateToBeActivated" -->
                                <input class="field-size" [min]="dateToBeActivatedMin" matInput placeholder="Date to be Activated" [matDatepicker]="picker">
                                <mat-datepicker-toggle class="field-size" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker class="field-size" #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col"></div>
                    </div>
                    <hr class="divider">
                    <!-- <mat-divider></mat-divider> -->
                    <div class="row" *ngIf="isDLTBlockchain">
                        <div class="col">
                            <mat-form-field matTooltip="Genesis Block Hash Algorithm. A Genesis Block is the name given to the first block a cryptocurrency, such as Bitcoin, ever mined."
                                matTooltipPosition="after" color="primary" appearance="outline" floatLabel="auto">
                                <mat-label class="field-label-size">Genesis Block Hash Algorithm</mat-label>
                                <!-- [(value)]="gbha" -->
                                <mat-select class="field-size" placeholder="Select" formControlName="gbha">
                                    <mat-option *ngFor="let option of gbhaOptions" [value]="option">{{option}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="getNormativeControl('gbha')?.hasError('required')">This field is required.</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col"></div>
                        <!-- <span>Please format the block hash in hexadecimal, using lower case characters. To be represented as big-endian with leading zeroes. Do not use any prefix like 0x. This will be
                            exactly
                            64
                            characters long.</span> -->
                    </div>
                    <div class="row" *ngIf="isDLTBlockchain">
                        <div class="col">
                            <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                <mat-label class="field-label-size">Genesis Block Hash</mat-label>
                                <!-- [(ngModel)]="gbh" -->
                                <input matInput formControlName="gbh" class="field-size" placeholder="Genesis Block Hash" maxlength="200">
                                <mat-error *ngIf="getNormativeControl('gbh')?.hasError('required')">This field is required.</mat-error>
                                <mat-error *ngIf="!getNormativeControl('gbh')?.hasError('required') && getNormativeControl('gbh')?.errors">This field supports alphanumetic text.</mat-error>

                            </mat-form-field>
                        </div>
                        <div class="col">
                            <button mat-icon-button color="primary" [matTooltipPosition]="'right'" matTooltip="Please format the block hash in hexadecimal, using lower case characters. To be represented as big-endian with leading zeroes. Do not use any prefix like 0x. This will
                            be exactly 64 characters long.">
                                <mat-icon class="material-symbols-outlined material-icons-font material-icons">help</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="row" *ngIf="isDLTBlockchain">
                        <div class="col">
                            <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                <mat-label class="field-label-size">Genesis Block UTC Timestamp</mat-label>
                                <!-- [(ngModel)]="gbUTCTime"  -->
                                <input matInput [ngxMatDatetimePicker]="picker" formControlName="gbUTCTime" class="field-size" placeholder="Genesis Block UTC Timestamp" maxlength="20">
                                <mat-datepicker-toggle matSuffix [for]="$any(picker)">
                                </mat-datepicker-toggle>
                                <!-- <mat-datepicker-toggle class="field-size" matSuffix  [for]="picker"></mat-datepicker-toggle> -->
                                <ngx-mat-datetime-picker [showSeconds]="true" #picker></ngx-mat-datetime-picker>
                                <!-- <mat-datepicker class="field-size" #picker></mat-datepicker> -->
                                <mat-error *ngIf="getNormativeControl('gbUTCTime')?.hasError('required')">Enter valid date(dd/MM/yyyy HH:mm:ss)</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col"></div>
                    </div>
                </div>
            </form>
            <!-- <div class="footer">
            <button mat-button matStepperNext>Continue</button>
        </div> -->
        </mat-step>
        <!-- Informative -->
        <mat-step [stepControl]="informativeFG" [editable]="!isTokenRequestSuccess">
            <ng-template matStepLabel>
                <p>Informative Attributes</p>
            </ng-template>

            <div class="informative-container flex-column container container-step">
                <div class="row">
                    <div class="col">
                        <mat-checkbox [(ngModel)]="hasOLLN" color="primary" class="cb-items">
                            Does the token have a long name in the original language that is different from the long name already specified?
                        </mat-checkbox>
                    </div>
                </div>
                <h4 class="section-title pt-1">Long Names</h4>
                <hr class="divider">
                <form [formGroup]="informativeFG">
                    <div class="row pt-1-50">
                        <div class="col">
                            <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                <mat-label class="field-label-size">Long Name</mat-label>
                                <input class="field-size" formControlName="longName" matInput placeholder="Long Name" maxlength="100">
                                <!-- [(ngModel)]="longName" -->
                                <mat-error *ngIf="getInformativeControl('longName')?.hasError('required')">This field is required.</mat-error>
                                <mat-error *ngIf="!getInformativeControl('longName')?.hasError('required') && getInformativeControl('longName')?.errors">This field support alphanumetic &
                                    symbols(.-_)</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field *ngIf="hasOLLN" color="primary" appearance="outline" floatLabel="auto">
                                <mat-label class="field-label-size">Original Language Long Name</mat-label>
                                <!-- [(ngModel)]="origLangLongName" -->
                                <input class="field-size" formControlName="origLangLongName" matInput placeholder="Original Language Long Name" maxlength="100">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row pt-1">
                        <div class="col">
                            <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                <mat-label class="field-label-size">Token Reference Url </mat-label>
                                <!-- [(ngModel)]="tokenReference" -->
                                <input matInput formControlName="tokenReferenceURL" class="field-size" placeholder="Token Reference Url" maxlength="100">
                                <mat-error *ngIf="getInformativeControl('tokenReferenceURL')?.hasError('required')">This field is required.</mat-error>
                                <mat-error *ngIf="!getInformativeControl('tokenReferenceURL')?.hasError('required') && getInformativeControl('tokenReferenceURL')?.errors">Please enter valid
                                    URL</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                <mat-label class="field-label-size">Public Distributed Ledger Indicator </mat-label>
                                <!-- [(value)]="publicDistributorLedgerIndicator -->
                                <mat-select class="field-size" placeholder="Select" formControlName="publicDistributorLedgerIndicator">
                                    <mat-option value="No">Private</mat-option>
                                    <mat-option value="Yes">Public</mat-option>
                                </mat-select>
                                <mat-error *ngIf="getInformativeControl('publicDistributorLedgerIndicator')?.hasError('required')">This field is required.</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </mat-step>
        <!-- Native Token -->
        <mat-step [stepControl]="nativeTokenFG" [editable]="!isTokenRequestSuccess" *ngIf="options.hasNativeToken">
            <ng-template matStepLabel>
                <p> Native Token Creation</p>
            </ng-template>
            <div class="native-container flex-column container container-step">
                <div class="row">
                    <div class="col">
                        <label class="long-name">{{(informativeData).longName}}</label>
                    </div>
                    <div class="col text-right">
                        <label class="native-digital-token">Native Digital Token</label> | <label class="dlt-label">{{(normativeData).dlt}}</label>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col">
                        <p class="tab-header-details">Please enter the following details for Native Digital Token:</p>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col">
                        <mat-tab-group [color]="'primary'" [selectedIndex]="nativeTokenSelectedTab" animationDuration="0ms" class="allow-tab-events" mat-stretch-tabs="false" mat-align-tabs="start">
                            <form [formGroup]="nativeTokenFG">
                                <ng-container formArrayName="nativeTokens">
                                    <mat-tab *ngFor="let nativeToken of nativeTokens.controls let nativeTokenIndex = index" [formGroupName]="nativeTokenIndex">
                                        <ng-template mat-tab-label>
                                            <div style="padding-right: 1rem;"> Native Token {{nativeTokenIndex + 1}} </div>
                                            <i *ngIf="nativeTokens.length > 1" (click)=" removeNativeTokenTab($event,nativeTokenIndex)"
                                                class="primary-color material-symbols-outlined material-icons-font material-icons">
                                                close
                                            </i>
                                        </ng-template>
                                        <div class="tab-content">
                                            <div class="row pt-1-50">
                                                <div class="col">
                                                    <mat-checkbox (change)="onChangeOrigLangShortName($event, nativeTokenIndex)" formControlName="hasOrigLangShortName" color="primary"
                                                        class="has-original-language-short-name">
                                                        Does the token have a short name in the original language that is different from the short name already specified?
                                                    </mat-checkbox>
                                                    <!-- [(ngModel)]="nativeToken.hasOriginalLangaugeShortName" -->
                                                </div>
                                            </div>
                                            <h3 class="section-title pt-1-50">Short Names</h3>
                                            <hr>
                                            <div class="row border p pr-0">
                                                <div class="col" formArrayName="shortNames">
                                                    <div class="row pt-50" *ngFor="let shortNameControl of getShortNames(nativeTokenIndex).controls; let shortNameIndex = index"
                                                        [formGroupName]="shortNameIndex">
                                                        <div class="col field-col">
                                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                                                <mat-label class="field-label-size">Short Name {{shortNameIndex+1}}</mat-label>
                                                                <input matInput class="field-size" formControlName="shortName" placeholder="Short Name" maxlength="256">
                                                                <mat-error *ngIf="shortNameControl.get('shortName')?.hasError('required')">This field is required.</mat-error>
                                                                <mat-error *ngIf="!shortNameControl.get('shortName')?.hasError('required') && shortNameControl.get('shortName')?.errors">This field
                                                                    accepts alphanumeric text.</mat-error>
                                                                <!-- [(ngModel)]="data.shortName" -->
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col delete-col">
                                                            <i *ngIf="getShortNames(nativeTokenIndex).length > 1" (click)="deleteShortName(nativeTokenIndex, shortNameIndex)"
                                                                class="material-symbols-outlined material-icons-font material-icons">
                                                                delete
                                                            </i>
                                                        </div>
                                                    </div>
                                                    <i *ngIf="getShortNames(nativeTokenIndex).length < 5" (click)="addShortName(nativeTokenIndex)"
                                                        class="primary-color material-symbols-outlined material-icons-font material-icons">
                                                        add
                                                    </i>
                                                </div>
                                                <div class="col" formArrayName="origlangShortNames">
                                                    <ng-container *ngIf="nativeToken.get('hasOrigLangShortName')?.value">
                                                        <div class="row pt-50"
                                                            *ngFor="let origLangShortNameControl of getOrigLangShortNames(nativeTokenIndex).controls; let origLangShortNameIndex = index"
                                                            [formGroupName]="origLangShortNameIndex">
                                                            <div class="col field-col">
                                                                <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                                                    <mat-label class="field-label-size">Original Langauge Short Name</mat-label>
                                                                    <input matInput formControlName="origlangShortName" class="field-size" placeholder="Original Langauge Short Name" maxlength="30">
                                                                    <mat-error>This field accepts ASCII characters only.</mat-error>
                                                                    <!-- [(ngModel)]="data.originalLangaugeShortName" -->
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col delete-col">
                                                                <i *ngIf="getOrigLangShortNames(nativeTokenIndex).length > 1"
                                                                    (click)="deleteOrigLangShortName(nativeTokenIndex, origLangShortNameIndex)"
                                                                    class="material-symbols-outlined material-icons-font material-icons">
                                                                    delete
                                                                </i>
                                                            </div>
                                                        </div>
                                                        <i *ngIf="getOrigLangShortNames(nativeTokenIndex).length < 5" (click)="addOrigLangShortName(nativeTokenIndex)"
                                                            class="primary-color material-symbols-outlined material-icons-font material-icons">
                                                            add
                                                        </i>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <!-- <br> -->
                                            <h3 class="section-title pt-2">Attributes</h3>
                                            <hr>
                                            <div class="row pt-1">
                                                <div class="col">
                                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                                        <mat-label class="field-label-size">Unit Multiplier</mat-label>
                                                        <input matInput formControlName="unitMultiplier" class="field-size" placeholder="Unit Multiplier" maxlength="30">
                                                        <!-- [(ngModel)]="nativeToken.unitMultiplier" -->
                                                        <mat-error *ngIf="nativeToken.get('unitMultiplier')?.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
                                                        <mat-error *ngIf="!nativeToken.get('unitMultiplier')?.hasError('required') && nativeToken.get('unitMultiplier')?.errors">This field accepts
                                                            numeric
                                                            values only</mat-error>

                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <!-- <br> -->
                                            <h3 class="section-title pt-1">External Indentifiers</h3>
                                            <hr>
                                            <div class="row pt-50">
                                                <div class="col">
                                                    <mat-checkbox (change)="onChangeExternalIdentifiers($event, nativeTokenIndex)" formControlName="hasExternalIdentifier" color="primary">
                                                        <!-- [(ngModel)]="nativeToken.hasExternalIdentifier" -->
                                                        Does the token have any identifiers, external to this standard, that may be used to identify either the digital token or the underlying asset
                                                        that
                                                        this digital token represents?
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row border p mt-1" *ngIf="nativeToken.get('hasExternalIdentifier')?.value">
                                                <div class="col" formArrayName="underlyingAssets">
                                                    <div class="row" *ngFor="let underlyingAssetControl of getUnderlyingAssets(nativeTokenIndex).controls; let underlyingAssetIndex = index"
                                                        [formGroupName]="underlyingAssetIndex">
                                                        <div class="col  pr-50 pt-50">
                                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                                                <mat-label class="field-label-size">Underlying Asset External Identifiers Type</mat-label>
                                                                <mat-select class="field-size" placeholder="Select" formControlName="type"
                                                                    (selectionChange)="onSelectExternalIdentifiers(nativeTokenIndex, underlyingAssetIndex, true)">
                                                                    <mat-option *ngFor="let option of underlyingAssetExternalIdentifierTypesOptions" [value]="option.value">{{option.text}}</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                            <!-- <app-multiple-dropdown-field [selections]="nativeToken.underlyingAssetExternalIdentifierTypes"
                                                                [options]="underlyingAssetExternalIdentifierTypesOptions" [label]="'Underlying Asset External Identifiers Type'">
                                                            </app-multiple-dropdown-field> -->
                                                        </div>
                                                        <div class="col pl-50 pt-50">
                                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                                                <mat-label class="field-label-size">Underlying Asset External Identifiers Value</mat-label>
                                                                <input matInput class="field-size" formControlName="value" placeholder="Underlying Asset External Identifiers Value" maxlength="48" />
                                                                <mat-error *ngIf="underlyingAssetControl.get('value')?.hasError('required')">This field is required</mat-error>
                                                                <mat-error *ngIf="!underlyingAssetControl.get('value')?.hasError('required') && underlyingAssetControl.get('value')?.errors">This field
                                                                    accepts alphanumetic characters.</mat-error>
                                                            </mat-form-field>
                                                            <!-- <app-multiple-input-field [values]="nativeToken.underlyingAssetExternalIdentifierValues" [label]="'Underlying Asset External Identifiers Value'"
                                                                [placeholder]="'Underlying Asset External Identifiers Value'">
                                                            </app-multiple-input-field> -->
                                                        </div>
                                                        <div class="col pt-1 text-center flex-grow-0">
                                                            <i (click)="deleteUnderlyingAsset(nativeTokenIndex, underlyingAssetIndex)"
                                                                class="material-symbols-outlined material-icons-font material-icons">
                                                                delete
                                                            </i>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col text-center">
                                                            <i class="primary-color material-symbols-outlined material-icons-font material-icons"
                                                                *ngIf="(nativeToken.get('hasExternalIdentifier')?.value && getUnderlyingAssets(nativeTokenIndex).length < 5)"
                                                                (click)="addUnderlyingAsset(nativeTokenIndex)">
                                                                add
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row border p mt-1" *ngIf="nativeToken.get('hasExternalIdentifier')?.value">
                                                <div class="col" formArrayName="externalIdentifiers">
                                                    <div class="row" *ngFor="let externalIdentifier of getExternalIdentifiers(nativeTokenIndex).controls; let externalIdentifierIndex = index"
                                                        [formGroupName]="externalIdentifierIndex">
                                                        <div class="col  pr-50 pt-50">
                                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                                                <mat-label class="field-label-size">Digital Token External Identifiers Type</mat-label>
                                                                <mat-select class="field-size" placeholder="Select" formControlName="type"
                                                                    (selectionChange)="onSelectExternalIdentifiers(nativeTokenIndex, externalIdentifierIndex)">
                                                                    <mat-option *ngFor="let option of digitalTokenExternalIdentifiersTypesOptions" [value]="option.value">{{option.text}}</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col pl-50 pt-50">
                                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                                                <mat-label class="field-label-size">Digital Token External Identifiers Value</mat-label>
                                                                <input matInput class="field-size" formControlName="value" placeholder="Digital Token External Identifiers Value" maxlength="48" />
                                                                <mat-error *ngIf="externalIdentifier.get('value')?.hasError('required')">This field is required</mat-error>
                                                                <mat-error *ngIf="!externalIdentifier.get('value')?.hasError('required') && externalIdentifier.get('value')?.errors">This field accepts
                                                                    alphanumetic characters.</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col flex-grow-0 pt-1">
                                                            <i (click)="deleteExternalIdentifier(nativeTokenIndex, externalIdentifierIndex)"
                                                                class="material-symbols-outlined material-icons-font material-icons">
                                                                delete
                                                            </i>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col text-center">
                                                            <i class="primary-color material-symbols-outlined material-icons-font material-icons" (click)="addExternalIdentifier(nativeTokenIndex)"
                                                                *ngIf="getExternalIdentifiers(nativeTokenIndex).length < 5">
                                                                add
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="row border p mt-1" *ngIf="nativeToken.hasExternalIdentifier">
                                                <div class="col">
                                                    <app-multiple-dropdown-field [options]="digitalTokenExternalIdentifiersTypesOptions" [selections]="nativeToken.digitalTokenExternalIdentifiersTypes"
                                                        [label]="'Digital Token External Identifiers Type'">
                                                    </app-multiple-dropdown-field> 
                                                </div>
                                                <div class="col">
                                                    <app-multiple-input-field [values]="nativeToken.digitalTokenExternalIdentifiersValues" [label]="'Digital Token External Identifiers Value'"
                                                        [placeholder]="'Digital Token External Identifiers Value'">
                                                    </app-multiple-input-field>
                                                </div>
                                            </div> -->
                                        </div>
                                    </mat-tab>
                                    <mat-tab disabled *ngIf="nativeTokens.length < MAXIMUM_TAB">
                                        <ng-template mat-tab-label>
                                            <button mat-icon-button color="primary" (click)="addNativeTokenTab()">
                                                <mat-icon class="native-tab-add-icon material-symbols-outlined material-icons-font material-icons">add</mat-icon>
                                            </button>
                                        </ng-template>
                                    </mat-tab>
                                </ng-container>
                            </form>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </mat-step>
        <!-- Digital Token Creation Fork -->
        <mat-step *ngIf="options.hasDigitalTokenCreationFork">
            <ng-template matStepLabel>
                <p> Digital Token Creation Fork</p>
            </ng-template>
            <div class="flex-column container container-step">
                <div class="row">
                    <div class="col">
                        <label style="font-weight: 800;">Bitcoin SV</label>
                        <br>
                    </div>
                    <div class="col">
                        <label>Native Digital Token</label> | <label>Blockchain</label>
                    </div>
                </div>
                <div class="row">
                    <p>Please enter the following details for Digital Token Creation Fork</p>
                </div>
                <div class="row">
                    <div class="col">
                        <button mat-raised-button class="example-add-tab-button" (click)="addForkTab()" *ngIf="tokenForks.length < MAXIMUM_TAB">
                            Add Fork Tab
                        </button>
                        <mat-tab-group animationDuration="0ms" class="allow-tab-events" [selectedIndex]="tokenForkSelectedTab" mat-stretch-tabs="false" mat-align-tabs="start">
                            <ng-container *ngFor="let fork of tokenForks; let index = index">
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <label for="tab">Fork {{index + 1}}</label>
                                        <i *ngIf="tokenForks.length > 1" (click)=" removeTokenForksTab($event, index)" class="material-symbols-outlined material-icons-font material-icons">
                                            close
                                        </i>
                                    </ng-template>
                                    <div class="tab-content">
                                        <div class="row">
                                            <div class="col">
                                                <mat-form-field color="primary" appearance="outline" floatLabel="always" class="example-full-width">
                                                    <mat-label class="field-label-size">Fork Block Height *</mat-label>
                                                    <input matInput class="field-size" placeholder="Fork Block Hash" value="">
                                                </mat-form-field>
                                            </div>
                                            <div class="col"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <mat-form-field color="primary" appearance="outline" floatLabel="always">
                                                    <mat-label class="field-label-size">Fork Block UTC Timestamp</mat-label>
                                                    <input class="field-size" matInput placeholder="Fork Block UTC Timestamp" [matDatepicker]="picker">
                                                    <mat-datepicker-toggle class="field-size" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker class="field-size" #picker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                            <div class="col"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <mat-form-field color="primary" appearance="outline" floatLabel="always">
                                                    <mat-label class="field-label-size">Fork Block Hash Algorithm *</mat-label>
                                                    <mat-select class="field-size" placeholder="Select">
                                                        <mat-option value="cusip">Option 1</mat-option>
                                                        <mat-option value="sedol">Option 2</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <mat-form-field color="primary" appearance="outline" floatLabel="always" class="example-full-width">
                                                    <mat-label class="field-label-size">Fork Block Hash *</mat-label>
                                                    <input matInput class="field-size" placeholder="Fork Block Hash">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <mat-checkbox color="primary">
                                                    <p>Consensus Mechanism Change</p>
                                                </mat-checkbox>
                                                <mat-checkbox color="primary">
                                                    <p>Digital Token Creation Response</p>
                                                </mat-checkbox>
                                                <br>
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                            </ng-container>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </mat-step>
        <!-- Auxiliary Token -->
        <mat-step [stepControl]="auxiliaryFG" [editable]="!isTokenRequestSuccess" *ngIf="options.hasAuxiliaryToken">
            <ng-template matStepLabel>
                <p> Auxiliary Token Creation </p>
            </ng-template>
            <div class="auxiliary-container flex-column container container-step">
                <div class="row">
                    <div class="col">
                        <label class="long-name">{{(informativeData).longName}}</label>
                    </div>
                    <div class="col text-right">
                        <label class="dlt-label">{{(normativeData).dlt}}</label>
                    </div>
                </div>
                <div class="details-container row">
                    <div class="col">
                        <p class="tab-header-details">Please enter the following details for Auxiliary Token:</p>
                    </div>
                </div>
                <div class="content-container row">
                    <div class="col">
                        <!-- <button mat-raised-button class="example-add-tab-button" (click)="addAuxiliaryTab()" *ngIf="auxiliaryTokens.length < 3">
                            Add Auxiliary Token
                        </button> -->
                        <mat-tab-group [color]="'primary'" [selectedIndex]="auxiliarySelectedTab" animationDuration="0ms" class="allow-tab-events" mat-stretch-tabs="false" mat-align-tabs="start">
                            <form [formGroup]="auxiliaryFG">
                                <ng-container formArrayName="auxiliaryMechanisms">
                                    <mat-tab *ngFor="let auxiliary of auxiliaries.controls; let index = index" [formGroupName]="index">
                                        <ng-template mat-tab-label>
                                            <label for="auxiliary">Auxiliary Token {{index + 1}}</label>
                                            <i *ngIf="auxiliaries.length > 1" (click)=" removeAuxiliaryTab($event, index)" class="material-symbols-outlined material-icons-font material-icons">
                                                close
                                            </i>
                                        </ng-template>
                                        <div class="tab-content pt-1-50">
                                            <div class="row pt-1">
                                                <div class="col flex-grow-2">
                                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                                        <mat-label class="field-label-size">Auxiliary Mechanism</mat-label>
                                                        <input formControlName="auxiliaryMechanism" matInput class="field-size" placeholder="Auxiliary Mechanism" maxlength="20" required>
                                                        <mat-error *ngIf="auxiliary.get('auxiliaryMechanism')?.hasError('required')">This field is required.</mat-error>
                                                        <mat-error
                                                            *ngIf="!auxiliary.get('auxiliaryMechanism')?.hasError('required') && auxiliary.get('auxiliaryMechanism')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_VALID_SYMBOL_UTF_8_ERROR_MESSAGE}}</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col"></div>
                                            </div>
                                            <div class="row pt-1">
                                                <div class="col flex-grow-3">
                                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="text-area">
                                                        <mat-label class="field-label-size">Auxiliary Mechanism Description</mat-label>
                                                        <textarea formControlName="auxiliaryMechanismDesc" matInput class="field-size" placeholder="Auxiliary Mechanism Description" cdkTextareaAutosize
                                                            cdkAutosizeMinRows="5" maxlength="400"></textarea>
                                                        <mat-error *ngIf="auxiliary.get('auxiliaryMechanismDesc')?.hasError('required')">This field is required</mat-error>
                                                        <mat-error
                                                            *ngIf="!auxiliary.get('auxiliaryMechanismDesc')?.hasError('required') && auxiliary.get('auxiliaryMechanismDesc')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_VALID_SYMBOL_UTF_8_ERROR_MESSAGE}}</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col pt-2 flex-grow-0">
                                                    <button mat-icon-button color="primary" [matTooltipPosition]="'right'" matTooltip="This is the description of Auxiliary Mechanism Description">
                                                        <mat-icon class="material-symbols-outlined material-icons-font material-icons">help</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row pt-1">
                                                <div class="col flex-grow-3">
                                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="text-area">
                                                        <mat-label class="field-label-size">Auxiliary Digital Token Technical Reference</mat-label>
                                                        <textarea formControlName="auxiliaryMechanismTechRef" matInput class="field-size" placeholder="Auxiliary Digital Token Technical Reference"
                                                            cdkTextareaAutosize cdkAutosizeMinRows="5" maxlength="400"></textarea>
                                                        <mat-error *ngIf="auxiliary.get('auxiliaryMechanismTechRef')?.hasError('required')">This field is required.
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="!auxiliary.get('auxiliaryMechanismTechRef')?.hasError('required') && auxiliary.get('auxiliaryMechanismTechRef')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_VALID_SYMBOL_UTF_8_ERROR_MESSAGE}}</mat-error>
                                                    </mat-form-field>
                                                    <!-- [(ngModel)]="auxiliary.auxiliaryDigitalTokenTechnicalReference" -->
                                                </div>
                                                <div class="col pt-2 flex-grow-0">
                                                    <button mat-icon-button color="primary" [matTooltipPosition]="'right'"
                                                        matTooltip="This is the description of Auxiliary Digital Token Technical Reference">
                                                        <mat-icon class="material-symbols-outlined material-icons-font material-icons">help</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row pt-1">
                                                <div class="col flex-grow-3">
                                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="text-area">
                                                        <mat-label class="field-label-size">Auxiliary Digital Token Verification Details</mat-label>
                                                        <textarea formControlName="auxiliaryMechanismVerificationDetail" matInput class="field-size"
                                                            placeholder="Auxiliary Digital Token Verification Details" cdkTextareaAutosize cdkAutosizeMinRows="5" maxlength="800"></textarea>
                                                        <mat-error *ngIf="auxiliary.get('auxiliaryMechanismVerificationDetail')?.hasError('required')">This field is required.
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="!auxiliary.get('auxiliaryMechanismVerificationDetail')?.hasError('required') && auxiliary.get('auxiliaryMechanismVerificationDetail')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_VALID_SYMBOL_UTF_8_ERROR_MESSAGE}}</mat-error>

                                                    </mat-form-field>
                                                    <!-- [(ngModel)]="auxiliary.auxiliaryDigitalTokenVerificationDetails" -->
                                                </div>
                                                <div class="col pt-2 flex-grow-0">
                                                    <button mat-icon-button color="primary" [matTooltipPosition]="'right'"
                                                        matTooltip="This is the description of Auxiliary Digital Token Verification Details">
                                                        <mat-icon class="material-symbols-outlined material-icons-font material-icons">help</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>

                                    <mat-tab disabled *ngIf="auxiliaries.length < 3" class="plus">
                                        <ng-template mat-tab-label>
                                            <button mat-icon-button color="primary" (click)="addAuxiliaryTab()">
                                                <mat-icon class="native-tab-add-icon material-symbols-outlined material-icons-font material-icons">add</mat-icon>
                                            </button>
                                        </ng-template>
                                    </mat-tab>

                                </ng-container>
                            </form>
                        </mat-tab-group>
                    </div>
                </div>

            </div>
        </mat-step>
        <!-- Submission Tab -->
        <mat-step [completed]="isTokenRequestSuccess" [editable]="!isTokenRequestSuccess" [state]="isTokenRequestSuccess ? 'done':''">
            <ng-template matStepLabel>
                <label> Review & Submit </label>
            </ng-template>

            <div *ngIf="!isTokenRequestSuccess" class="flex-column container container-step submit-container">
                <div class="row">
                    <div class="col">
                        <label class="long-name">{{(informativeData).longName}}</label><br>
                        <label class="native-digital-token">Native Digital Token</label> | <label class="dlt">({{(normativeData).dlt}})</label>
                    </div>
                </div>
                <div class="row section">
                    <div class="col">
                        <div class="row header">
                            <div class="col">
                                <label class="section-title">Issuer Details</label>
                            </div>
                            <div class="col text-right">
                                <i class="edit-icon material-symbols-outlined material-icons-font material-icons">
                                    mode_edit_outline
                                </i>
                            </div>
                        </div>
                        <hr class="section-header-divider">
                        <p> Are you the Creator/Initiator/Maintainer of the DLT? <b>{{options.isCreator ? 'Yes' : 'No'}}</b> </p>
                        <p *ngIf="options.isCreator && options.hasLei && options.lei"> Legal Entity Identifier (LEI): <b>{{options.lei}}</b></p>
                        <p *ngIf="options.isCreator && !options.hasLei"> Company: <b>{{options.companyName}}</b></p>
                        <p *ngIf="options.isCreator && !options.hasLei"> Business Identifier Code: <b>{{options.businessIdentifierCode}}</b></p>
                    </div>
                </div>
                <div class="row section">
                    <div class="col">
                        <div class="row header">
                            <div class="col">
                                <label class="section-title">Normative Attributes</label>
                            </div>
                            <div class="col text-right">
                                <i (click)="stepper.selectedIndex = 0" class="edit-icon material-symbols-outlined material-icons-font material-icons">
                                    mode_edit_outline
                                </i>
                            </div>
                        </div>
                        <hr class="section-header-divider">
                        <p *ngIf="isDLTBlockchain"> Genesis Block Hash Algorithm: <b>{{(normativeData)?.gbHashAlgo}}</b> </p>
                        <p *ngIf="isDLTBlockchain"> Genesis Block Hash: <b>{{(normativeData)?.gbHash}}</b></p>
                        <p class="row">
                            <span class="col">Provisional: <b>{{(normativeData).isProvisional ? 'Yes': 'No'}}</b> </span>
                            <span class="comma-delimited" *ngIf="(normativeData).isProvisional">Date to be activated: <b>{{(normativeData).activationDate | date:'dd/MM/yyyy'}}</b></span>
                        </p>
                        <p *ngIf="isDLTBlockchain"> Genesis Block UTC Timestamp: <b>{{(normativeData)?.gbUtcTimeStamp | date:'dd/MM/yyyy HH:mm:ss'}}</b></p>
                    </div>
                </div>
                <div class="row section">
                    <div class="col ">
                        <div class="row header">
                            <div class="col">
                                <label class="section-title">Informative Attributes</label>
                            </div>
                            <div class="col text-right">
                                <i (click)="stepper.selectedIndex = 1" class="edit-icon material-symbols-outlined material-icons-font material-icons">
                                    mode_edit_outline
                                </i>
                            </div>
                        </div>
                        <hr class="section-header-divider">
                        <p> Longname: <b>{{(informativeData).longName}}</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span *ngIf="hasOLLN">Original Languange Long Name
                                <b>{{(informativeData).origLangLongName}}</b></span></p>
                        <p>Token Reference URL: <b>{{(informativeData).tokenRefUrl}}</b></p>
                        <p>Public Distributed Ledger Indicator: &emsp; &emsp; <b>{{(informativeData).publicDistLedgerIndicator}}</b></p>
                        <!-- <p> Shortname: <b>{{nativeTokens[0].shortName}}</b>  &emsp; &emsp; Original Languange Short Name <b>{{nativeTokens[0]?.originalLangaugeShortName}}</b></p> -->
                    </div>
                </div>
                <div class="row section" *ngIf="options.hasNativeToken">
                    <div class="col ">
                        <div class="row header">
                            <div class="col">
                                <label class="section-title">Native Tokens</label>
                            </div>
                            <div class="col text-right">
                                <i (click)="stepper.selectedIndex = 2" class="edit-icon material-symbols-outlined material-icons-font material-icons">
                                    mode_edit_outline
                                </i>
                            </div>
                        </div>

                        <hr class="section-header-divider">
                        <div class="row p" *ngFor="let nativeToken of nativeTokens.controls let i = index">
                            <div class="col">
                                <fieldset class="border">
                                    <legend>
                                        <p><b>Native Token {{i+1}}</b></p>
                                    </legend>
                                    <p class="row">
                                        <span class="col">Short Name(s) : <b>{{joinList(nativeToken.get('shortNames')?.value,'shortName')}}</b></span>
                                        <span class="col" *ngIf="nativeToken.get('hasExternalIdentifier')?.value">Original Language Short Name :
                                            <b>{{joinList(nativeToken.get('origlangShortNames')?.value, 'origlangShortName')}}</b></span>
                                    </p>
                                    <p class="row">
                                        <span class="col">Unit Multiplier : <b>{{nativeToken.get('unitMultiplier')?.value}}</b></span>
                                    </p>
                                    <p class="row" *ngIf="nativeToken.get('hasExternalIdentifier')?.value">
                                        <span class="col">Underlying Asset External Identifiers Type: <br><b>{{joinList(nativeToken.get('underlyingAssets')?.value, 'type')}}</b></span>
                                        <span class="col">Underlying Asset External Identifiers Value: <br><b>{{joinList(nativeToken.get('underlyingAssets')?.value, 'value')}}</b></span>
                                    </p>
                                    <p class="row" *ngIf="nativeToken.get('hasExternalIdentifier')?.value">
                                        <span class="col">Digital Token External Identifiers Type: <br><b>{{joinList(nativeToken.get('externalIdentifiers')?.value, 'type')}}</b></span>
                                        <span class="col">Digital Token External Identifiers Value: <br><b>{{joinList(nativeToken.get('externalIdentifiers')?.value, 'value')}}</b></span>
                                    </p>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row section" *ngIf="options.hasAuxiliaryToken">
                    <div class="col">
                        <div class="row header">
                            <div class="col">
                                <label class="section-title">Auxiliary Tokens</label>
                            </div>
                            <div class="col text-right">
                                <i (click)=" options.hasNativeToken ? stepper.selectedIndex = 3 : stepper.selectedIndex = 2"
                                    class="edit-icon material-symbols-outlined material-icons-font material-icons">
                                    mode_edit_outline
                                </i>
                            </div>
                        </div>
                        <hr class="section-header-divider">
                        <div class="row" *ngFor="let auxiliary of auxiliaries.controls let index = index">
                            <div class="col">
                                <fieldset>
                                    <legend>
                                        <p><b>Auxiliary Token {{index+1}}</b></p>
                                    </legend>
                                    <p>Auxiliary Mechanism : <b>{{auxiliary?.get('auxiliaryMechanism')?.value}}</b></p>
                                    <p>Auxiliary Mechanism Description : <b>{{auxiliary?.get('auxiliaryMechanismDesc')?.value}}</b></p>
                                    <p>Auxiliary Digital Token Technical Reference : <b>{{auxiliary?.get('auxiliaryMechanismTechRef')?.value}}</b></p>
                                    <p>Auxiliary Digital Token Verification Details : <b>{{auxiliary?.get('auxiliaryMechanismVerificationDetail')?.value}}</b></p>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row section">
                    <div class="col">
                        <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="text-area">
                            <mat-label class="field-label-size">Additional Informations</mat-label>
                            <textarea matInput [(ngModel)]="additionalInfo" class="field-size" placeholder="Additional Informations" cdkTextareaAutosize cdkAutosizeMinRows="5"
                                maxlength="400"></textarea>
                        </mat-form-field>
                        <mat-checkbox color="primary" class="cb-items">
                            I have read and agree to DTIF.org Terms and Conditions.
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div *ngIf="isTokenRequestSuccess" class="row container container-step submitted-container">
                <div class="col content flex-column">
                    <i class="check-circle material-symbols-outlined material-icons-font material-icons">
                        check_circle
                    </i>
                    <div class="row flex-column">
                        <p class="col submit-message">New token has been successfully submitted!</p>
                        <p class="col ticket-id">Application ID: {{applicationId}}</p>
                    </div>
                    <div class="row flex-column text-center token-details">
                        <label class="col token-name"><b>{{(informativeData).longName}}</b></label>
                        <label class="col token-type"><b>Native Digital Token</b> | ({{(normativeData).dlt}})</label>
                    </div>
                    <a routerLink="/" mat-raised-button disableRipple="true" color="accent" class="submit-new-token-link">Submit a New Token</a>
                </div>
            </div>

        </mat-step>
    </mat-horizontal-stepper>
</div>