<div class="close-button-container">
    <button mat-icon-button class="close-button " mat-dialog-close tabindex="-1">
        <mat-icon class="close-icon material-icons-font material-icons">close</mat-icon>
    </button>
</div>
<div class="title-container">
    <h1 class="title"><strong>DTI Allocation Request</strong></h1>
    <br />
    <p class="sub-title"><strong>Terms and Conditions</strong> </p>
</div>
<mat-dialog-content class="content" (scroll)="onScroll($event)" id="content-dialog">
    <p class="sub-title"><strong>Introduction</strong></p>
    <br />
    <p class="details text-justify">
        By clicking to accept these Terms and Conditions (the “<strong>Terms</strong>”) and in consideration for the mutual
        promises and obligations of the Parties set out herein, a binding contract based on these Terms will
        be formed between You (as defined below) and the Digital Token Identifier Foundation (being a
        division of Etrading Software Limited, registered as a private limited company in England and Wales
        with registered company number 05237988 and registered address at First Floor, 6 Dowgate Hill,
        London, England, EC4R 2SU) ("<strong>DTIF</strong>"). The contract will govern Your submission of a request (the
        "<strong>DTI Request</strong>") for DTIF to assign and register (i.e., to "<strong>Allocate</strong>") a random, unique and fixed-length
        digital token identifier (a "<strong>DTI</strong>") for a Digital Token which may or may not have already been issued
        and distributed within the public domain. Subject to (i) the DTI Request meeting the requirements
        of the applicable validation process and (ii) payment by You of the applicable fees (in each case in
        accordance with these Terms), the DTI will be Allocated. For the purposes of these Terms, "<strong>Digital
            Token</strong>" shall mean the digital token which is the subject of the DTI Request and "<strong>ISO 24165</strong>" shall
        mean, together, International Standard ISO 24165-1 and International Standard ISO 24165-2.
    </p>
    <p class="details text-justify">
        Where a person submits the DTI Request in their own personal capacity, "<strong>You</strong>" shall mean the
        person submitting the DTI Request (and "<strong>Your</strong>" shall be construed accordingly). Where a person
        submits the DTI Request for and on behalf of an organisation or entity, "<strong>You</strong>" and "<strong>Your</strong>" shall refer
        to such organisation or entity and the person clicking to accept these Terms warrants that they are
        authorised to agree to these Terms on behalf of such organisation or entity and bind such
        organisation or entity to these Terms. You and DTIF are each a "<strong>Party</strong>" and together, the "<strong>Parties</strong>".
    </p>
    <p class="details text-justify">
        Please note that these Terms shall only govern Your submission of, and DTIF's analysis and
        associated activities in respect of, the DTI Request. Your access to and use of DTIF's website at
        <a href="https://dtif.org/" target="_blank">https://dtif.org/</a> (the "<strong>DTIF Website</strong>" and including, without limitation, the DTI registry at
        <a href="https://dtif.org/registry-search/" target="_blank">https://dtif.org/registry-search/</a>) is governed by DTIF's <i>Website and Registry Terms and Conditions</i>
        accessible at: <a href="https://dtif.org/terms-and-conditions/" target="_blank">https://dtif.org/terms-and-conditions/</a>.
    </p>
    <p class="details text-justify">
        If you are submitting the DTI Request in Your own personal capacity (and not on behalf of an
        organisation or entity) then you have a right to cancel the request by notifying DTIF at
        <a href="mailto:secretariat@dtif.org" target="_blank">secretariat@dtif.org</a>. This cancellation right is subject to clause 2(d) below and cancellation must
        occur no later than the end of the 30 day payment period referred to in clause 2(c) below. Please
        note that, in submitting a DTI Request, You are expressly requesting DTIF to perform services on
        Your behalf by virtue of the Validation Process (as defined below).
    </p>
    <br />
    <p class="row">
        <span class="col item-order"><strong>1.</strong></span>
        <span class="col item-details"><strong>SUBMITTING THE DTI REQUEST</strong></span>
    </p>
    <p class="row">
        <span class="col item-order">(a)</span>
        <span class="col item-details">
            Notwithstanding that there is no requirement for You to own any rights in the Digital Token in order to submit the DTI Request, You nevertheless warrant to DTIF that:
            <br /> <br />
            <p class="row">
                <span class="col item-order">(i)</span>
                <span class="col item-details ">
                    You have the full right, power, authority and capacity to enter into and
                    perform Your obligations under these Terms and to grant the rights purported
                    to be granted to DTIF under these Terms;
                </span>
            </p>
            <p class="row">
                <span class="col item-order">(ii)</span>
                <span class="col item-details">
                    the account from which You are making the DTI Request has been registered
                    in Your name and using Your contact details;
                </span>
            </p>
            <p class="row">
                <span class="col item-order">(iii)</span>
                <span class="col item-details">
                    all information (whether technical or descriptive in nature) that You have
                    provided and/or will provide to DTIF in respect of the Digital Token (the
                    "<strong>Digital Token Information</strong>") is true, complete, accurate and up-to-date and
                    You have the full right, power, authority and capacity to provide DTIF with all
                    such Digital Token Information; and
                </span>
            </p>
            <p class="row">
                <span class="col item-order">(iv)</span>
                <span class="col item-details">
                    You are submitting the DTI Request and Digital Token Information in good
                    faith, without any intention of misleading DTIF or any other person,
                    organisation or entity.
                </span>
            </p>
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(b)</span>
        <span class="col item-details">
            You shall indemnify DTIF, its directors, officers, agents and employees in respect of all
            damages, loss, costs, claims or expenses of any kind suffered or incurred or paid by DTIF
            in connection with Your breach of any of the warranties at clause 1(a).
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(c)</span>
        <span class="col item-details">
            To the extent that You own any Digital Token Information, You hereby grant to DTIF a
            perpetual, worldwide, non-exclusive, royalty-free, transferable licence to use such Digital
            Token Information for the purposes of: (i) analysing, responding to and/or actioning the
            DTI Request; (ii) producing and publishing certain technical and descriptive data
            concerning the Digital Token on any website and/or registry which is owned or otherwise
            within the control of DTIF; and (iii) carrying out internal analytics.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(d)</span>
        <span class="col item-details">
            You hereby grant to DTIF a perpetual, worldwide, non-exclusive, royalty-free, transferable
            licence to use all contact information which relates to You and which You have provided
            and/or will provide to DTIF for the sole purpose of contacting You in connection with the
            DTI Request and/or otherwise validating the DTI Request. For the avoidance of doubt, DTIF
            will not knowingly permit such contact information to be published on any website and/or
            public register.
        </span>
    </p>
    <br />
    <p class="row">
        <span class="col item-order"><strong>2.</strong></span>
        <span class="col item-details"><strong>PAYMENT</strong></span>
    </p>
    <p class="row">
        <span class="col item-order">(a)</span>
        <span class="col item-details">
            At any time following its receipt of a DTI Request (whether before, during or after the
            Validation Process), DTIF shall issue an invoice to You for the per-DTI fee which is displayed
            <a href="https://dtif.org/fee-model/" target="_blank">https://dtif.org/fee-model/</a>. The following should be noted:
            <br /> <br />
            <p class="row">
                <span class="col item-order">(i)</span>
                <span class="col item-details">
                    The fees stated <a href="https://dtif.org/fee-model/" target="_blank">https://dtif.org/fee-model/</a> are liable to change at any time
                    but, save as set out in (ii) below, price changes will not affect DTI Requests
                    which have already been submitted.
                </span>
            </p>
            <p class="row">
                <span class="col item-order">(ii)</span>
                <span class="col item-details">
                    The price quoted <a href="https://dtif.org/fee-model/" target="_blank">https://dtif.org/fee-model/</a> is exclusive of VAT or any other
                    sales tax (unless stated otherwise) which may be payable by You, and which
                    may be added to the invoice.
                </span>
            </p>
            <p class="row">
                <span class="col item-order">(iii)</span>
                <span class="col item-details">
                    If there is an obvious pricing error stated <a href="https://dtif.org/fee-model/" target="_blank">https://dtif.org/fee-model/</a> then it
                    will not apply to Your DTI Request and DTIF will notify You of the correct fee.
                </span>
            </p>
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(b)</span>
        <span class="col item-details">
            Payment shall be made by You:
            <br /> <br />
            <p class="row">
                <span class="col item-order">(i)</span>
                <span class="col item-details">
                    in euros to the DTIF bank account specified on the invoice; and
                </span>
            </p>
            <p class="row">
                <span class="col item-order">(ii)</span>
                <span class="col item-details">
                    within 30 days of the date of the invoice.
                </span>
            </p>
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(c)</span>
        <span class="col item-details">
            If payment is not made in full within 30 days of the invoice date then DTIF reserves the
            right at its sole discretion to notify You that it is cancelling the applicable DTI Request
            process.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(d)</span>
        <span class="col item-details">
            If any payment has already been made by You for a DTI Request which has either been
            cancelled by DTIF or You (including by way of termination under clause 6(b)) or in respect
            of which DTIF has decided not to Allocate a DTI (in each case in accordance with these
            Terms), DTIF shall return all sums which have been paid by You. However, this is subject
            to DTIF's entitlement to retain a portion of the fees paid by way of fair and reasonable
            recompense for DTIF's efforts in performing the Validation Process (if applicable).
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>3.</strong></span>
        <span class="col item-details"><strong>DTI ALLOCATION</strong></span>
    </p>
    <p class="row">
        <span class="col item-order">(a)</span>
        <span class="col item-details">
            DTIF shall review the DTI Request and all accompanying Digital Token Information which
            it receives from You in order to: (i) check whether a DTI has already been Allocated for the
            Digital Token; (ii) consider whether the DTI Request conforms to all applicable application
            guidelines; and (iii) analyse the Digital Token and consider whether (in its sole discretion,
            taking into account all Digital Token Information) a DTI may be Allocated for the Digital
            Token in accordance with ISO 24165 (the "<strong>Validation Process</strong>").
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(b)</span>
        <span class="col item-details">
            If DTIF reasonably considers that it has not received sufficient information from You in
            order to conduct and/or conclude the Validation Process, DTIF may contact You with a
            request for further information. DTIF shall only commence the Validation Process once it
            reasonably considers that it has received sufficient information from You (which shall be
            deemed Digital Token Information) in order to conduct and conclude the Validation
            Process. Without prejudice to the foregoing, where the DTI Request is submitted prior to
            the issuance and distribution of the Digital Token (in which case DTIF may not have access
            to all Digital Token Information required to complete the Validation Process), DTIF may (at
            its sole discretion) issue a provisional DTI in respect of the Digital Token until such time as
            it is able to complete the Validation Process.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(c)</span>
        <span class="col item-details">
            If DTIF considers (in its sole discretion, taking into account all Digital Token Information) at
            the conclusion of the Validation Process (and having received payment in full from You)
            that a DTI may be Allocated for the Digital Token in accordance with ISO 24165 then (unless
            any other facts or circumstances prevent it from doing so) DTIF shall (without undue
            delay): (i) notify You of this fact; (ii) Allocate a DTI in respect of the Digital Token; and (iii)
            add details of the Digital Token (including, without limitation, certain technical and
            descriptive data concerning the Digital Token) and the DTI to its publicly available registry
            of DTIs.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(d)</span>
        <span class="col item-details">
            If DTIF considers (in its sole discretion, taking into account all Digital Token Information) at
            the conclusion of the Validation Process that a DTI should not be Allocated for the Digital
            Token in accordance with ISO 24165 (or if it has any other reasonable ground to withhold
            the Allocation of a DTI) then DTIF shall (without undue delay) notify You of this fact, but
            (subject to clause 2(d)) DTIF shall be under no obligation to take any further action in
            respect of the DTI Request. Without prejudice to the foregoing, nothing in these Terms
            shall prevent You from submitting a new DTI Request in respect of the same Digital Token,
            provided that You submit to DTIF Digital Token Information which is substantially new
            and/or different to the Digital Token Information which was submitted as part of the
            previous DTI Request (and provided that a DTI will only be Allocated for the Digital Token
            where You pay for the DTI in accordance with the terms of clause 2).
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(e)</span>
        <span class="col item-details">
            The basic number of a DTI is randomly generated and intended to be semantically
            meaningless. You shall notify DTIF if you become aware that the basic number of the DTI
            Allocated to the Digital Token is semantically meaningful in any language.
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>4.</strong></span>
        <span class="col item-details"><strong>THE DIGITAL TOKEN AND DTI</strong></span>
    </p>
    <p class="row">
        <span class="col item-order">(a)</span>
        <span class="col item-details">
            The Parties acknowledge and agree that a DTI is Allocated to the Digital Token and not to
            You and as such, the Allocation of a DTI in respect of the Digital Token and/or DTIF's
            inclusion of the DTI within the DTI registry signifies only the existence of the Digital Token
            and its 1:1 relationship to the DTI. Nothing in these Terms operates to grant and/or
            transfer to You any right, title or interest in and to any Digital Token and/or DTI.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(b)</span>
        <span class="col item-details">
            Without prejudice to the generality of clause 5(d), the Parties acknowledge and agree that
            notwithstanding DTIF's Allocation of a DTI in respect of the Digital Token and/or DTIF's
            inclusion of the DTI within the DTI registry, neither DTIF nor any of its employees,
            mandatees and/or other representatives (the "<strong>DTIF Parties</strong>") endorse any Digital Token in
            any way and no warranties are provided as to the features, functions, legal status,
            suitability for investment, or regulatory status of any Digital Token.
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>5.</strong></span>
        <span class="col item-details"><strong>LIABILITY</strong></span>
    </p>
    <p class="row">
        <span class="col item-order">(a)</span>
        <span class="col item-details">
            Subject to clauses 5(b), 5(c) and 5(d), the aggregate liability of the DTIF Parties (whether
            such liability arises in contract, tort (including negligence) or otherwise) to You for any
            damages, loss, costs, claims or expenses of any kind arising out of, or in connection with,
            these Terms caused or contributed to by the DTIF Parties shall not exceed the greater of
            (i) US$100 and (ii) fees paid by You under these Terms with respect to the DTI and/or the
            DTI Request to which the DTIF Parties' liability relates.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(b)</span>
        <span class="col item-details">
            Subject to clause 5(c), none of the DTIF Parties shall be liable to You (whether such liability
            arises in contract, tort (including negligence) or otherwise) for any:
            <br /> <br />
            <p class="row">
                <span class="col item-order">(i)</span>
                <span class="col item-details">
                    loss of profit, loss of or damage to data, loss of anticipated savings or interest,
                    loss of or damage to reputation or goodwill;
                </span>
            </p>
            <p class="row">
                <span class="col item-order">(ii)</span>
                <span class="col item-details">
                    any indirect, special or consequential damages, loss, costs, claims or expenses
                    of any kind; and/or
                </span>
            </p>
            <p class="row">
                <span class="col item-order">(iii)</span>
                <span class="col item-details">
                    any loss arising from a failure or delay in performing its obligations under
                    these Terms to the extent that such failure or delay was caused or contributed
                    to by an act or omission of You.
                </span>
            </p>
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(c)</span>
        <span class="col item-details">
            The exclusions and limitations of liability in these Terms shall not apply in respect of any
            damages, loss, costs, claims or expenses of any kind suffered by any person arising out of:
            <br /> <br />
            <p class="row">
                <span class="col item-order">(i)</span>
                <span class="col item-details">
                    the fraud and/or fraudulent misrepresentation of the person seeking to rely
                    on the exclusion or limitation; and
                </span>
            </p>
            <p class="row">
                <span class="col item-order">(ii)</span>
                <span class="col item-details">
                    death or personal injury resulting from negligence on the part of the person
                    seeking to rely on the exclusion or limitation.
                </span>
            </p>
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(d)</span>
        <span class="col item-details">
            Except as otherwise expressly provided in these Terms, all warranties, undertakings or
            other similar terms or conditions implied by statute, common law or custom are excluded
            to the maximum extent permitted by law.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(e)</span>
        <span class="col item-details">
            Without prejudice to the foregoing and to the maximum extent permitted by law, You
            hereby waive your rights to claim damages from the International Organization for
            Standardization and/or any of its employees, mandatees and/or other representatives in
            connection with DTIF's implementation of the services defined in ISO 24165. For the
            avoidance of doubt, the International Organization for Standardization shall remain
            responsible for the contents of ISO 24165.
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>6.</strong></span>
        <span class="col item-details strong"><strong>TERM AND TERMINATION</strong></span>
    </p>
    <p class="row">
        <span class="col item-order">(a)</span>
        <span class="col item-details">
            A contract formed under these Terms shall commence upon You clicking to accept these
            Terms and shall continue until notice has been given under clause 2(c), 3(c) or 3(d) or You
            have cancelled the DTI Request.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(b)</span>
        <span class="col item-details">
            Either Party may terminate a contract formed under these Terms at any time with
            immediate effect by providing written notice to the other Party.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(c)</span>
        <span class="col item-details">
            The expiry or termination of these Terms (howsoever caused) shall be without prejudice
            to: (i) any other rights which either DTIF or You may have under these Terms; (ii) any
            liabilities accrued prior to the date on which the expiry or termination takes effect; and
            (iii) any rights or obligations of a person which are expressly stated to survive, or by their
            nature survive, expiry or termination of these Terms.
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>7.</strong></span>
        <span class="col item-details"> <strong>CONFIDENTIALITY</strong> </span>
    </p>
    <p class="row">
        <span class="col item-order">(a)</span>
        <span class="col item-details">
            In this clause, “<strong>Confidential Information</strong>” means any information received by a Party in
            connection with these Terms which is of a confidential nature.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(b)</span>
        <span class="col item-details">
            The Parties will each hold all Confidential Information in the strictest confidence and,
            except as permitted hereunder, will not disclose it to any person.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(c)</span>
        <span class="col item-details">
            Confidential Information may be disclosed by a Party to its personnel or professional
            advisers where strictly necessary and provided that such persons are made aware of its
            confidential nature.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(d)</span>
        <span class="col item-details">
            This clause 7 will not apply to information which the disclosing party can show: (i) was
            known to the recipient, under no obligation of confidence, at the time it was disclosed; (ii)
            became publicly known through no wrongful act by the disclosing party; or (iii) was
            required to be disclosed by law or the rules of a regulatory authority.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(e)</span>
        <span class="col item-details">
            Neither Party will make any public statement or announcement in connection with these
            Terms without the other Party's prior written consent.
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>8.</strong></span>
        <span class="col item-details">
            <strong>DATA PROTECTION</strong>
        </span>
    </p>
    <p class="row">
        <span class="col item-order"></span>
        <span class="col item-details">
            DTIF shall process Your personal data as an independent controller (as that term is defined
            in applicable data protection laws) in accordance with the terms of its <i>Privacy Policy</i>
            accessible at: <a href="https://dtif.org/privacy-policy/" target="_blank">https://dtif.org/privacy-policy/</a>
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>9.</strong></span>
        <span class="col item-details">
            <strong>ENTIRE AGREEMENT</strong>
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(a)</span>
        <span class="col item-details">
            These Terms (together with any documents referred to herein or therein or required to be
            entered into pursuant to these Terms) contain the entire agreement and understanding of
            the Parties and supersede all prior agreements, understandings or arrangements (both
            oral and written) relating to the subject matter of these Terms and any such document.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(b)</span>
        <span class="col item-details">
            You acknowledge that You are entering into these Terms without reliance on any
            undertaking, warranty or representation given by or on behalf of DTIF other than as
            expressly contained in these Terms, provided that nothing in this clause 9(b) shall limit or
            exclude the liability of DTIF for fraud or fraudulent misrepresentation.
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>10.</strong></span>
        <span class="col item-details">
            <strong>NO PARTNERSHIP OR AGENCY</strong>
        </span>
    </p>
    <p class="row">
        <span class="col item-order"></span>
        <span class="col item-details">
            These Terms shall not create, nor shall they be construed as creating, any partnership or
            agency relationship between the Parties.
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>11.</strong></span>
        <span class="col item-details">
            <strong>VARIATIONS</strong>
        </span>
    </p>
    <p class="row">
        <span class="col item-order"></span>
        <span class="col item-details">
            A variation of these Terms shall only be effective if made in writing, signed by or on behalf
            of each of the Parties and expressed to be such a variation.
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>12.</strong></span>
        <span class="col item-details"><strong>NO WAIVER</strong></span>
    </p>
    <p class="row">
        <span class="col item-order"></span>
        <span class="col item-details">
            No failure or delay by either Party in exercising its rights under these Terms shall limit or
            restrict the future exercise or enforceability of those rights.
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>13.</strong></span>
        <span class="col item-details">
            <strong>ASSIGNMENT/SUB-CONTRACTING</strong>
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(a)</span>
        <span class="col item-details">
            Subject to clause 13(b), neither Party shall, without the prior consent of the other Party,
            assign any of its rights and/or sub-contract, delegate or transfer any of its obligations,
            under these Terms.
        </span>
    </p>
    <p class="row">
        <span class="col item-order">(b)</span>
        <span class="col item-details">
            DTIF may assign any of its rights and/or sub-contract, delegate or transfer any of its
            obligations under these Terms to any of its parent undertakings and any subsidiary
            undertakings of DTIF or DTIF's parent undertakings without Your prior consent. For the
            purposes of this clause, “subsidiary undertaking” and “parent undertaking” shall have the
            meaning ascribed thereto in section 1162 of the Companies Act 2006.
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>14.</strong></span>
        <span class="col item-details">
            <strong>RIGHTS OF THIRD PARTIES</strong>
        </span>
    </p>
    <p class="row">
        <span class="col item-order"></span>
        <span class="col item-details">
            Save as otherwise expressly provided in these Terms (including, without limitation, in
            clauses 4 and 5), no provision of these Terms which confers rights upon any third party
            shall be enforceable by any such third party pursuant to the Contracts (Rights of Third
            Parties) Act 1999.
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>15.</strong></span>
        <span class="col item-details">
            <strong>NOTICES</strong>
        </span>
    </p>
    <p class="row">
        <span class="col item-order"></span>
        <span class="col item-details">
            Any notice or other communication to be given or made under or in connection with these
            Terms shall be in written English and sent via the helpdesk functionality accessible via the
            DTIF Website.
        </span>
    </p>
    <br>
    <p class="row">
        <span class="col item-order"><strong>16.</strong></span>
        <span class="col item-details">
            <strong>GOVERNING LAW AND JURISDICTION</strong>
        </span>
    </p>
    <p class="row">
        <span class="col item-order"></span>
        <span class="col item-details">
            This Agreement and the rights and obligations of the parties shall be governed by, and
            construed in accordance with, the laws of England and Wales. Each party irrevocably
            agrees to submit to the exclusive jurisdiction of the courts of England and Wales.
        </span>
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end" class="actions-container">
    <button mat-raised-button color="accent" [mat-dialog-close]="true" [disabled]="!isReadAll" tabindex="-1" class="actions accept">Accept & Submit</button>
    <button mat-raised-button mat-dialog-close color="outline" tabindex="-1" class="actions decline">Decline</button>
</mat-dialog-actions>