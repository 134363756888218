<div class="title-container">
    <label for="" class="title primary-color size-8">
        Distributed Ledger Technology (DLT) Registration Request
    </label>
</div>
<div class="form-container">
    <dti-loading *ngIf="processing" [text]="loadingText"></dti-loading>
    <dti-initiator-tab *ngIf="atInitiatorTab; else stepper" formType="DLT" [controls]="initiatorFormGroup" #initiator></dti-initiator-tab>
    <ng-template #stepper>
        <div class="stepper-container flex-column" #stepperContainer>
            <mat-horizontal-stepper color="accent" labelPosition="bottom" [linear]="creation" #steps>

                <mat-step [stepControl]="normativeFormGroup" [completed]="normativeFormGroup.valid" [editable]="creation || editing">
                    <form [formGroup]="normativeFormGroup">
                        <ng-template matStepLabel>
                            <p>
                                Normative Attributes
                            </p>
                        </ng-template>
                        <ng-template matStepperIcon="edit">
                            <i class="material-symbols-outlined material-icons-font material-icons">
                                check
                            </i>
                        </ng-template>
                        <div class="normative-container flex-column">
                            <div class="row">
                                <p class="container-title col">Please enter the following details for the DLT token request.</p>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                        <mat-label class="field-label-size" color="primary">Distributed Ledger Technology Type</mat-label>
                                        <mat-select class="field-size" placeholder="Select" formControlName="dlt" color="primary" (selectionChange)="dltOnChange()">
                                            <mat-option [ngClass]="'field-size mat-select-option'" value="Other">Other</mat-option>
                                            <mat-option [ngClass]="'field-size mat-select-option'" value="Blockchain">Blockchain</mat-option>
                                        </mat-select>
                                        <mat-error>This field is required.</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col text-center">
                                    <span class="provisional-label">Provisional</span><mat-slide-toggle formControlName="isProvisional" disableRipple="true"
                                        class="provisional field-size"></mat-slide-toggle>
                                </div>
                            </div>
                            <div class="row" *ngIf="normativeFormGroup.value.isProvisional">
                                <div class="col">
                                    <!-- <mat-slide-toggle checked="true">Provisional</mat-slide-toggle> -->
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                        <mat-label class="field-label-size">Date to be Activated</mat-label>
                                        <!--TODO: [min]="dateToBeActivatedMin" -->
                                        <input class="field-size" matInput placeholder="Date to be Activated" [matDatepicker]="picker">
                                        <mat-datepicker-toggle class="field-size" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker class="field-size" #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col"></div>
                            </div>
                            <hr class="divider">
                            <div class="row" *ngIf="isDLTBlockchain">
                                <div class="col">
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                        <mat-label class="field-label-size">Genesis Block Hash Algorithm</mat-label>

                                        <mat-select class="field-size" placeholder="Select" formControlName="gbHashAlgo">
                                            <mat-option [ngClass]="'field-size mat-select-option'" *ngFor="let option of gbhaOptions" [value]="option">{{option}}</mat-option>
                                        </mat-select>
                                        <mat-error>This field is required.</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col"></div>
                            </div>
                            <div class="row" *ngIf="isDLTBlockchain">
                                <div class="col">
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                        <mat-label class="field-label-size">Genesis Block Hash</mat-label>
                                        <!-- [(ngModel)]="gbh" -->
                                        <input matInput formControlName="gbHash" class="field-size" placeholder="Genesis Block Hash" maxlength="255">
                                        <mat-error *ngIf="normativeFormGroup.get('gbHash')?.hasError('required')">This field is required.</mat-error>
                                        <mat-error *ngIf="!normativeFormGroup.get('gbHash')?.hasError('required') && normativeFormGroup.get('gbHash')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_VALID_SYMBOLS}}</mat-error>

                                    </mat-form-field>
                                </div>
                                <div class="col">
                                </div>
                            </div>
                            <div class="row" *ngIf="isDLTBlockchain">
                                <div class="col">
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                        <mat-label class="field-label-size">Genesis Block UTC Timestamp</mat-label>
                                        <input matInput [ngxMatDatetimePicker]="picker" formControlName="gbUtcTimeStamp" class="field-size" placeholder="Genesis Block UTC Timestamp" maxlength="20">
                                        <mat-datepicker-toggle matSuffix [for]="$any(picker)">
                                        </mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker [showSeconds]="true" #picker></ngx-mat-datetime-picker>
                                        <mat-error>Enter valid date(dd/MM/yyyy HH:mm:ss)</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col"></div>
                            </div>
                        </div>
                    </form>
                </mat-step>

                <mat-step [stepControl]="informativeFormGroup" [completed]="informativeFormGroup.valid" [editable]="creation || editing">
                    <ng-template matStepLabel>
                        <p>
                            Informative Attributes
                        </p>
                    </ng-template>
                    <div class="informative-container flex-column">
                        <form [formGroup]="informativeFormGroup">
                            <div class="row">
                                <div class="col">
                                    <mat-checkbox formControlName="hasOrigLongName" color="primary" class="cb-items" (change)='informativeFormGroup.get("originalLongName")?.reset()'>
                                        Does the token have a long name in the original language that is different from the long name already specified?
                                    </mat-checkbox>
                                </div>
                            </div>
                            <h4 class="section-title pt-1">Long Names</h4>
                            <hr class="divider">
                            <div class="row pt-1-50">
                                <div class="col">
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                        <mat-label class="field-label-size">Long Name</mat-label>
                                        <input class="field-size" formControlName="longName" matInput placeholder="Long Name" maxlength="255">
                                        <!-- [(ngModel)]="longName" -->
                                        <mat-error *ngIf="informativeFormGroup.get('longName')?.hasError('required')">This field is required.</mat-error>
                                        <mat-error
                                            *ngIf="!informativeFormGroup.get('longName')?.hasError('required') && informativeFormGroup.get('longName')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_SPACE}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field *ngIf="informativeData.hasOrigLongName" color="primary" appearance="outline" floatLabel="auto">
                                        <mat-label class="field-label-size">Original Language Long Name</mat-label>
                                        <input class="field-size" formControlName="originalLongName" matInput placeholder="Original Language Long Name" maxlength="255">
                                        <mat-error *ngIf="informativeFormGroup.get('originalLongName')?.errors">{{VALIDATIONS_MESSAGE.UTF_8_WITH_RESTRICT_SYMBOL_MESSAGE}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row pt-1">
                                <div class="col">
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                        <mat-label class="field-label-size">Implementation URL (link to GitHub) </mat-label>
                                        <input matInput type="url" pattern="https?://.+" formControlName="tokenRefUrl" class="field-size" placeholder="Implementation URL (link to GitHub)"
                                            maxlength="255">
                                        <mat-error *ngIf="informativeFormGroup.get('tokenRefUrl')?.hasError('required')">This field is required.</mat-error>
                                        <mat-error *ngIf="!informativeFormGroup.get('tokenRefUrl')?.hasError('required') && informativeFormGroup.get('tokenRefUrl')?.errors">Please enter valid
                                            URL</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                        <mat-label class="field-label-size">Public Distributed Ledger Indicator </mat-label>
                                        <mat-select class="field-size" placeholder="Select" formControlName="publicDistLedgerIndicator">
                                            <mat-option [ngClass]="'field-size mat-select-option'" value="No">Private</mat-option>
                                            <mat-option [ngClass]="'field-size mat-select-option'" value="Yes">Public</mat-option>
                                        </mat-select>
                                        <mat-error>This field is required.</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-step>

                <mat-step *ngIf="initiatorData.hasNativeToken" (interacted)="wasStepInNativeToken=true" [stepControl]="nativeTokenFormGroup" [completed]="wasStepInNativeToken"
                    [editable]="creation || editing">
                    <ng-template matStepLabel>
                        <p> Native Token Creation</p>
                    </ng-template>
                    <div class="native-token-container flex-column">
                        <div [class.flex-column]="hasLongNameMaxLength" class="row long-name-row">
                            <div class="col">
                                <label class="long-name">{{informativeData.longName}}</label>
                            </div>
                            <div [ngClass]="hasLongNameMaxLength ? 'text-left':'text-right'" class="col">
                                <label class="native-digital-token">Native Digital Token</label> | <label class="dlt-label">{{normativeData.dlt}}</label>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col">
                                <p class="tab-header-details">Please enter the following details for Native Digital Token:</p>
                            </div>
                        </div>
                        <div class="row pt2">
                            <div class="col">
                                <mat-tab-group [color]="'primary'" [selectedIndex]="nativeTokenSelectedTab" animationDuration="0ms" class="allow-tab-events" mat-stretch-tabs="false"
                                    mat-align-tabs="start">
                                    <form [formGroup]="nativeTokenFormGroup">
                                        <ng-container formArrayName="nativeTokens">
                                            <mat-tab *ngFor="let nativeToken of nativeTokens.controls let nativeTokenIndex = index" [formGroupName]="nativeTokenIndex">
                                                <ng-template mat-tab-label>
                                                    <div> Native Token {{nativeTokenIndex + 1}} </div>
                                                    <mat-icon *ngIf="nativeTokens.length > 1" (click)=" removeNativeTokenTab($event,nativeTokenIndex)"
                                                        class="native-tab-close-icon material-symbols-outlined material-icons-font material-icons">close</mat-icon>
                                                </ng-template>
                                                <div class="tab-content">
                                                    <div class="row pt-1-50">
                                                        <div class="col">
                                                            <mat-checkbox (change)="onChangeOrigLangShortName($event, nativeTokenIndex)" formControlName="hasOrigLangShortName" color="primary"
                                                                class="has-original-language-short-name">
                                                                Does the token have a short name in the original language that is different from the short name already specified?
                                                            </mat-checkbox>
                                                            <!-- [(ngModel)]="nativeToken.hasOriginalLangaugeShortName" -->
                                                        </div>
                                                    </div>
                                                    <h3 class="section-title pt-1-50">Short Names</h3>
                                                    <hr>
                                                    <div class="row border p pr-0">
                                                        <div class="col" formArrayName="shortNames">
                                                            <div class="row pt-50" *ngFor="let shortNameControl of getShortNames(nativeTokenIndex).controls; let shortNameIndex = index"
                                                                [formGroupName]="shortNameIndex">
                                                                <div class="col field-col">
                                                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                                                        <mat-label class="field-label-size">Short Name {{shortNameIndex+1}}</mat-label>
                                                                        <input matInput class="field-size" formControlName="shortName" placeholder="Short Name" maxlength="30">
                                                                        <mat-error *ngIf="shortNameControl.get('shortName')?.hasError('required')">This field is required.</mat-error>
                                                                        <mat-error *ngIf="!shortNameControl.get('shortName')?.hasError('required') && shortNameControl.get('shortName')?.errors">This
                                                                            field
                                                                            accepts alphanumeric text.</mat-error>
                                                                    </mat-form-field>
                                                                </div>
                                                                <div class="col">
                                                                    <button mat-icon-button color="accent" class="button-delete" *ngIf="getShortNames(nativeTokenIndex).length > 1"
                                                                        (click)="deleteShortName(nativeTokenIndex, shortNameIndex)">
                                                                        <mat-icon class="delete-icon material-symbols-outlined material-icons-font material-icons">delete</mat-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <button mat-icon-button color="accent" *ngIf="getShortNames(nativeTokenIndex).length < 5" (click)="addShortName(nativeTokenIndex)">
                                                                <mat-icon class="material-symbols-outlined material-icons-font material-icons">add</mat-icon>
                                                            </button>
                                                        </div>
                                                        <div class="col" formArrayName="origlangShortNames">
                                                            <ng-container *ngIf="nativeToken.get('hasOrigLangShortName')?.value">
                                                                <div class="row pt-50"
                                                                    *ngFor="let origLangShortNameControl of getOrigLangShortNames(nativeTokenIndex).controls; let origLangShortNameIndex = index"
                                                                    [formGroupName]="origLangShortNameIndex">
                                                                    <div class="col field-col">
                                                                        <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                                                            <mat-label class="field-label-size">Original Langauge Short Name</mat-label>
                                                                            <input matInput formControlName="origlangShortName" class="field-size" placeholder="Original Langauge Short Name"
                                                                                maxlength="30">
                                                                            <mat-error>{{VALIDATIONS_MESSAGE.UTF_8_WITH_RESTRICT_SYMBOL_MESSAGE}}</mat-error>
                                                                        </mat-form-field>
                                                                    </div>
                                                                    <div class="col">
                                                                        <button mat-icon-button color="accent" class="button-delete" *ngIf="getOrigLangShortNames(nativeTokenIndex).length > 1"
                                                                            (click)="deleteOrigLangShortName(nativeTokenIndex, origLangShortNameIndex)">
                                                                            <mat-icon class="delete-icon material-symbols-outlined material-icons-font material-icons">delete</mat-icon>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <button mat-icon-button color="accent" *ngIf="getOrigLangShortNames(nativeTokenIndex).length < 5"
                                                                    (click)="addOrigLangShortName(nativeTokenIndex)">
                                                                    <mat-icon class="material-symbols-outlined material-icons-font material-icons">add</mat-icon>
                                                                </button>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <!-- <br> -->
                                                    <h3 class="section-title pt-2">Attributes</h3>
                                                    <hr>
                                                    <div class="row pt-1">
                                                        <div class="col">
                                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                                                <mat-label class="field-label-size">Unit Multiplier</mat-label>
                                                                <input matInput formControlName="unitMultiplier" class="field-size" placeholder="Unit Multiplier" maxlength="30">
                                                                <mat-error *ngIf="!nativeToken.get('unitMultiplier')?.hasError('required') && nativeToken.get('unitMultiplier')?.errors">This field
                                                                    accepts
                                                                    numeric
                                                                    values only</mat-error>

                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <!-- <br> -->
                                                    <h3 class="section-title pt-1">External Indentifiers</h3>
                                                    <hr>
                                                    <div class="row pt-50">
                                                        <div class="col">
                                                            <mat-checkbox (change)="onChangeExternalIdentifiers($event, nativeTokenIndex)" formControlName="hasExternalIdentifier" color="primary">
                                                                <!-- [(ngModel)]="nativeToken.hasExternalIdentifier" -->
                                                                Does the token have any identifiers, external to this standard, that may be used to identify either the digital token or the underlying
                                                                asset
                                                                that
                                                                this digital token represents?
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="row border external-identifier-container mt-1" *ngIf="nativeToken.get('hasExternalIdentifier')?.value">
                                                        <div class="col" formArrayName="underlyingAssets">
                                                            <div class="row" *ngFor="let underlyingAssetControl of getUnderlyingAssets(nativeTokenIndex).controls; let underlyingAssetIndex = index"
                                                                [formGroupName]="underlyingAssetIndex">
                                                                <div class="col pr-50 pt-50">
                                                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                                                        <mat-label class="field-label-size">Underlying Asset External Identifiers Type</mat-label>
                                                                        <mat-select class="field-size" placeholder="Select" formControlName="type"
                                                                            (selectionChange)="onSelectExternalIdentifiers(nativeTokenIndex, underlyingAssetIndex, true)">
                                                                            <mat-option [ngClass]="'field-size mat-select-option'" *ngFor="let option of underlyingAssetExternalIdentifierTypesOptions"
                                                                                [value]="option.value">{{option.text}}</mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </div>
                                                                <div class="col pl-50 pt-50">
                                                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                                                        <mat-label class="field-label-size">Underlying Asset External Identifiers Value</mat-label>
                                                                        <input matInput class="field-size" formControlName="value" placeholder="Underlying Asset External Identifiers Value"
                                                                            maxlength="48" />
                                                                        <mat-error *ngIf="underlyingAssetControl.get('value')?.hasError('required')">This field is required</mat-error>
                                                                        <mat-error
                                                                            *ngIf="!underlyingAssetControl.get('value')?.hasError('required') && underlyingAssetControl.get('value')?.errors">This field
                                                                            accepts alphanumetic characters.</mat-error>
                                                                    </mat-form-field>
                                                                </div>
                                                                <div class="col text-center flex-grow-0">
                                                                    <button mat-icon-button color="accent" class="ext-delete-button"
                                                                        (click)="deleteUnderlyingAsset(nativeTokenIndex, underlyingAssetIndex)">
                                                                        <mat-icon class="delete-icon material-symbols-outlined material-icons-font material-icons">delete</mat-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col text-center">
                                                                    <button mat-icon-button color="accent"
                                                                        *ngIf="(nativeToken.get('hasExternalIdentifier')?.value && getUnderlyingAssets(nativeTokenIndex).length < 5)"
                                                                        (click)="addUnderlyingAsset(nativeTokenIndex)">
                                                                        <mat-icon class="material-symbols-outlined material-icons-font material-icons">add</mat-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row border external-identifier-container mt-1" *ngIf="nativeToken.get('hasExternalIdentifier')?.value">
                                                        <div class="col" formArrayName="externalIdentifiers">
                                                            <div class="row" *ngFor="let externalIdentifier of getExternalIdentifiers(nativeTokenIndex).controls; let externalIdentifierIndex = index"
                                                                [formGroupName]="externalIdentifierIndex">
                                                                <div class="col  pr-50 pt-50">
                                                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                                                                        <mat-label class="field-label-size">Digital Token External Identifiers Type</mat-label>
                                                                        <mat-select class="field-size" placeholder="Select" formControlName="type"
                                                                            (selectionChange)="onSelectExternalIdentifiers(nativeTokenIndex, externalIdentifierIndex)">
                                                                            <mat-option [ngClass]="'field-size mat-select-option'" *ngFor="let option of digitalTokenExternalIdentifiersTypesOptions"
                                                                                [value]="option.value">{{option.text}}</mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </div>
                                                                <div class="col pl-50 pt-50">
                                                                    <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                                                        <mat-label class="field-label-size">Digital Token External Identifiers Value</mat-label>
                                                                        <input matInput class="field-size" formControlName="value" placeholder="Digital Token External Identifiers Value"
                                                                            maxlength="48" />
                                                                        <mat-error *ngIf="externalIdentifier.get('value')?.hasError('required')">This field is required</mat-error>
                                                                        <mat-error *ngIf="!externalIdentifier.get('value')?.hasError('required') && externalIdentifier.get('value')?.errors">This field
                                                                            accepts
                                                                            alphanumetic characters.</mat-error>
                                                                    </mat-form-field>
                                                                </div>
                                                                <div class="col flex-grow-0">
                                                                    <button mat-icon-button color="accent" class="ext-delete-button"
                                                                        (click)="deleteExternalIdentifier(nativeTokenIndex, externalIdentifierIndex)">
                                                                        <mat-icon class="delete-icon material-symbols-outlined material-icons-font material-icons">delete</mat-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col text-center">
                                                                    <button mat-icon-button color="accent" (click)="addExternalIdentifier(nativeTokenIndex)"
                                                                        *ngIf="getExternalIdentifiers(nativeTokenIndex).length < 5">
                                                                        <mat-icon class="material-symbols-outlined material-icons-font material-icons">add</mat-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-tab>
                                            <mat-tab disabled *ngIf="nativeTokens.length < NATIVE_TOKEN_MAXIMUM_TAB">
                                                <ng-template mat-tab-label>
                                                    <button mat-icon-button color="primary" (click)="addNativeTokenTab()" title="New Native Token">
                                                        <mat-icon class="native-tab-add-icon material-symbols-outlined material-icons-font material-icons">add</mat-icon>
                                                    </button>
                                                </ng-template>
                                            </mat-tab>
                                        </ng-container>
                                    </form>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <mat-step *ngIf="initiatorData.hasAuxiliaryToken" (interacted)="wasStepInAuxiliary=true" [stepControl]="auxiliaryFormGroup" [completed]="wasStepInAuxiliary"
                    [editable]="creation || editing">

                    <ng-template matStepLabel>
                        <p> Auxiliary Token Creation </p>
                    </ng-template>
                    <div class="auxiliary-container flex-column container container-step">
                        <div [class.flex-column]="hasLongNameMaxLength" class="row long-name-row">
                            <div class="col">
                                <label class="long-name">{{(informativeData).longName}}</label>
                            </div>
                            <div  [ngClass]="hasLongNameMaxLength ? 'text-left':'text-right'"  class="col">
                                <label class="dlt-label">{{(normativeData).dlt}}</label>
                            </div>
                        </div>
                        <div class="details-container row">
                            <div class="col">
                                <p class="tab-header-details">Please enter details for Auxiliary Mechanism(s).</p>
                            </div>
                        </div>
                        <div class="content-container row">
                            <div class="col">
                                <!-- <button mat-raised-button class="example-add-tab-button" (click)="addAuxiliaryTab()" *ngIf="auxiliaryTokens.length < 3">
                                    Add Auxiliary Token
                                </button> -->
                                <mat-tab-group [color]="'primary'" [selectedIndex]="auxiliarySelectedTab" animationDuration="0ms" class="allow-tab-events" mat-stretch-tabs="false"
                                    mat-align-tabs="start">
                                    <form [formGroup]="auxiliaryFormGroup">
                                        <ng-container formArrayName="auxiliaryMechanisms">
                                            <mat-tab *ngFor="let auxiliary of auxiliaries.controls; let index = index" [formGroupName]="index">
                                                <ng-template mat-tab-label>
                                                    <label for="auxiliary">Mechanism {{index + 1}}</label>
                                                    <mat-icon *ngIf="auxiliaries.length > 1" color="accent" (click)=" removeAuxiliaryTab($event, index)"
                                                        class="aux-tab-close material-symbols-outlined material-icons-font material-icons">close</mat-icon>
                                                </ng-template>
                                                <div class="tab-content pt-1-50">
                                                    <div class="row pt-1">
                                                        <div class="col flex-grow-2">
                                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="example-full-width">
                                                                <mat-label class="field-label-size">Auxiliary Mechanism Name</mat-label>
                                                                <input formControlName="auxiliaryMechanism" matInput class="field-size" placeholder="Auxiliary Mechanism Name (e.g., ERC-20)"
                                                                    maxlength="48">
                                                                <mat-error *ngIf="auxiliary.get('auxiliaryMechanism')?.hasError('required')">This field is required.</mat-error>
                                                                <mat-error
                                                                    *ngIf="!auxiliary.get('auxiliaryMechanism')?.hasError('required') && auxiliary.get('auxiliaryMechanism')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_VALID_SYMBOL_UTF_8_ERROR_MESSAGE}}</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col"></div>
                                                    </div>
                                                    <div class="row pt-1">
                                                        <div class="col flex-grow-4">
                                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="text-area">
                                                                <mat-label class="field-label-size">Auxiliary Mechanism Description</mat-label>
                                                                <textarea formControlName="auxiliaryMechanismDesc" matInput class="field-size" placeholder="Auxiliary Mechanism Description"
                                                                    cdkTextareaAutosize cdkAutosizeMinRows="5" maxlength="255"></textarea>
                                                                <mat-error *ngIf="auxiliary.get('auxiliaryMechanismDesc')?.hasError('required')">This field is required</mat-error>
                                                                <mat-error
                                                                    *ngIf="!auxiliary.get('auxiliaryMechanismDesc')?.hasError('required') && auxiliary.get('auxiliaryMechanismDesc')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_VALID_SYMBOL_UTF_8_ERROR_MESSAGE}}</mat-error>
                                                                <mat-hint align="end" class="text-counter">{{textCounter(auxiliary.get('auxiliaryMechanismDesc')) }}</mat-hint>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col flex-grow-50"></div>
                                                    </div>
                                                    <div class="row pt-1">
                                                        <div class="col flex-grow-4">
                                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="text-area">
                                                                <mat-label class="field-label-size">Auxiliary Digital Token Technical Reference Overview</mat-label>
                                                                <textarea formControlName="auxiliaryMechanismTechRef" matInput class="field-size"
                                                                    placeholder="Auxiliary Digital Token Technical Reference Overview" cdkTextareaAutosize cdkAutosizeMinRows="5"
                                                                    maxlength="255"></textarea>
                                                                <mat-error *ngIf="auxiliary.get('auxiliaryMechanismTechRef')?.hasError('required')">This field is required.
                                                                </mat-error>
                                                                <mat-error
                                                                    *ngIf="!auxiliary.get('auxiliaryMechanismTechRef')?.hasError('required') && auxiliary.get('auxiliaryMechanismTechRef')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_VALID_SYMBOL_UTF_8_ERROR_MESSAGE}}</mat-error>
                                                                <mat-hint align="end" class="text-counter">{{textCounter(auxiliary.get('auxiliaryMechanismTechRef')) }}</mat-hint>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col flex-grow-50"></div>

                                                    </div>
                                                    <div class="row pt-1">
                                                        <div class="col flex-grow-4">
                                                            <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="text-area">
                                                                <mat-label class="field-label-size">Auxiliary Digital Token Verification Details</mat-label>
                                                                <textarea formControlName="auxiliaryMechanismVerificationDetail" matInput class="field-size"
                                                                    placeholder="Auxiliary Digital Token Verification Details" cdkTextareaAutosize cdkAutosizeMinRows="5" maxlength="255"></textarea>
                                                                <mat-error *ngIf="auxiliary.get('auxiliaryMechanismVerificationDetail')?.hasError('required')">This field is required.
                                                                </mat-error>
                                                                <mat-error
                                                                    *ngIf="!auxiliary.get('auxiliaryMechanismVerificationDetail')?.hasError('required') && auxiliary.get('auxiliaryMechanismVerificationDetail')?.errors">{{VALIDATIONS_MESSAGE.ALPHA_NUMERIC_VALID_SYMBOL_UTF_8_ERROR_MESSAGE}}</mat-error>
                                                                <mat-hint align="end" class="text-counter">{{textCounter(auxiliary.get('auxiliaryMechanismVerificationDetail')) }}</mat-hint>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col flex-grow-50"></div>
                                                    </div>
                                                </div>
                                            </mat-tab>
                                            <mat-tab disabled *ngIf="auxiliaries.length < 3" class="plus">
                                                <ng-template mat-tab-label>
                                                    <button mat-icon-button (click)="addAuxiliaryTab()" title="New Mechanism">
                                                        <mat-icon class="aux-tab-add material-symbols-outlined material-icons-font material-icons">add</mat-icon>
                                                    </button>
                                                </ng-template>
                                            </mat-tab>

                                        </ng-container>
                                    </form>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </mat-step>

                <mat-step>
                    <ng-template matStepLabel>
                        <label> Review & Submit </label>
                    </ng-template>
                    <div *ngIf="!successfull" class="flex-column">
                        <dti-summary (onEdit)="onTabChange($event)" [details]="summaryDetails" [changesMade]="changesMade"></dti-summary>
                        <div class="row additional-info">
                            <div class="col">
                                <mat-form-field color="primary" appearance="outline" floatLabel="auto" class="text-area">
                                    <mat-label class="field-label-size">Additional Information</mat-label>
                                    <textarea matInput [(ngModel)]="additionalInfo" class="field-size" placeholder="Additional Information" cdkTextareaAutosize cdkAutosizeMinRows="5"
                                        maxlength="400"></textarea>
                                    <mat-hint align="end" class="text-counter">{{textCounter({value:additionalInfo},400)}}</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <dti-success-submission *ngIf="successfull" [applicationId]="applicationId" [tokenLongName]="informativeData.longName" [tokenShortName]="tokenShortNames"
                        [tokenType]="'Native Digital Token'" [mechanism]="normativeData.dlt" [title]="successTitle">
                    </dti-success-submission>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </ng-template>
    <dti-previous-next [hasPrevious]="hasGotoPrevious" [hasContinue]="!successfull" [hasReview]="hasGotoReview" [previousText]="previousText" [continueText]="continueText"
     (reviewClick)="onClickContinue(false)" (previousClick)="onClickPrevious()" (continueClick)="onClickContinue(true)"></dti-previous-next>
    <dti-tab-glossary [tabIndex]="currentTabIndex" [show]="isShowGlossary" (close)="isShowGlossary=false" />
</div>
<dti-right-side-menus [show]="continueText!='Submit' && !processing && !successfull && !isShowGlossary" (onClick)="isShowGlossary = true"></dti-right-side-menus>