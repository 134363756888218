<div class="container">
  <div class="row">
      <div class="col col-title">
          <p class="title"><mat-icon>block</mat-icon> <span class="text-title">{{title}}</span><br></p>
      </div>
      <div class="col text-right">
          <button mat-icon-button class="close-button" mat-dialog-close tabindex="-1">
              <mat-icon class="close-icon">close</mat-icon>
          </button>
      </div>
  </div>

  <mat-dialog-content>
      <p class="content-text">{{message}}<a href={{mailText}}>support@dtif.org</a></p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
      <button mat-raised-button color="accent" [mat-dialog-close]="true" tabindex="-1" class="actions">{{okButton}}</button>
  </mat-dialog-actions>
</div>
