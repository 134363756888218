import * as auth0 from 'auth0-js';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './data/user.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private auth0 = new auth0.WebAuth({
    clientID: environment.clientID,
    domain: environment.domain,
    redirectUri: `${window.location.origin}/callback`,
    responseType: 'token id_token',
    scope: 'openid profile email',
    audience: environment.audience
  });
  authService: any;

  constructor(public router: Router, private user: UserService, private cookieService: CookieService) { }

  signup(email: string, password: string, firstName: string, lastName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth0.signup({
        connection: environment.DB,
        email: email,
        password: password,
        userMetadata: {
          firstName: firstName,
          lastName: lastName
        }
      }, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  isAuthenticated(): boolean {
    const accessToken = this.cookieService.get('access_token');
    this.setSessionFromOtherApp();
    return !!accessToken;
  }

  setSessionFromOtherApp() {

    if (!this.user.email) {
      this.user.email = this.cookieService.get('email');
    }

    if (!this.user.name) {
      this.user.name = this.cookieService.get('name');
    }
    const expirationDate = this.getCookieExpiration();
    const now = new Date().getTime();
    if (expirationDate !== undefined) {
      const diff = expirationDate.getTime() - now;
      setTimeout(() => {
        this.logout();
      }, diff);

    }
  }

  logout() {
    const expirationDate = new Date('Thu, 01 Jan 1970 00:00:00 UTC');
    this.cookieService.set('access_token', '', expirationDate, '/', environment.cookieDomain);
    this.cookieService.set('name', '', expirationDate, '/', environment.cookieDomain);
    this.cookieService.set('email', '', expirationDate, '/', environment.cookieDomain);
    this.auth0.logout({
      returnTo: environment.baseUrl + '/sign-out',
      clientID: environment.clientID
    });
  }

  getAuth0(): auth0.WebAuth {
    return this.auth0;
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.router.navigate(['/']);
      } else if (err) {
        console.error(err);
      }
    });
  }

  private setSession(authResult: any) {
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 1);
    const now = new Date().getTime();
    const diff = expirationDate.getTime() - now;
    setTimeout(() => {
      this.logout();
    }, diff);
    this.cookieService.set('access_token', authResult.accessToken, expirationDate, '/', environment.cookieDomain);
    this.auth0.client.userInfo(authResult.accessToken, (err, { email, name, nickname, picture }: any) => {
      this.cookieService.set('email', email, expirationDate, '/', environment.cookieDomain);
      this.cookieService.set('name', name, expirationDate, '/', environment.cookieDomain);
      this.user.email = email;
      this.user.name = name;
      this.user.nickname = nickname;
      this.user.picture = picture;
    });
  }

  private getCookieExpiration() {
    const cookieName = 'access_token';
    const cookieValue = this.cookieService.get(cookieName);

    if (cookieValue) {
      const cookieParts = cookieValue.split(';');
      for (const part of cookieParts) {
        const trimmedPart = part.trim();
        if (trimmedPart.indexOf('expires=') === 0) {
          const expirationDateStr = trimmedPart.substring(8);
          const expirationDate = new Date(expirationDateStr);
          return expirationDate;
        }
      }
    }
    return undefined;
  }

}
