import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormType } from 'src/app/shared/models/enums';
import { Section, Summary } from 'src/app/shared/models/interface';

@Component({
  selector: 'dti-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent {

  @Input() details: Summary = {
    title: '',
    subTitle: '',
    miniSubTitle: '',
    sections: [{
      title: '',
      items: []
    }]
  }

  @Input() changesMade: any[] = []

  @Output() onEdit: EventEmitter<any> = new EventEmitter();

  onEditSection($event: number) {
    // if $event is 0 set to -1 as first tab 
    // else -2 since biling info is in initiator tab.
    this.onEdit.emit($event - ($event ? 2 : 1))
  }

  mapField({ label, value }: any) {
    // if has field label show even it's null(convert to empty)
    return label ? `${label} : <b>${value || ''}</b>` : '';
  }
}
