import { HttpClient, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DltService {
  private baseURL = environment.apiBaseURL;
  private url = `${this.baseURL}/v1/api/request/dlt`
  private _jiraKey = '';

  constructor(private http: HttpClient) { }


  set applicationId(id: string) {
    this._jiraKey = id;
  }
  
  get applicationId(): string {
    return `${this._jiraKey}`;
  }

  submitToken(body: DLT) {
    return this.http.post<DLT>(this.url, body);
  }

  getRequestDetail(id: string) {
    return this.http.get<any>(`${this.url}/${id}`);
  }

  updateRequestDetail(dltData: DLT) {
    return this.http.put<DLT>(`${this.url}/${this._jiraKey}`, dltData);
  }
}

// TODO: Isolate interface in other file

export interface DLT {
  isCreator: boolean,
  hasLei?: boolean,
  lei?: string,
  companyName?: string,
  businessIdentifierCode?: string,
  normative: Normative,
  informative: Informative,
  nativeTokens: NativeToken[],
  auxiliaryTokens: Auxiliary[],
  metaData: MetaData
}

interface Normative {
  dlt: string,
  gbHashAlgo?: string,
  gbHash?: string,
  gbUtcTimeStamp?: string,
  isProvisional: boolean,
  activationDate?: string
}

interface Informative {
  longName: string,
  originalLongName?: string,
  tokenRefUrl: string,
  publicDistLedgerIndicator: string
}

interface NativeToken {
  shortNames: string[],
  originalShortNames?: string[],
  attribute: {
    unitMultiplier: string
  },
  externalIdentifier?: {
    underLyingAssets?: ValueType[],
    digitalTokens?: [
      { type: string, value: string }
    ]
  }
  // externalIdentifiers :
}

interface ValueType {
  value: string,
  type: string
}


interface Auxiliary {
  auxiliaryMechanism: string,
  auxiliaryMechanismDesc: string,
  auxiliaryMechanismTechRef: string,
  auxiliaryMechanismVerificationDetail: string
}


interface MetaData {
  email: string,
  timeStamp: string
}

