// list of validations to be use in all fields. 
export const VALIDATIONS = {
    ASCII: /^[ -~]+$/,
    ALPHA_NUMERIC_MAX20: /^[A-Z,0-9]{20}$/,
    ALPHA_NUMERIC_MIN8_MAX11: /^[A-Z,0-9]{8}$|^[A-Z,0-9]{11}$/,
    ALPHA_NUMERIC_VALID_SYMBOLS: /^[\w\ ._-]+$/,
    ALPHA_NUMERIC_SPACE: /^(?!.* {3})[a-zA-Z0-9]+(?: {1}[a-zA-Z0-9]+)*$/,
    ALPHA_NUMERIC: /^(?:[a-zA-Z0-9]+)?$/,
    ALPHA_NUMERIC_VALID_ASCII: /^[a-zA-Z0-9._-]*$/,
    ALPHA_NUMERIC_WITH_SINGLE_SPECIAL_CHARACTER: '^[a-zA-Z0-9.-]+( [a-zA-Z0-9.-]+)*$',
    COMPANY_NAME: /^(?!\s*$)(?!.* {2,})(?!^\s)(?!.*\s$)[^\~\!\#\$\%\^\*\(\)\{\}\=\<\>\:\;]+(?: [^\~\!\#\$\%\^\*\(\)\{\}\=\<\>\:\;]+)*$/,
    EMAIL: '^[a-zA-Z0-9]+([._%+-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z]{1,})*$',
    FLOATING_NUMBER: /^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/,
    LATIN_CHARACTERS_WHITESPACE: /^[a-zA-Z\s]+$/,
    NUMERIC_FLOAT: '^\\+*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$',
    PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/, //source: https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
    TELEPHONE_OR_VAT_NUMBER: '^\\+?[0-9]+(-[0-9]+)*$',
    UTF8: /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu,
    UTF_8_WITH_RESTRICT_SYMBOL: /^(?!\s*$)(?!.* {2,})(?!^\s)(?!.*\s$)[^\~\!\#\$\%\^\&\*\(\)\{\}\=\<\>\:\;]+(?: [^\~\!\#\$\%\^\&\*\(\)\{\}\=\<\>\:\;]+)*$/,
    URL: /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi,
};

export const VALIDATIONS_MESSAGE = {
    ALPHA_NUMERIC_MAX20: `This field accepts 20 alphanumetic values.`,
    ALPHA_NUMERIC_SPACE: `This field accepts alphanumetic values.`,
    ALPHA_NUMERIC_VALID_SYMBOLS: `This field accepts alphanumetic & symbols(.-_)`,
    ALPHA_NUMERIC_MIN8_MAX11: `This field accepts 8/11 alphanumetic values.`,
    ALPHA_NUMERIC_VALID_SYMBOL_UTF_8_ERROR_MESSAGE: 'This field restrict (!~#$%^&*{}=[]<>/`) symbols.',
    EMAIL: 'Email address can only use: a-z, A-Z, 0-9, and @._%+-',
    FLOATING_NUMBER: `This field accepts floating numbers only`,
    ENTER_VALID_INPUTS: 'Please enter valid values.',
    LATIN_CHARACTERS_WHITESPACE: 'This field accepts [A-Z,a-z] only',
    NUMERIC_FLOAT: `This field accepts numeric values only`,
    REQUIRED: `This field is required.`,
    UTF_8_WITH_RESTRICT_SYMBOL_MESSAGE: 'This field restrict (!~#$%^&*{}=[]<>/`) symbols.',
    NO_WHITESPACE: 'Input cannot be empty or just spaces.',

    LETTERS_AND_NUMBERS_ONLY: (fieldName: string) => `${fieldName} can only use: a-z A-Z 0-9 . and -`,
    NUMBERS_ONLY: (fieldName: string) => `${fieldName} can only use: 0-9, +, and -`,
};