import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'dti-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.scss']
})
export class RequestListComponent {


 @Input() dataSource = new MatTableDataSource([]);
  displayedColumns = ["issueType", "reference", "summary", "createdAt", "status"];


  constructor() { }

  getQueryParams(query: any): object {
    const { issueType } = query;
    return { issueType }
  }
}
