import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

import { RegistrationComponent } from './registration/registration.component';
import { CallbackComponent } from './callback/callback.component';
import { ApiDocumentationsComponent } from './api-documentations/api-documentations.component';
import { Auth0RegisterComponent } from './auth0-register/auth0-register.component';
import { DistributedLedgerTokenComponent } from './pages/distributed-ledger-token/distributed-ledger-token.component';
import { AuxiliaryTokenComponent } from './pages/auxiliary-token/auxiliary-token.component';
import { MyRequestsComponent } from './pages/my-requests/my-requests.component';
import { TokenRequestSummaryComponent } from './pages/token-request-summary/token-request-summary.component';
import { FormsGuard } from './guard/forms.guard';
import { BlockedUserComponent } from './pages/blocked-user/blocked-user.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';


const routes: Routes = [
  {
    path: '', component: DashboardComponent, canActivate: [AuthGuard]
  },
  {
    path: 'distributed-ledger-token/:id', component: DistributedLedgerTokenComponent, canActivate: [AuthGuard], canDeactivate: [FormsGuard]
  },
  {
    path: 'distributed-ledger-token', component: DistributedLedgerTokenComponent, canActivate: [AuthGuard], canDeactivate: [FormsGuard]
  },
  {
    path: 'auxiliary-token/:id', component: AuxiliaryTokenComponent, canActivate: [AuthGuard], canDeactivate: [FormsGuard]
  },
  {
    path: 'auxiliary-token', component: AuxiliaryTokenComponent, canActivate: [AuthGuard], canDeactivate: [FormsGuard]
  },
  {
    path: 'my-requests', component: MyRequestsComponent, canActivate: [AuthGuard]
  },
  {
    path: 'dti-allocation', component: RegistrationComponent, canActivate: [AuthGuard]
  },
  {
    path: 'token-request-summary/:id', component: TokenRequestSummaryComponent, canActivate: [AuthGuard]
  },
  {
    path: 'signup', component: Auth0RegisterComponent
  },
  {
    path: 'callback', component: CallbackComponent
  },
  {
    path: 'blocked', component: BlockedUserComponent
  },
  {
    path: 'swagger', component: ApiDocumentationsComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  providers: [
    AuthGuard
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
