import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { IFormState } from '../shared/models/interface';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDiallogComponent } from '../components/confirmation-diallog/confirmation-diallog.component';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class FormsGuard implements CanDeactivate<IFormState> {

  constructor(private dialog: MatDialog, private cookieService: CookieService) { }

  canDeactivate(
    component: IFormState,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      if (component.isTouched() && !!this.cookieService.get('access_token')) {
        return new Promise<boolean>((resolve) => {
          const dialogRef = this.dialog.open(ConfirmationDiallogComponent, {
            data: {
              title: 'Leaving this form?',
              message: 'All input data in the fields will not be saved.',
              yesButtonText: 'Yes, Leave this form',
              noButtonText: 'No, Stay this form'
            }
          });
          dialogRef.afterClosed().subscribe((confirm) => {
            resolve(Boolean(confirm))
          })
        });
    }
    return true;
  }

}
