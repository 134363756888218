import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  private url = `${environment.apiBaseURL}/v1/api/request`;

  constructor(private httpClient: HttpClient) { }

  getComments(id: string, startAt = 0, maxResult = 20) {
    return this.httpClient.get<any>(`${this.url}/${id}/comment?startAt=${startAt}&maxResults=${maxResult}`)
  }

  addComment(id: string, commentDetail: any) {
    return this.httpClient.post<any>(`${this.url}/${id}/comment`, commentDetail);
  }

  cancelRequest(id: string) {
    return this.httpClient.delete(`${this.url}/${id}`)
  }
}
