import { Component, OnInit  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dti-blocked-user',
  templateUrl: './blocked-user.component.html',
  styleUrls: ['./blocked-user.component.scss']
})
export class BlockedUserComponent implements OnInit  {

  constructor(private dialog: MatDialog, private route: ActivatedRoute) { }

  ngOnInit(): void {

    let param = environment.baseUrl;
    this.route.queryParams
      .subscribe(params => {
        param = params?.['redirect_uri'];
      }
    );

    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {
        title: 'Access Blocked!',
        message: 'Your account is disabled, please contact ',
        okButton: "Go Back"
      },
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(() => {

      if(!param.match(environment.baseUrl)) {
        const url = new URL(param)
        const baseUrl = `${url.protocol}//${url.hostname}`
        param = baseUrl;
      } else {
        param = environment.baseUrl;
      }
      window.location.href = param;
    })
  }

}
