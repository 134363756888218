export const environment = {
  production: false,
  title: 'Local Environment Heading',
  apiBaseURL: `https://c62k3baee4.execute-api.ap-southeast-1.amazonaws.com/dev`,
  clientID: '13I18DeKfJech3D99E07DtTTI9D5JMd8',
  domain: 'auth-dev.dtif.org',
  audience: 'https://dev-dtif-org.eu.auth0.com/api/v2/',
  DB: 'DB-DTIF',
  baseUrl: 'https://staging14.dtif.org',
  apiKey: 'Is1CEL6vLm53ojjj0Afz446ZjOLXapuK9UPi39t2',
  ijeBaseUrl: 'https://dti-ije-staging.dtif.org',
  cookieDomain: '.dtif.org'
};
