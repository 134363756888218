import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'dti-right-side-menus',
  templateUrl: './right-side-menus.component.html',
  styleUrls: ['./right-side-menus.component.scss']
})
export class RightSideMenusComponent {

  @Input() show = true;
  @Output() onClick = new EventEmitter();
  @HostBinding('class') get visible() { return this.show ? 'show' : 'hide'; }
}
