import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-multiple-dropdown-field',
  templateUrl: './multiple-dropdown-field.component.html',
  styleUrls: ['./multiple-dropdown-field.component.scss']
})
export class MultipleDropdownFieldComponent {

  @Input() label: string = '';
  @Input() options: any[] = [{ value: '', text: '' }];
  @Input() selections: any[] = [{ value: '' }];


  get isAddVisible() {
    return this.selections.length < 5;
  }

  get isDeleteVisible() {
    return this.selections.length > 1;
  }

  addSelections() {
    this.selections.push({ value: '' });
  }

  removeSelection(index: number) {
    this.selections.splice(index, 1);
  }

}
