import { Component, Input } from '@angular/core';

@Component({
  selector: 'dti-success-submission',
  templateUrl: './success-submission.component.html',
  styleUrls: ['./success-submission.component.scss']
})
export class SuccessSubmissionComponent {

  @Input() title: string = 'New token has been successfully submitted!';
  @Input() applicationId: string = '87904-75129004-8u485';
  @Input() tokenLongName: string = 'AFREUM STABLE ZWL';
  @Input() tokenShortName: string = 'SZWL';
  @Input() tokenType: string = 'Auxilary Digital Token';
  @Input() mechanism: string = 'Bitcoin Omni Layer (BTC)';

  get shortName(): string {
    return !!this.tokenShortName ? `(${this.tokenShortName})` : '';
  }
}