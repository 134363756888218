import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuxiliaryService } from 'src/app/services/auxiliary.service';
import { DltService } from 'src/app/services/dlt.service';
import { JoinObjectList, JoinStringList } from 'src/app/shared/util/helper';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDiallogComponent } from 'src/app/components/confirmation-diallog/confirmation-diallog.component';
import { RequestService } from 'src/app/services/request.service';
import { UserService } from 'src/app/data/user.service';
import { RequestState } from 'src/app/shared/models/enums';



@Component({
  selector: 'dti-token-request-summary',
  templateUrl: './token-request-summary.component.html',
  styleUrls: ['./token-request-summary.component.scss']
})

export class TokenRequestSummaryComponent implements OnInit {

  requestDetails: any = {};
  submittedTokenId: string = '';
  issueType: string = '';
  status = '';
  dateCreated = new Date();
  requestState = RequestState.Processing;
  loadingText = ``;

  private hasAuxiliaryToken = false;
  private hasNativeTokens = false;

  constructor(private activatedRoute: ActivatedRoute, private route: Router,
    private dltService: DltService, private auxiliaryService: AuxiliaryService,
    private requestService: RequestService, private userService: UserService,
    private dialog: MatDialog) {

    this.activatedRoute.queryParams.subscribe(({ issueType }) => this.issueType = issueType);

    this.activatedRoute.params.subscribe(({ id: applicationId }) => {
      this.submittedTokenId = applicationId;
      this.requestState = RequestState.Processing;
      this.getTokenRequestDetails();
    });

  }

  get subTitle(): string {
    return this.issueType == `DLT` ? `Native Digital Token` : `Auxiliary Digital Token`;
  }

  get canCancelRequest(): boolean {
    return ['open', 'in progress'].includes(this.status.toLowerCase());
  }

  get canEditSummary(): boolean {
    return ['open', 'in progress', 'escalated', 'pending user input'].includes(this.status.toLowerCase());
  }

  get userNickName(): string {
    return this.userService.name;
  }

  get isProcessing(): boolean {
    return this.requestState == RequestState.Processing;
  }

  ngOnInit() {

  }

  getTokenRequestDetails() {
    this.requestState = RequestState.Processing;
    if (this.issueType === 'DLT') {
      this.dltService.getRequestDetail(this.submittedTokenId).subscribe(({ data }) => {
        const { billingInfo, normative, informative, auxiliaryTokens, nativeTokens, status, dateCreated } = data,
          sections = [this.getIssuerDetails(data), this.getbillingInfo(billingInfo), this.getNormativeDetails(normative), this.getInformativeDetails(informative)],
          auxiliaryDetails = this.getDLTAuxiliaryDetails(auxiliaryTokens),
          nativeTokensDetails = this.getDLTNativeTokenDetails(nativeTokens);
        if (Object.keys(nativeTokensDetails).length) {
          sections.push(nativeTokensDetails);
          this.hasNativeTokens = true;
        }
        if (Object.keys(auxiliaryDetails).length) {
          this.hasAuxiliaryToken = true;
          sections.push(auxiliaryDetails);
        }

        this.dateCreated = new Date(dateCreated)
        this.status = status;
        this.requestDetails = {
          title: informative.longName,
          subTitle: this.subTitle,
          miniSubTitle: normative.dlt,
          hasEditButton: this.canEditSummary,
          sections
        }
        this.requestState = RequestState.Successful;
      })
    }
    else {
      this.auxiliaryService.getRequestDetail(this.submittedTokenId).subscribe(({ data }) => {
        const { billingInfo, normative, informative, status, dateCreated } = data;
        this.status = status;
        this.dateCreated = new Date(dateCreated)
        this.requestDetails = {
          title: informative.longName,
          subTitle: this.subTitle,
          miniSubTitle: normative.auxiliaryDlt,
          hasEditButton: this.canEditSummary,
          sections: [this.getIssuerDetails(data), this.getbillingInfo(billingInfo), this.getNormativeDetails(normative), this.getAUXInformativeDetails(informative)]
        }
        this.requestState = RequestState.Successful;
      })
    }
  }


  getIssuerDetails({ isCreator, lei, companyName, businessIdentifierCode }: any): any {

    const items: any = [];
    if (isCreator != undefined) {
      items.push({
        fields: [{ label: 'Are you the Creator/Initiator/Maintainer of the DLT?', value: isCreator ? 'Yes' : 'No' }]
      });
    }
    if (lei != undefined) {
      items.push({
        fields: [{ label: 'Legal Entity Identifier (LEI)', value: lei }]
      });
    }
    if (companyName != undefined) {
      items.push({
        fields: [{ label: 'Company', value: companyName }]
      });
    }
    if (businessIdentifierCode != undefined) {
      items.push({
        fields: [{ label: 'Business Identifier Code', value: businessIdentifierCode }]
      });
    }


    return {
      title: 'Issuer Details',
      items,
    }
  }

  getbillingInfo({ customerName, financeContract, emailAddress, tellNo, invoiceAddress, vatNumber }: any) {
    return {
      title: 'Billing Information',
      items: [
        { fields: [{ label: 'Customer Name', value: customerName }] },
        { fields: [{ label: 'Finance Contact', value: financeContract }] },
        { fields: [{ label: 'Email Address', value: emailAddress }] },
        { fields: [{ label: 'Tel No', value: tellNo }] },
        { fields: [{ label: 'Invoice Address', value: invoiceAddress }] },
        { fields: [{ label: 'VAT Number', value: vatNumber }] },
      ]
    }
  }

  getNormativeDetails({ dlt, isProvisional, gbHashAlgo, gbHash, gbUtcTimeStamp, auxiliaryDlt, auxiliaryMechanism, auxiliaryMechanismTechRef }: any): any {
    // TODO: 🛠 Will do activationDate
    const items: any = [];
    if (gbHashAlgo != undefined) {
      items.push({
        fields: [{ label: 'Genesis Block Hash Algorithm', value: gbHashAlgo }]
      });
    }
    if (auxiliaryDlt != undefined) {
      items.push({
        fields: [{ label: 'Auxiliary Digital Token Distributed Ledger', value: auxiliaryDlt }]
      });
    }
    if (gbHash != undefined) {
      items.push({
        fields: [{ label: 'Genesis Block Hash', value: gbHash }]
      });
    }
    if (isProvisional != undefined) {
      items.push({
        fields: [{ label: 'Provisional', value: isProvisional ? 'Yes' : 'No' }]
      });
    }
    if (auxiliaryMechanism != undefined) {
      items.push({
        fields: [{ label: 'Auxiliary Digital Token Mechanism', value: auxiliaryMechanism }]
      });
    }
    if (auxiliaryMechanismTechRef != undefined) {
      items.push({
        fields: [{ label: 'Auxiliary Digital Token Technical Reference', value: auxiliaryMechanismTechRef }]
      });
    }
    if (gbUtcTimeStamp != undefined) {
      items.push({
        fields: [{ label: 'Genesis Block UTC Timestamp', value: formatDate(gbUtcTimeStamp, 'dd/MM/yyyy HH:mm:ss', 'en-US') }]
      });
    }
    return {
      title: 'Normative Attributes',
      items,
    }
  }

  getInformativeDetails({ longName, originalLongName, tokenRefUrl, publicDistLedgerIndicator }: any): any {
    const items: any = [];
    if (longName != undefined) {
      items.push({
        fields: [{ label: 'Long Name', value: longName }, (originalLongName != undefined ? { label: 'Original Long Name', value: originalLongName } : {})]
      });
    }
    if (tokenRefUrl != undefined) {
      items.push({
        fields: [{ label: 'Token Reference URL', value: tokenRefUrl }]
      });
    }
    if (publicDistLedgerIndicator != undefined) {
      items.push({
        fields: [{ label: 'Public Distributed Ledger Indicator', value: publicDistLedgerIndicator }]
      });
    }
    return {
      title: 'Informative Attributes',
      items
    }
  }

  getAUXInformativeDetails({ longName, originalLongName, shortNames, originalShortNames, attribute, externalIdentifier }: any) {
    const items: any = [];
    items.push({
      fields: [{ label: 'Long Name', value: longName }, (originalLongName != undefined ? { label: 'Original Long Name', value: originalLongName } : {})]
    });
    items.push({
      fields: [{ label: 'Short Name', value: JoinStringList(shortNames) }, (originalShortNames != undefined ? { label: 'Original Short Name', value: JoinStringList(originalShortNames) } : {})]
    });

    items.push({
      fields: [{ label: 'Unit Multiplier', value: attribute.unitMultiplier }]
    });

    items.push({
      fields: [
        { label: 'Underlying Asset External Identifier Type', value: JoinObjectList(externalIdentifier.underLyingAssets, 'type') },
        { label: 'Underlying Asset External Identifier Value', value: JoinObjectList(externalIdentifier.underLyingAssets, 'value') },
      ]
    });
    items.push({
      fields: [
        { label: 'Digital Token External Identifier Type', value: JoinObjectList(externalIdentifier.digitalTokens, 'type') },
        { label: 'Digital Token External Identifier Value', value: JoinObjectList(externalIdentifier.digitalTokens, 'value') },
      ]
    });

    return {
      title: 'Informative Attributes',
      items
    }
  }

  getDLTAuxiliaryDetails(auxiliries: any[]): any | null {
    const auxiliaryTokensItems: any[] = [];
    auxiliries.forEach(({ auxiliaryMechanism, auxiliaryMechanismDesc, auxiliaryMechanismTechRef, auxiliaryMechanismVerificationDetail }: any) => {
      if (!!auxiliaryMechanism && !!auxiliaryMechanismDesc && !!auxiliaryMechanismTechRef && !!auxiliaryMechanismVerificationDetail) {
        const items = {
          title: `Auxiliary Token`,
          items: [
            { fields: [{ label: `Auxiliary Mechanism`, value: auxiliaryMechanism }] },
            { fields: [{ label: `Auxiliary Mechanism Description`, value: auxiliaryMechanismDesc }] },
            { fields: [{ label: `Auxiliary Digital Token Technical Reference`, value: auxiliaryMechanismTechRef }] },
            { fields: [{ label: `Auxiliary Digital Token Verification Details`, value: auxiliaryMechanismVerificationDetail }] }
          ]
        }
        auxiliaryTokensItems.push(items);
      }
    });
    return auxiliaryTokensItems.length ? {
      title: `Auxiliary Tokens`,
      hasMultiple: true,
      subSections: [...auxiliaryTokensItems]
    } : {};
  }

  getDLTNativeTokenDetails(nativeTokens: any[]) {
    const nativeTokensItems: any[] = [];
    nativeTokens.forEach(({ shortNames, originalShortNames, attribute, externalIdentifier }: any) => {
      // will validate unit multiplier if has data to display since it is require field
      if (!!attribute.unitMultiplier) {
        const { digitalTokens, underLyingAssets } = externalIdentifier,
          items = {
            title: `Native Tokens`,
            items: [
              { fields: [{ label: `Short Name(s)`, value: JoinStringList(shortNames) }, { ...(originalShortNames ? { label: `Original Language Short Name(s)`, value: JoinStringList(originalShortNames) } : {}) }] },
              { fields: [{ label: `Unit Multiplier`, value: attribute.unitMultiplier }] },
              { fields: [{ label: `Underlying Asset External Identifiers Type`, value: JoinObjectList(underLyingAssets, 'type') }, { label: `Underlying Asset External Identifiers Value`, value: JoinObjectList(underLyingAssets, 'value') }] },
              { fields: [{ label: `Digital Token External Identifiers Type`, value: JoinObjectList(digitalTokens, 'type') }, { label: `Digital Token External Identifiers Value`, value: JoinObjectList(digitalTokens, 'value') }] }
            ]
          };
        nativeTokensItems.push(items);
      }
    });

    return nativeTokensItems.length ? {
      title: 'Native Tokens',
      hasMultiple: true,
      subSections: [...nativeTokensItems]
    } : {};
  }

  onEdit(tabIndex: number) {
    const formsURL: any = {
      AUX: `auxiliary-token`,
      DLT: `distributed-ledger-token`
    },
      url = `${formsURL[this.issueType]}/${this.submittedTokenId}`;

    this.route.navigate([url], { queryParams: { tab: tabIndex, hasAuxiliaryTokens: +this.hasAuxiliaryToken, hasNativeTokens: +this.hasNativeTokens } });
  }

  openConfirmationToCancel() {
    const dialogRef = this.dialog.open(ConfirmationDiallogComponent);

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.requestState = RequestState.Processing;
        this.loadingText = `Canceling token request ${this.submittedTokenId}.`
        this.requestService.cancelRequest(this.submittedTokenId).subscribe((data) => {
          window.scroll(0, 0);
          this.getTokenRequestDetails();
        });
      }
    });
  }
}
