
// Used for state process when submitting new token.
export enum RequestState {
    Creation,
    Editing,
    Processing,
    Successful,
    Error
}

// use for determining form type
export enum FormType {
    DLT = `DLT`,
    AUX = `AUX`,
    FFG = `FFG`
}


