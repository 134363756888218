

const JoinStringList = (list: string[]) => {
    return list.filter(Boolean).join(', ');
}

const JoinObjectList = (list: object[], prop: string) => {
    return list.map((s: any) => s[prop]).filter(Boolean).join(', ');
}

const DifferenceOfStringList = (priorList: string[], newList: string[]) => {
    return priorList.reduce((changes: string[], value, index) => {
        if (index < newList.length) {
            const newValue = newList[index]
            if (value !== newValue && (!!value || !!newValue)) changes.push(newValue || '(deleted)');
        } else if (!!value) { // prior has value but now deleted
            changes.push('(deleted)');
        }
        return changes;
    }, []).join(', ')
}

const DifferenceOfObjectList = (priorObjectList: any[], newObjectList: any[], prop: string) => {
    const mapPriorObjectList = priorObjectList.map((s: any) => s[prop]);
    const mapNewObjectList = newObjectList.map((s: any) => s[prop]);
    return DifferenceOfStringList(mapPriorObjectList, mapNewObjectList);
}

export { JoinStringList, JoinObjectList, DifferenceOfStringList, DifferenceOfObjectList }