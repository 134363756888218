<div class="col">
    <div class="row" *ngFor="let select of selections; let index = index">
        <div class="col">
            <mat-form-field color="primary" appearance="outline" floatLabel="auto">
                <mat-label class="field-label-size">{{label}}</mat-label>
                <mat-select class="field-size" placeholder="Select" [(value)]="select.value">
                    <mat-option *ngFor="let option of options" [value]="option.value">{{option.text}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <i *ngIf="isDeleteVisible" (click)="removeSelection(index)" class="material-symbols-outlined material-icons-font material-icons">
            delete
        </i>
    </div>
    <i *ngIf="isAddVisible" (click)="addSelections()" class="material-symbols-outlined material-icons-font material-icons">
        add
    </i>
</div>