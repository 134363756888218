<dti-loading *ngIf="isProcessing" text=""></dti-loading>
<div class="container">
    <div class="row pt-1-50">
        <div class="col">
            <h1 class="title">My Requests</h1>
        </div>
    </div>
    <div class="filter-container row pt-1">
        <div class="col">
            <mat-form-field color="primary" appearance="outline">
                <input matInput type="search" [(ngModel)]="searchByKeyword" (ngModelChange)="applyFilter()" class="field-size" placeholder="Search by keywords">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field color="primary" appearance="outline">
                <mat-select class="field-size" (selectionChange)="applyFilter()" [(ngModel)]="dateSubmitted" placeholder="Date Submitted">
                    <mat-option *ngFor="let dateSubmittedOption of dateSubmittedOptions" [value]="dateSubmittedOption">{{dateSubmittedOption}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field color="primary" appearance="outline">
                <mat-select class="field-size" (selectionChange)="applyFilter()" [(ngModel)]="status" multiple placeholder="Status">
                    <mat-option *ngFor="let statusOption of statusOptions" [value]="statusOption">
                        <span [ngClass]="statusOption">{{statusOption}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field color="primary" appearance="outline">
                <mat-select class="field-size" (selectionChange)="applyFilter()" [(ngModel)]="requestType" placeholder="Request Type">
                    <mat-option *ngFor="let requestTypeOption of requestTypeOptions" [value]="requestTypeOption.value">{{requestTypeOption.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <button mat-raised-button disableRipple="true" color="accent" (click)="clearFilter()" [disabled]="disableFilter" class="clear-button">
                Clear All
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <!-- <button mat-raised-button disableRipple="true" color="accent" (click)="testClick()" class="">
                Get Request
            </button> -->
            <table mat-table [dataSource]="dataRequests">
                <!-- Type -->
                <ng-container matColumnDef="issueType">
                    <th mat-header-cell *matHeaderCellDef> Type </th>
                    <td mat-cell *matCellDef="let element"> <span class="{{element.issueType}}">{{element.issueType}}</span></td>
                </ng-container>
                <!-- Reference -->
                <ng-container matColumnDef="reference">
                    <th mat-header-cell *matHeaderCellDef> Application ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
                </ng-container>

                <!-- Summary -->
                <ng-container matColumnDef="summary">
                    <th mat-header-cell *matHeaderCellDef>Summary</th>
                    <td mat-cell *matCellDef="let element"> {{element.summary}} </td>
                </ng-container>

                <!-- Date Submitted -->
                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef>Date Submitted</th>
                    <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'MM/dd/yyyy' }} </td>
                </ng-container>

                <!-- Status -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element"> <label class="{{element.status.toLowerCase()}}"> {{element.status}}</label> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" routerLink="/token-request-summary/{{row.reference}}" [queryParams]="getQueryParams(row)"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                        <div class="no-results">
                            <div class="icon-container">
                                <img class="image-icon" src="../../assets/img/archive.svg" alt="">
                            </div>
                            <label>No Results Found</label>
                            <p>Please double check your search for any typos or spelling errors - or try a different search term.</p>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>